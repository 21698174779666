import { useState, useMemo, useCallback, useEffect } from 'react';
import { useGeneric, GenericContextProps } from 'context/genericContext';
import { useGetSupportWorkerRoleOptions, useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import { SelectOption } from 'types/types';
import { LocationBar, Loading, ToggleButtons, Search, Table, Filters } from 'components';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import GroupsOutlined from '@mui/icons-material/GroupsOutlined';
import { AdjustmentsHorizontalIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { format, isValid } from 'date-fns';
import { dateIs7DaysOld } from 'services/helpers';
import { SupportWorker } from '../../__generated__/graphql';

export default function SupportWorkerList() {
  const navigate = useNavigate();
  const { supportWorkers, loading } = useGetSupportWorkers({ showAll: true });
  const { teams: genericTeam, teamsLoading } = useGeneric() as GenericContextProps;
  const { suportWorkerRoles, supportWorkerRolesLoading } = useGetSupportWorkerRoleOptions();
  const supportWorkerRoleOptions = suportWorkerRoles.map((sw) => {
    return { value: sw.id, label: sw.name };
  });
  const teams = useMemo(() => genericTeam?.map((t) => ({ value: t.teamId, label: t.teamName })) || [], [genericTeam]);

  const [search, setSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [team, setTeam] = useState('all');
  const [role, setRole] = useState('all');
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [filters, setFilters] = useState([
    { name: 'teams', selectedValue: { value: 'all', label: 'All' }, values: [{ value: 'all', label: 'All' }, ...teams] },
    {
      name: 'role',
      selectedValue: { value: 'all', label: 'All' },
      values: [{ value: 'all', label: 'All' }, ...supportWorkerRoleOptions],
    },
  ]);
  const columnVisibility = {
    avatar: true,
    position: window.innerWidth > 1545,
    isActive: true,
    birdieId: window.innerWidth > 1024,
    lat: window.innerWidth > 1024,
    cognito: window.innerWidth > 640,
    lastLoggedInDate: window.innerWidth > 1280,
  };

  useEffect(() => {
    const teamSearch = searchParams.get('teams');
    const roleSearch = searchParams.get('role');
    const newFilters = filters;
    if (teamSearch) {
      setTeam(teamSearch);
      const teamOption = genericTeam?.map((t) => ({ value: t.teamId, label: t.teamName })).find((t) => t.value === teamSearch);
      if (teamOption) {
        newFilters[0].selectedValue = teamOption;
      }
    }
    if (roleSearch) {
      setRole(roleSearch);
      const roleOption = supportWorkerRoleOptions.find((t) => t.value === roleSearch);
      if (roleOption) {
        newFilters[1].selectedValue = roleOption;
      }
    }
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericTeam]);

  useEffect(() => {
    const newFilters = filters;
    newFilters[0].values = [{ value: 'all', label: 'All' }, ...teams];
    newFilters[1].values = [{ value: 'all', label: 'All' }, ...supportWorkerRoleOptions];
    setFilters(newFilters);
  }, [filters, supportWorkerRoleOptions, teams]);

  const filterByStatus = (sw: SupportWorker) => {
    if (filterStatus === 'All') return true;
    if (filterStatus === 'New' && sw.dateCreated && dateIs7DaysOld(sw.dateCreated)) return true;
    if (filterStatus === 'Active' && sw.isActive && !sw.isTerminated) return true;
    if (filterStatus === 'Terminated' && !sw.isActive && sw.isTerminated) return true;
    return false;
  };

  const filterByTeam = (sw: SupportWorker) => {
    if (team === 'all') return true;
    if (team && sw.teamId === team) return true;
    return false;
  };

  const filterByRole = (sw: SupportWorker) => {
    if (role === 'all') return true;
    if (role && sw.roleId === role) return true;
    return false;
  };

  const filterByName = (sw: SupportWorker) => {
    if (sw.fullName.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  const filteredSupportWorkers = () => {
    return supportWorkers
      ?.filter((sw) => filterByTeam(sw))
      .filter((sw) => filterByName(sw))
      .filter((sw) => filterByStatus(sw))
      .filter((sw) => filterByRole(sw))
      .sort((x, y) => (y.dateUpdated || 0) - (x.dateUpdated || 0));
  };

  const onButtonClick = useCallback(
    (sw: SupportWorker) => {
      navigate(`/admin-workers/${sw.id}`);
    },
    [navigate],
  );

  const RowActions = useCallback(
    ({ row }: { row: SupportWorker }) => {
      return (
        <button data-cy="list-edit-button" type="button" onClick={() => onButtonClick(row)} aria-label="Edit" className="m-0 p-0">
          <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary600" />
        </button>
      );
    },
    [onButtonClick],
  );

  const onFilterChange = (name: string, value: SelectOption) => {
    const newFilters = [...filters];
    const filterIndex = newFilters.findIndex((f) => f.name === name);
    newFilters[filterIndex].selectedValue = value;
    setFilters(newFilters);
  };

  const columnHelper = createColumnHelper<SupportWorker>();

  const columns1 = useMemo(() => {
    return [
      columnHelper.accessor('avatar', {
        header: () => 'Name',
        cell: (info) => (
          <div className="flex items-center font-medium text-gray-900 md:whitespace-nowrap dark:text-white">
            <img className="w-12 h-12 rounded-full" src={info.row.original.avatar || '/defaultProfile.jpg'} alt={`Link worker ${info.row.original.fullName}`} />
            <div className="ps-3">
              <div data-cy="list-name" className="text-md leading-md font-semibold text-gray-700">
                {info.row.original.fullName}
              </div>
              <div data-cy="list-email" className="font-normal text-gray-500 hidden md:block">
                {info.row.original.email}
              </div>
            </div>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('position', {
        header: () => 'Position / Role',
        cell: (props) => {
          let response = props.row.original.permissions?.admin ? 'Admin' : '';
          response = props.row.original.permissions?.developer ? `${response}Dev` : response;
          const position = response ? `${props.renderValue()} (${response})` : props.renderValue();
          return (
            <div className="">
              <div className="font-medium">{position}</div>
              <div>{props.row.original.role?.name}</div>
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('isActive', {
        header: () => 'Team Hub Status',
        cell: (props) => {
          if (!props.row.original.isActive && !props.row.original.isTerminated) {
            return (
              <div
                data-cy="list-inactive"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Inactive
              </div>
            );
          } else if (props.row.original.isActive && !props.row.original.isTerminated) {
            return (
              <div
                data-cy="list-active"
                className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Active
              </div>
            );
          } else if (props.row.original.isTerminated) {
            return (
              <div
                data-cy="list-terminated"
                className="bg-gray-50 border border-gray-700 rounded-2xl text-gray-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Leavers
              </div>
            );
          }
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('birdieId', {
        header: () => 'Birdie',
        cell: (props) => {
          if (!props.row.original.birdieId && !props.row.original.isTerminated) {
            return (
              <div
                data-cy="list-birdie-inactive"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Disabled
              </div>
            );
          } else if (props.row.original.birdieId && !props.row.original.isTerminated) {
            return (
              <div
                data-cy="list-birdie-active"
                className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Active
              </div>
            );
          }
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('lat', {
        header: () => 'Team Hub Maps',
        cell: (props) => {
          if ((props.row.original.lat === 0 || props.row.original.lng === 0) && !props.row.original.isTerminated) {
            return (
              <div
                data-cy="list-maps-inactive"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Inactive
              </div>
            );
          } else if (props.row.original.lat !== 0 && props.row.original.lng !== 0 && !props.row.original.isTerminated) {
            return (
              <div
                data-cy="list-maps-active"
                className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Active
              </div>
            );
          }
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('cognito', {
        header: () => 'Login',
        cell: (props) => {
          if (!props.row.original.cognito && !props.row.original.isTerminated) {
            return (
              <div
                data-cy="list-cognito-inactive"
                className="bg-error-50 border border-error-700 rounded-2xl text-error-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Disabled
              </div>
            );
          } else if (props.row.original.cognito && !props.row.original.isTerminated) {
            return (
              <div
                data-cy="list-cognito-active"
                className="bg-success-50 border border-success-700 rounded-2xl text-success-700 text-sm leading-sm font-medium px-2.5 py-0.5 w-fit"
              >
                Active
              </div>
            );
          }
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('lastLoggedInDate', {
        header: () => 'Last Login',
        cell: (info) => {
          return <span>{isValid(info.renderValue()) ? format(info.renderValue() || 0, 'dd/MM/yy') : 'Never logged in'}</span>;
        },
        footer: (info) => info.column.id,
      }),

      columnHelper.display({
        id: 'actions',
        cell: (props) => <RowActions row={props.row.original} />,
      }),
    ] as Array<ColumnDef<SupportWorker, unknown>>;
  }, [columnHelper, RowActions]);

  const onSaveFilters = () => {
    const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
    setSearchParams(Object.assign({}, ...newSearchParamsArray));
    setTeam(filters[0].selectedValue.value);
    setRole(filters[1].selectedValue.value);
    setShowFiltersModal(false);
  };

  if (loading || teamsLoading || supportWorkerRolesLoading) {
    return <Loading />;
  }

  const rows = filteredSupportWorkers().map((c) => {
    return {
      ...c,
    };
  });

  return (
    <div>
      <Helmet>
        <title>Support Workers</title>
      </Helmet>
      <LocationBar section="Admin" page="Support Worker List" Icon={GroupsOutlined} />
      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-display-sm sm:text-display-lg leading-display-sm sm:leading-display-lg sm:tracking-display-lg text-black font-medium">
            Support Workers
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8 md:mt-16">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-9 w-full md:w-fit">
            <ToggleButtons
              testId="status-filter"
              buttons={['All', 'New', 'Active', 'Terminated']}
              selectedButton={filterStatus}
              onSelectButton={setFilterStatus}
            />
            <Search value={search} onSearch={setSearch} />
            <div className="flex flex-col md:flex-row items-center gap-3 w-full md:w-fit">
              <button
                data-cy="show-filters-button"
                type="button"
                className={`relative border ${
                  team === 'all' && filterStatus === 'all' ? 'border-gray-300' : 'border-gray-900'
                }  text-gray-700 text-md leading-md flex items-center px-4 py-2.5 rounded-lg shadow-xs justify-center w-full md:w-fit`}
                onClick={() => setShowFiltersModal(true)}
              >
                <AdjustmentsHorizontalIcon className="w-5 h-5 mr-2" />
                Filters
                {team !== 'all' ||
                  (role !== 'all' && (
                    <span className="absolute bg-gray-900 h-5 w-5 text-white rounded-full -top-2 -right-2 flex items-center justify-center text-xs font-semibold">
                      {team !== 'all' && role !== 'all' ? '2' : '1'}
                    </span>
                  ))}
              </button>
              {team !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {teams.find((t) => t.value === team)?.label}
                  <button
                    type="button"
                    aria-label="remove team filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('teams', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setTeam('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
              {role !== 'all' && (
                <div className="bg-gray-100 rounded-2xl border border-gray-300 px-2.5 py-1 text-gray-700 text-sm leading-sm font-medium flex items-center">
                  {supportWorkerRoleOptions.find((t) => t.value === role)?.label}
                  <button
                    type="button"
                    aria-label="remove role filter"
                    className="text-gray-500"
                    onClick={() => {
                      onFilterChange('role', { value: 'all', label: 'All' });
                      const newSearchParamsArray = filters.map((f) => ({ [f.name]: f.selectedValue.value }));
                      setSearchParams(Object.assign({}, ...newSearchParamsArray));
                      setRole('all');
                    }}
                  >
                    <XMarkIcon className="w-4 h-4 ml-2" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3 md:mt-12 mb-10 overflow-x-auto overflow-y-hidden">
          {rows && rows.length > 0 && <Table<SupportWorker> data={rows} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>

      {showFiltersModal && (
        <Filters filters={filters} onFilterChange={onFilterChange} onCloseModal={() => setShowFiltersModal(false)} onSaveFilters={onSaveFilters} />
      )}
    </div>
  );
}
