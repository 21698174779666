import { useState } from 'react';
import { getTime } from 'date-fns';
import ReactSelect from 'components/Select';
import DatePicker from 'components/DatePicker';
// import { Loading } from 'components';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { SelectOption } from 'types/types';
import { CustomerPackage } from '__generated__/graphql';

interface PackageCreateProps {
  onClose: () => void;
  onCreate: (data: CustomerPackage) => void;
}

export default function PackageCreate({ onClose, onCreate }: PackageCreateProps) {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [supportedPerson, setSupportedPerson] = useState<SelectOption>();
  const { customers } = useGetCustomers({});

  const onSavePackage = () => {
    if (startDate && supportedPerson) {
      onCreate({
        startDate: getTime(startDate),
        endDate: null,
        customerId: supportedPerson.value,
        externalId: supportedPerson.id || '',
        name: supportedPerson.label,
      });
    }
  };

  // if (loading) return <Loading />;

  const supportedPersonOptions = customers.map((s) => {
    return { label: `${s.firstName} ${s.lastName}`, value: s.id, id: s.externalId };
  });

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Create Package</div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Supported Person</div>
              <div className="mt-2">
                <ReactSelect
                  testId="package-customerId"
                  options={supportedPersonOptions}
                  onChange={(e) => setSupportedPerson(e)}
                  selectedValue={supportedPerson}
                  searchable
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Start Date</div>
              <div className="mt-2">
                <DatePicker testId="package-start-date" value={startDate ?? undefined} onChange={setStartDate} />
              </div>
            </div>
            <div className="flex justify-between items-center mt-5">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                data-cy="save-package-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSavePackage}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
