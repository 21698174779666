import { utcToZonedTime, format, getTimezoneOffset } from 'date-fns-tz';
import { Shift } from '__generated__/graphql';
import { Days } from 'types/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { datetime, RRule } from 'rrule';
import {
  add,
  addHours,
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  endOfMonth,
  formatDuration,
  getDate,
  getHours,
  getMinutes,
  getTime,
  set,
  setHours,
  setMinutes,
  setDay,
  startOfDay,
  startOfMonth,
  sub,
  subHours,
  subMonths,
  addYears,
} from 'date-fns';

const timeZone = 'Europe/London';
const twoHour = 2;

export function getStartDateTime(date: number | Date) {
  return getTimezoneOffset(timeZone, date) === 0 ? utcToZonedTime(date, timeZone) : utcToZonedTime(addHours(date, twoHour), timeZone);
}

export function getEndDateTime(date: number | Date) {
  return getTimezoneOffset(timeZone, date) === 0 ? utcToZonedTime(date, timeZone) : utcToZonedTime(subHours(date, twoHour), timeZone);
}

export const formatDateWithYMD = (date: number | Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const formatAmPm = (dateTime: number): string => {
  const hours = parseInt(format(dateTime, 'HH'), 10);
  if (hours <= 13) return 'am';
  return 'pm';
};

export const getShiftLength = (shiftDurationMinutes: number) => {
  return formatDuration(
    {
      hours: Math.floor(shiftDurationMinutes / 60),
      minutes: shiftDurationMinutes % 60,
    },
    { format: ['hours', 'minutes'] },
  );
};

export function formatTime(data: number | Date, dataFormat: string) {
  return format(utcToZonedTime(data, timeZone), dataFormat);
}

export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const getDay = (day: string) => {
  return day === '6' ? 0 : parseInt(day, 10) + 1;
};

export const shiftDuration = (arr: Shift[]) => {
  const total = arr.reduce((acc, shift) => {
    const duration = differenceInMinutes(shift.endDateTime, shift.startDateTime);
    return acc + duration;
  }, 0);
  return total;
};

export const rotaPeriod = (arr: Shift[]) => {
  const day = format(arr[0].startDateTime, 'EEEE');
  const d = format(arr[0].startDateTime, 'd');
  const m = format(arr[0].startDateTime, 'LLLL');
  return `${day} ${d} ${m}`;
};

export const calculatePercentage = (a: number, b: number) => Math.round((a / b) * 100);

export const convertToString = (time: number) => {
  const hours = Math.floor(time / 60) || 0;
  const minutes = time % 60 || 0;
  return `${hours.toString().length > 1 ? hours : `0${hours}`}:${minutes.toString().length > 1 ? minutes : `0${minutes}`}`;
};

export const getFirstDayMonth = (date: number | Date) => {
  return getTime(startOfMonth(date));
};

export const getLastDayMonth = (date: number | Date) => {
  return getTime(endOfMonth(date));
};

export const getDurationInMinutes = (startDate: number | Date, endDate: number | Date) => {
  return getTime(differenceInMinutes(endDate, startDate));
};

export const getFirstDayOfPayroll = (date: number | Date) => {
  const cleanDate = set(startOfDay(startOfMonth(date)), { date: 21 });
  if (getDate(date) <= 20) {
    return getTime(sub(cleanDate, { months: 1 }));
  } else {
    return getTime(cleanDate);
  }
};

export const getLastDayOfPayroll = (date: number | Date) => {
  const cleanDate = set(endOfDay(startOfMonth(date)), { date: 20 });
  if (getDate(date) <= 20) {
    return getTime(cleanDate);
  } else {
    return getTime(add(cleanDate, { months: 1 }));
  }
};

export const getStartOfDay = (date: number | Date) => {
  return getTime(startOfDay(date));
};

export const getEndOfDay = (date: number | Date) => {
  return getTime(endOfDay(date));
};

export const weekFromMonday = (day: Days | number) => {
  // getDay from Monday instead of Sunday => Monday = 0 && Sunday = 6
  switch (day) {
    case 0:
      return 6;
    case 1:
      return 0;
    case 2:
      return 1;
    case 3:
      return 2;
    case 4:
      return 3;
    case 5:
      return 4;
    case 6:
      return 5;
    default:
      return 0;
  }
};

export const getDayName = (day: Days | number) => {
  switch (day) {
    case 0:
      return 'Monday';
    case 1:
      return 'Tuesday';
    case 2:
      return 'Wednesday';
    case 3:
      return 'Thursday';
    case 4:
      return 'Friday';
    case 5:
      return 'Saturday';
    case 6:
      return 'Sunday';
    default:
      return 'Monday';
  }
};

export const getHoursAndMinutes = (milliseconds: number | Date) => {
  const hours = `0${new Date(milliseconds).getHours()}`.slice(-2);
  const minutes = `0${new Date(milliseconds).getMinutes()}`.slice(-2);
  return `${hours}:${minutes}`;
};

export const getHoursToDaysHours = (hours: number) => {
  const daysForPeriod = Math.floor(hours / 24);
  const hoursForPeriod = Math.floor(hours % 24);
  return { days: daysForPeriod, hours: hoursForPeriod };
};

export const getTextColor = (color: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  if (result !== null) {
    const red = parseInt(result[1], 16);
    const green = parseInt(result[2], 16);
    const blue = parseInt(result[3], 16);
    if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) {
      return 'black';
    }
  }
  return 'white';
};

export const getWellbeingStatusColor = (status: string | undefined) => {
  switch (status) {
    case 'On Track':
      return '#43c463';
    case 'Off Track':
      return '#b83c33';
    case 'Slightly Off Track':
      return '#e8a135';
    case 'Paused':
      return '#bfbbb6';
    default:
      return '#';
  }
};

export const msToTime = (duration: number) => {
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  return { hours, minutes };
};

export const removeTime = (date: number) => {
  return getTime(set(date, { hours: 0, minutes: 0, seconds: 0 }));
};

export const getTimeInDecimal = (startTime: number, endTime: number) => {
  const numberOfHoursInShift = differenceInHours(new Date(endTime), new Date(startTime));

  const numberOfMinutesRemainingInShift = differenceInMinutes(new Date(endTime), new Date(startTime)) - numberOfHoursInShift * 60;
  const dec = (numberOfMinutesRemainingInShift / 6) * 10;
  return parseFloat(`${numberOfHoursInShift}.${dec < 10 ? '0' : ''}${dec}`);
};

export function dateIs7DaysOld(date: number | Date) {
  return date >= sub(Date.now(), { days: 7 });
}

export const getCurrentInvoicePeriod = () => {
  const today = new Date();
  const startDate = new Date(new Date().setDate(21));
  const endDate = new Date(new Date().setDate(20));
  if (today.getDate() > 20) {
    startDate.setMonth(startDate.getMonth());
    endDate.setMonth(endDate.getMonth() + 1);
  } else {
    startDate.setMonth(startDate.getMonth() - 1);
    endDate.setMonth(endDate.getMonth());
  }
  return { startDate: format(startDate, 'LLLL'), endDate: format(endDate, 'LLLL') };
};

export const differenceBetweenDates = (startDate: number, endDate: number): number => {
  return getTime(differenceInMinutes(endDate, startDate));
};

export const formatHoursAndMinutes = (totalMinutes: number): { hours: number; minutes: number } => {
  const hours = totalMinutes > 0 ? Math.floor(totalMinutes / 60) : 0;
  const minutes = totalMinutes > 0 ? totalMinutes % 60 : 0;
  return { hours, minutes };
};

export const setTime = (date: number): number => {
  const hours = getHours(date);
  const minutes = getMinutes(date);
  return getTime(setMinutes(setHours(Date.now(), hours), minutes));
};

export const getPeriodsForYear = () => {
  const rule = new RRule({
    freq: RRule.MONTHLY,
    dtstart: datetime(2022, 10, 21),
    until: setDay(new Date(), 20),
  });
  const dates = rule.all();
  const dateTimes = dates.map((d) => getTime(startOfDay(d.getTime()))).sort((a, b) => b - a);
  return dateTimes;
};

export const getFinancialYears = () => {
  const currentYear = new Date().getFullYear();
  const rule = new RRule({
    freq: RRule.YEARLY,
    dtstart: datetime(2022, 4, 1),
    until: datetime(currentYear, 4, 1),
  });
  const dates = rule.all();
  const dateTimes = dates.map((d) => new Date(d).getTime()).sort((a, b) => b - a);
  return dateTimes;
};

export const setHoursAndMinutes = (date: number, dateWithHoursAndMinutes: number) => {
  const hours = getHours(dateWithHoursAndMinutes);
  const minutes = getMinutes(dateWithHoursAndMinutes);
  return getTime(set(date, { hours, minutes, seconds: 0 }));
};

export const addHoursToDate = (date: number, hours: number) => {
  return getTime(addHours(date, hours));
};

export const setTimeOnDate = (date: Date, hours: number, minutes: number) => {
  return set(date, { hours, minutes, seconds: 0 });
};

export const getAmOrPmStartShiftTime = (dateTime: number): number => {
  const hours = parseInt(format(dateTime, 'HH'), 10);
  if (hours <= 13) return 9;
  return 16;
};

export const getPreviousMonth = (date: Date | number) => {
  return getTime(subMonths(date, 1));
};

export const getPreviousDay = (date: Date | number) => {
  return getTime(sub(date, { days: 1 }));
};

export const addYearsToDate = (date: number, years: number) => {
  return getTime(addYears(date, years));
};

export const getNextPayrollStartDate = (date: number | Date) => {
  const cleanDate = set(endOfDay(startOfMonth(date)), { date: 21 });
  if (getDate(date) <= 20) {
    return getTime(cleanDate);
  } else {
    return getTime(add(cleanDate, { months: 1 }));
  }
};
