import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import getTime from 'date-fns/getTime';
import { clockMessage } from 'config/messages';
import { Shift } from '__generated__/graphql';
import { Loading } from 'components';

interface onSaveVisitProps {
  startDateTime: number;
  endDateTime: number;
}

interface EditVisitProps {
  toggleEditModal: (editVisitModalState: boolean) => void;
  editVisitModalState: boolean;
  onSaveVisit: (data: onSaveVisitProps, event: string) => void;
  shifts: Shift[];
  selectedShift: Shift | null;
  apiLoading: boolean;
}

export default function EditVisit({ toggleEditModal, editVisitModalState, shifts, selectedShift, onSaveVisit, apiLoading }: EditVisitProps) {
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [validation, setValidation] = useState<string | null>(null);
  const shift = shifts.find((s: Shift) => s.id === selectedShift?.id);

  useEffect(() => {
    if (shift) {
      setStartTime(new Date(shift.startDateTime));
      setEndTime(new Date(shift?.endDateTime));
      setValidation(null);
    }
  }, [shift]);

  const onUpdateVisit = () => {
    setValidation(null);

    if (!startTime || !endTime) {
      setValidation('Please select a shift date time and end time');
      return;
    }

    if (getTime(startTime) >= getTime(endTime)) {
      setValidation('Please provide a valid visit date time; end time should be greater than start time');
      return;
    }

    onSaveVisit({ startDateTime: getTime(startTime), endDateTime: getTime(endTime) }, 'Edit');
  };

  return (
    <Dialog data-cy="edit-visit-dialog" open={editVisitModalState} onClose={() => toggleEditModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Edit Visit Time</DialogTitle>
      <DialogContent>
        {validation && (
          <Alert variant="filled" severity="error" sx={{ width: '84%', marginLeft: '1em', marginBottom: '1em' }}>
            {validation}
          </Alert>
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl sx={{ paddingTop: { xs: '1em', sm: '1em' }, width: '90%' }}>
            <Typography>Start Date</Typography>
            <TimePicker
              ampm={false}
              localeText={{ toolbarTitle: 'Select Start Time' }}
              minutesStep={5}
              value={startTime}
              onChange={setStartTime}
              slotProps={{ textField: { helperText: clockMessage } }}
            />
          </FormControl>
          <FormControl sx={{ paddingTop: { xs: '1em', sm: '1em' }, width: '90%' }}>
            <Typography>End Date</Typography>
            <TimePicker
              ampm={false}
              localeText={{ toolbarTitle: 'Select End Time' }}
              minutesStep={5}
              minTime={startTime || new Date()}
              value={endTime}
              onChange={setEndTime}
              slotProps={{ textField: { helperText: clockMessage } }}
            />
          </FormControl>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button data-cy="close-visit-button" variant="contained" onClick={() => toggleEditModal(false)}>
          Close
        </Button>
        {apiLoading ? (
          <Loading isComponent />
        ) : (
          <Button data-cy="edit-visit-button" variant="contained" onClick={() => onUpdateVisit()}>
            Save Visit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
