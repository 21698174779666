import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Table } from 'components';
import { PencilIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { Maybe, CustomerPackage, CustomerPackagePrice } from '__generated__/graphql';
import { useSaveCustomerPackage } from 'api/hooks/useCustomerPackages';
import { camelCaseToNormalString, toTitleCase } from 'services/strings';
import PackagePriceCreate from './PackagePriceCreate';
import { MapPackageWithPrice } from './Mapper';
import PackagePriceUpdate from './PackagePriceUpdate';
import PackagePriceEnd from './PackagePriceEnd';

interface PackagePriceListProps {
  customerPackage: Maybe<CustomerPackage> | undefined;
}

const PackagePriceList = ({ customerPackage }: PackagePriceListProps) => {
  const [openCreatePackageModal, setOpenCreatePackageModal] = useState(false);
  const [openEditPackageModal, setOpenEditPackageModal] = useState(false);
  const [openEndPackageModal, setOpenEndPackageModal] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState<CustomerPackagePrice | null>(null);
  const { saveCustomerPackage } = useSaveCustomerPackage({ customerId: customerPackage?.customerId, packageId: customerPackage?.id });

  const onAddPackagePrice = async (data: CustomerPackagePrice) => {
    await saveCustomerPackage({
      variables: {
        input: MapPackageWithPrice(customerPackage, data),
      },
    });
    setOpenCreatePackageModal(false);
  };

  const onEditPackagePrice = async (data: CustomerPackagePrice) => {
    const prices = customerPackage?.prices?.filter((p) => p?.id !== data.id);
    await saveCustomerPackage({
      variables: {
        input: MapPackageWithPrice({ ...customerPackage, prices }, data),
      },
    });

    setOpenEditPackageModal(false);
    setOpenEndPackageModal(false);
  };

  const editPackagePrice = (price: CustomerPackagePrice | null) => {
    if (price) {
      setSelectedPrice(price);
      setOpenEditPackageModal(true);
    }
  };

  const endPackagePrice = (price: CustomerPackagePrice | null) => {
    if (price) {
      setSelectedPrice(price);
      setOpenEndPackageModal(true);
    }
  };

  const RowActions = ({ row }: { row: Maybe<CustomerPackagePrice> }) => {
    if (row?.endDate) return null;
    return (
      <div className="flex items-center gap-3">
        <button data-cy="list-edit-button" type="button" onClick={() => editPackagePrice(row)} aria-label="Edit">
          <PencilIcon className="w-6 h-6 text-gray-500 hover:text-primary-600" />
        </button>
        <button type="button" onClick={() => endPackagePrice(row)} aria-label="Close" className="m-0 p-0">
          <LockClosedIcon className="w-6 h-6  text-error-600 hover:text-primary600" />
        </button>
      </div>
    );
  };

  const columnVisibility = {
    dateUpdated: window.innerWidth > 768,
    name: true,
    subject: window.innerWidth > 640,
    type: true,
  };

  const columnHelper = createColumnHelper<Maybe<CustomerPackagePrice>>();

  const columns1 = [
    columnHelper.accessor('startDate', {
      header: () => 'Start Date',
      cell: (info) => <div data-cy="list-startDate">{info.renderValue() ? format(info.renderValue() || 0, 'dd/MM/yyyy') : 'Unknown'}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('endDate', {
      header: () => 'End Date',
      cell: (info) => <div data-cy="list-endDate">{info.renderValue() ? format(info.renderValue() || 0, 'dd/MM/yyyy') : 'Active'}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('monthlyValue', {
      header: () => 'Monthly Value',
      cell: (info) => <div data-cy="list-monthly-value">£{parseFloat(info.renderValue()?.toString() || '')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('hourlyValue', {
      header: () => 'Hourly Value',
      cell: (info) => <div data-cy="list-hourly-value">£{parseFloat(info.renderValue()?.toString() || '')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('numberOfHours', {
      header: () => 'Number Hours per Week',
      cell: (info) => <div data-cy="list-number-hours">{parseFloat(info.renderValue()?.toString() || '')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('type', {
      header: () => 'Type',
      cell: (info) => <div data-cy="list-type">{toTitleCase(camelCaseToNormalString(info.renderValue() || ''))}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('fundingSource', {
      header: () => 'Funding Source',
      cell: (info) => (
        <div data-cy="list-funding-source">
          {info
            .renderValue()
            ?.map((f) => toTitleCase(camelCaseToNormalString(f || '')))
            .join(', ')}
        </div>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
    }),
  ] as Array<ColumnDef<Maybe<CustomerPackagePrice>, unknown>>;

  const personHasActivePrice = () => {
    return customerPackage?.endDate || customerPackage?.prices?.some((s) => !s?.endDate);
  };

  const prices = [...(customerPackage?.prices || [])].sort((x, y) => (y?.startDate || 0) - (x?.startDate || 0));

  return (
    <>
      <div className="my-10 px-4 md:px-[0%]">
        <div className="flex justify-between place-items-center mb-8">
          <div className="text-display-sm md:text-display-lg leading-display-sm md:leading-display-lg font-semibold md:tracking-display-lg text-black" />
          {!personHasActivePrice() && (
            <button
              type="button"
              data-cy="create-package-button"
              className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md hidden md:block"
              onClick={() => setOpenCreatePackageModal(true)}
            >
              Add Price
            </button>
          )}
        </div>
        <div className="mt-3 md:mt-8 mb-10">
          {prices && prices.length > 0 && <Table<Maybe<CustomerPackagePrice>> data={prices} columns={columns1} columnVisibility={columnVisibility} />}
        </div>
      </div>
      {openCreatePackageModal && (
        <PackagePriceCreate onClose={() => setOpenCreatePackageModal(false)} onCreate={onAddPackagePrice} customerPackage={customerPackage} />
      )}
      {openEditPackageModal && selectedPrice && (
        <PackagePriceUpdate selectedPrice={selectedPrice} onClose={() => setOpenEditPackageModal(false)} onUpdate={onEditPackagePrice} />
      )}
      {openEndPackageModal && selectedPrice && (
        <PackagePriceEnd selectedPrice={selectedPrice} onClose={() => setOpenEndPackageModal(false)} onUpdate={onEditPackagePrice} />
      )}
    </>
  );
};

export { PackagePriceList };
