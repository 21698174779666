import React, { useState, useEffect } from 'react';
import getTime from 'date-fns/getTime';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { formatTime } from 'services/helpers';
import { Shift as ShiftType, Maybe, ShiftRun } from '__generated__/graphql';
import ReactSelect from 'components/Select';
import { SelectOption, activityTypes } from 'types/types';
import TimePicker from 'components/TimePicker';
import { Input } from 'components';
import { PencilIcon, TrashIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { camelCaseToNormalString } from '../../../../services/strings';

interface ActivityItemProps {
  activity: Partial<ShiftType> & { newEntry?: boolean };
  myShiftRuns: (Maybe<ShiftRun> | undefined)[];
  saveActivity: (activity: Partial<ShiftType>) => void;
  removeActivity: (activity: Partial<ShiftType>) => void;
  updateActivity: (activity: Partial<ShiftType>) => void;
}

export default function ActivityItem({ activity, myShiftRuns, saveActivity, removeActivity, updateActivity }: ActivityItemProps) {
  const [startDateTime, setStartDateTime] = useState<Date>();
  const [endDateTime, setEndDateTime] = useState<Date>();
  const [shiftRun, setShiftRun] = useState<SelectOption>();
  const [activityType, setActivityType] = useState<SelectOption>();
  const [notes, setNotes] = useState('');
  const [editActivity, setEditActivity] = useState(false);
  const [validation, setValidation] = useState<string>('');
  const shifts = myShiftRuns.map((s) => ({
    value: s?.id || '',
    label: `${s?.startDateTime ? formatTime(s?.startDateTime, 'EEEE dd MMM') : ''}: ${s?.startDateTime ? formatTime(s?.startDateTime, 'HH:mm') : ''}-${
      s?.endDateTime ? formatTime(s?.endDateTime, 'HH:mm') : ''
    }`,
  }));

  useEffect(() => {
    if (activity) {
      if (activity.shiftId) {
        setShiftRun({
          value: activity.shiftId,
          label: `${formatTime(activity.startDateTime ?? 0, 'EEEE dd MMM')}: ${formatTime(activity.startDateTime ?? 0, 'HH:mm')}-${formatTime(
            activity.endDateTime ?? 0,
            'HH:mm',
          )}`,
        });
      }
      if (activity.startDateTime) setStartDateTime(new Date(activity.startDateTime));
      if (activity.endDateTime) setEndDateTime(new Date(activity.endDateTime));
      if (activity.activityType) setActivityType({ value: activity.activityType, label: camelCaseToNormalString(activity.activityType) });
      if (activity.notes) setNotes(activity.notes);
      setValidation('');
    }
  }, [activity]);

  const onChangeStartDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setStartDateTime(dateTime);
      setValidation('');
    }
  };

  const onChangeEndDateTime = (dateTime: Date | null) => {
    if (dateTime) {
      setEndDateTime(dateTime);
      setValidation('');
    }
  };

  const onChangeActivity = (a: SelectOption) => {
    setActivityType(a);
    setValidation('');
  };

  const onChangeNotes = (note: string) => {
    setNotes(note);
    setValidation('');
  };

  const onChangeShiftRun = (option: SelectOption) => {
    const shift = myShiftRuns.find((sh: Maybe<ShiftRun> | undefined) => sh?.id === option.value);

    if (shift) {
      setShiftRun(option);
      setStartDateTime(new Date(shift.startDateTime));
      setEndDateTime(new Date(shift.endDateTime));
      setValidation('');
    }
  };

  const onRemoveActivity = () => {
    removeActivity(activity);
  };

  const onSaveActivity = () => {
    if (getTime(startDateTime ?? 0) >= getTime(endDateTime ?? 0) || getTime(startDateTime ?? 0) === getTime(endDateTime ?? 0)) {
      setValidation('Please provide a valid shift date time; end time should be greater than start time');
      return;
    }

    if (!notes || notes.length === 0) {
      setValidation('Please provide some notes for this activity');
      return;
    }

    if (shiftRun) {
      saveActivity({
        ...activity,
        startDateTime: getTime(startDateTime ?? 0),
        endDateTime: getTime(endDateTime ?? 0),
        shiftId: shiftRun.value,
        activityType: activityType?.value,
        notes,
      });
    }
  };

  const onUpdateActivity = () => {
    if (getTime(startDateTime ?? 0) >= getTime(endDateTime ?? 0) || getTime(startDateTime ?? 0) === getTime(endDateTime ?? 0)) {
      setValidation('Please provide a valid shift date time; end time should be greater than start time');
      return;
    }

    if (!notes || notes.length === 0) {
      setValidation('Please provide some notes for this activity');
      return;
    }

    if (shiftRun) {
      updateActivity({
        ...activity,
        startDateTime: getTime(startDateTime ?? 0),
        endDateTime: getTime(endDateTime ?? 0),
        shiftId: shiftRun.value,
        activityType: activityType?.value,
        notes,
      });
      setEditActivity(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="mb-5">
        <div className={`border border-gray-50 ${activity.newEntry && 'bg-primary-50'} shadow-lg rounded-lg p-4 sm:px-5 sm:py-2.5`}>
          {activity.newEntry && <div className="text-display-xs leading-display-xs text-gray-800 mb-3 mt-2">Add new activity</div>}
          {!activity.newEntry && !editActivity ? (
            <div className="flex flex-col lg:flex-row lg:items-center justify-between">
              <div className="flex flex-col lg:flex-row gap-2 lg:gap-3">
                <div className="mb-2">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">Activity</div>
                  <div className="mt-2 text-md leading-md text-gray-800 font-semibold">{camelCaseToNormalString(activity.activityType ?? '')}</div>
                </div>
                <div className="mb-2">
                  <div className="text-sm font-medium text-gray-700 w-auto">Date and Time</div>
                  <div className="mt-2 text-md leading-md text-gray-800">
                    {`${formatTime(activity.startDateTime ?? 0, 'EEEE dd MMM')}: ${formatTime(activity.startDateTime ?? 0, 'HH:mm')}-${formatTime(
                      activity.endDateTime ?? 0,
                      'HH:mm',
                    )}`}
                  </div>
                </div>
                {activity.notes && activity.notes !== '' && (
                  <div className="mb-2">
                    <div className="text-sm font-medium text-gray-700 capitalize w-auto">Notes</div>
                    <div className="mt-2 text-md leading-md text-gray-800">{activity.notes}</div>
                  </div>
                )}
              </div>
              <div className="flex flex-col sm:flex-row gap-2 shrink-0 justify-between mt-4 lg:mt-0">
                <button
                  type="button"
                  className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 lg:p-3 font-semibold text-md leading-md flex items-center justify-center"
                  onClick={onRemoveActivity}
                  aria-label="delete activity"
                >
                  <TrashIcon className="w-5 h-5 mr-2 lg:mr-0" />
                  <div className="lg:hidden">Delete</div>
                </button>
                <button
                  type="button"
                  className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 lg:p-3 font-semibold text-md leading-md flex items-center justify-center"
                  onClick={() => setEditActivity(true)}
                  aria-label="edit activity"
                >
                  <PencilIcon className="w-5 h-5 mr-2 lg:mr-0" />
                  <div className="lg:hidden">Update</div>
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col lg:flex-row gap-4 justify-between items-center">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                  <div className="mb-2">
                    <div className="text-sm font-medium text-gray-700 capitalize w-auto">Shift</div>
                    <div className="mt-2">
                      <ReactSelect options={shifts} onChange={onChangeShiftRun} selectedValue={shiftRun} />
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="text-sm font-medium text-gray-700 capitalize w-auto">Start time</div>
                    <div className="mt-2 sm:w-fit">
                      <TimePicker date={startDateTime} onChange={onChangeStartDateTime} />
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="text-sm font-medium text-gray-700 capitalize w-auto">End time</div>
                    <div className="mt-2 sm:w-fit">
                      <TimePicker date={endDateTime} onChange={onChangeEndDateTime} />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                  <div className="mb-2">
                    <div className="text-sm font-medium text-gray-700 capitalize w-auto">Activity</div>
                    <div className="mt-2">
                      <ReactSelect options={activityTypes} onChange={onChangeActivity} selectedValue={activityType} />
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="text-sm font-medium text-gray-700 capitalize w-auto">Notes</div>
                    <div className="mt-2">
                      <Input value={notes} onChange={onChangeNotes} />
                    </div>
                  </div>
                </div>
              </div>
              {!activity.newEntry && (
                <div className="flex flex-col sm:flex-row gap-2 shrink-0 justify-between mt-4 lg:mt-0 w-full lg:w-auto">
                  <button
                    type="button"
                    className="text-gray-500 font-semibold text-md leading-md"
                    onClick={() => {
                      setEditActivity(false);
                      setValidation('');
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center justify-center"
                    onClick={onUpdateActivity}
                  >
                    Save
                  </button>
                </div>
              )}
              {activity.newEntry && (
                <button
                  type="button"
                  // eslint-disable-next-line max-len
                  className="text-white disabled:opacity-50 bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center justify-center w-full sm:w-auto sm:self-end mb-2"
                  onClick={onSaveActivity}
                >
                  <PlusCircleIcon className="w-5 h-5 mr-2" />
                  Add
                </button>
              )}
            </div>
          )}
          {validation && (
            <div className="text-error-700 text-lg leading-lg flex items-center gap-2">
              <ExclamationTriangleIcon className="w-5 h-5" />
              {validation}
            </div>
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
}
