import { useState } from 'react';
import TWModal from 'components/TWModal';
import { FormProvider, useForm } from 'react-hook-form';
import { useUser } from 'context/userContext';
import { useParams, useSearchParams } from 'react-router-dom';
import { Message } from 'components';
import { useEditCustomerNote, useSaveCustomerNote } from 'api/hooks/useCustomers';
import { DateField } from 'components/Fields/DateField';
import { TextAreaField } from 'components/Fields/TextAreaField';
import { CustomerNote, Maybe } from '../../../../__generated__/graphql';

interface EditMeetingNoteProps {
  wellbeingNote: Maybe<CustomerNote> | undefined;
  onClose: () => void;
}

const EditMeetingNote = ({ wellbeingNote, onClose }: EditMeetingNoteProps) => {
  const methods = useForm<{
    id: string;
    note: string;
    date: number;
    customerId: string;
  }>({
    mode: 'onSubmit',
    defaultValues: {
      id: wellbeingNote?.id,
      customerId: wellbeingNote?.customerId,
      note: wellbeingNote?.note ? wellbeingNote?.note.replace(/<br\s*\/?>/g, '\r\n') : '',
      date: wellbeingNote?.date ? new Date(wellbeingNote?.date).getTime() : new Date().getTime(),
    },
  });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { handleSubmit } = methods;
  const { user } = useUser();
  const [searchParams] = useSearchParams();

  const { customerId } = useParams();
  const teamId = searchParams.get('teamId') || user?.teamId || '';
  const { editCustomerNote, mutationEditCustomerNote } = useEditCustomerNote({
    teamId,
    id: customerId,
  });
  const { saveCustomerNote, mutationSaveCustomerNote } = useSaveCustomerNote({
    teamId,
    id: customerId,
  });

  const onSaveWellbeingNote = async (note: CustomerNote) => {
    await editCustomerNote({
      variables: {
        input: {
          customerId,
          teamId,
          id: note.id,
          note: note.note,
          date: note.date,
        },
      },
    });
  };

  const onAddWellbeingNote = async (date: number, note: string) => {
    await saveCustomerNote({
      variables: {
        input: {
          customerId,
          teamId,
          note,
          date,
        },
      },
    });
  };
  const onSubmit = async (data: CustomerNote) => {
    if (wellbeingNote) {
      await onSaveWellbeingNote({
        ...data,
        note: data.note.replace(/\n\r?/g, '<br />'),
      });
    } else {
      await onAddWellbeingNote(data.date, data.note);
    }
    setOpenSnack(true);
    onClose();
  };

  return (
    <>
      <Message response={[mutationSaveCustomerNote, mutationEditCustomerNote]} openSnack={openSnack} setOpenSnack={setOpenSnack} />

      <FormProvider {...methods}>
        <form>
          <TWModal title={wellbeingNote ? 'Edit note' : 'Add note'} onClose={onClose} onSave={handleSubmit(onSubmit)} wFull testId="save-note-button">
            <DateField name="date" label="Date" />

            <TextAreaField name="note" label="Note" placeholder="Note" rows={10} testId="note-input" />
          </TWModal>
        </form>
      </FormProvider>
    </>
  );
};

export default EditMeetingNote;
