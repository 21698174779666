import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import { ArrowLeftIcon, ArrowPathIcon, PlusIcon, MapPinIcon, CalendarDaysIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { SaveInvoiceInput } from '__generated__/graphql';
import { Loading, LocationBar, Message } from 'components';
import TabSwitcher from 'components/TabSwitcher';
import { useSaveInvoice, useGetCustomerInvoice } from 'api/hooks/useInvoice';
import { useGetCustomerPackages } from 'api/hooks/useCustomerPackages';
import InvoiceUpdate from './InvoiceUpdate';
import InvoiceCarePackage from './InvoiceCarePackage';
import InvoiceCareMileage from './InvoiceCareMileage';
import InvoiceThriveBookings from './InvoiceThriveBookings';
import { MapInvoice } from './Mapper';

export default function InvoiceDetail() {
  const navigate = useNavigate();
  const { date, teamId, customerId } = useParams<{ date: string; teamId: string; customerId: string }>();
  const parsedDate = parseFloat(date || '0');
  const { invoice, loading } = useGetCustomerInvoice({ date: parsedDate, teamId, customerId });
  const { customerPackages } = useGetCustomerPackages({});
  const { saveInvoice, mutationSaveInvoice } = useSaveInvoice({ date: parsedDate, teamId, customerId });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const methods = useForm<SaveInvoiceInput>({
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset(MapInvoice(invoice));
  }, [invoice, reset]);

  const onSubmit = async (data: SaveInvoiceInput) => {
    setOpenSnack(true);
    await saveInvoice({
      awaitRefetchQueries: true,
      variables: {
        input: {
          ...data,
        },
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
    }, 2000);
  };

  const onBack = () => {
    navigate('/developer/invoices');
  };

  if (loading) {
    return <Loading />;
  }

  const tabs = [
    { label: 'Details', component: <InvoiceUpdate invoice={invoice} customerPackages={customerPackages} /> },
    { label: 'Thrive', component: <InvoiceThriveBookings invoice={invoice} onSubmit={onSubmit} /> },
  ];

  if (invoice?.invoiceType === 'Care') {
    tabs.push({ label: 'Care Package', component: <InvoiceCarePackage invoice={invoice} onSubmit={onSubmit} /> });
    tabs.push({ label: 'Mileage', component: <InvoiceCareMileage invoice={invoice} onSubmit={onSubmit} /> });
  }

  return (
    <>
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      <LocationBar section="Invoices" page="Manage Invoice" Icon={EmojiPeople} />
      <Message response={[mutationSaveInvoice]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="flex flex-col gap-3">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-10 px-4 md:px-[5%]">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={onBack}>
                <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
              </button>
              <div className="flex items-start justify-between mt-16">
                <div>
                  <div className="text-black text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">{invoice?.name}</div>
                  <div className="text-gray-600 text-md leading-md my-2 flex items-center">
                    <MapPinIcon className="w-5 h-5 mr-2 text-gray-600" />
                    <span className="pt-1">{invoice?.address}</span>
                  </div>
                  <div className="text-gray-600 text-md leading-md my-2 flex items-center">
                    <div className="mt-4">
                      {invoice?.invoiceType === 'Care' && (
                        <>
                          <div className="mb-2 text-sm">Monthly Value: £{invoice?.packagePrice?.monthlyValue}</div>
                          <div className="mb-2 text-sm">Hours per week: {invoice?.packagePrice?.numberOfHours}</div>
                          <div className="mb-2 text-sm">
                            Hourly Price: {invoice?.packagePrice?.monthlyValue} / ({invoice?.packagePrice?.numberOfHours} * 4.34) = £
                            {invoice?.packagePrice?.hourlyValue}
                          </div>
                          <div className="mb-2 text-sm">One Off Visits: £{invoice?.oneOffVisits?.Total || 0}</div>
                          <div className="mb-2 text-sm">Cancelled Visits: £{invoice?.cancelledVisits?.Total || 0}</div>
                          <div className="mb-2 text-sm">Mileage Price: £{invoice?.mileage?.Total || 0} (First 3 hours are free)</div>
                        </>
                      )}
                      <div className="mb-2 text-sm">Thrive: £{invoice?.thrive?.UnitAmount || 0}</div>
                      <div className="mb-2 text-sm">Thrive VAT: £{invoice?.thriveVat?.UnitAmount || 0}</div>
                      {invoice?.invoiceType === 'Care' && <div className="mt-4 text-xl font-bold">Total Price: £{invoice?.care?.Total}</div>}
                      {invoice?.invoiceType === 'Thrive' && <div className="mt-4 text-xl font-bold">Total Price: £{invoice?.thrive?.Total || 0}</div>}
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-between self-stretch hidden sm:flex">
                  <div>
                    <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                      <PlusIcon className="w-3.5 h-3.5 mr-2" />
                      Payroll Date: {format(invoice?.payrollStartDate ?? 0, 'dd/MM/yyyy')} - {format(invoice?.payrollEndDate ?? 0, 'dd/MM/yyyy')}
                    </div>
                    <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                      <CalendarDaysIcon className="w-3.5 h-3.5 mr-2" />
                      Invoice Date: {format(invoice?.invoiceDate ?? 0, 'dd/MM/yyyy')}
                    </div>
                    <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                      <CalendarIcon className="w-3.5 h-3.5 mr-2" />
                      Due Date: {format(invoice?.dueDate ?? 0, 'dd/MM/yyyy')}
                    </div>
                    <div className="text-gray-400 text-xs font-medium leading-xs mb-2 flex items-center">
                      <ArrowPathIcon className="w-3.5 h-3.5 mr-2" />
                      Updated Date: {format(invoice?.dateUpdated ?? 0, 'dd/MM/yyyy')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 sm:mt-16">
                <TabSwitcher tabs={tabs} />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
}
