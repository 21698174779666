import { Maybe, NoteActionType } from '__generated__/graphql';
import { useForm, FormProvider } from 'react-hook-form';
import { Add, Groups } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import { LocationBar, Message } from 'components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useUser } from 'context/userContext';
import { useGetTeamMeetings, useUpdateTeamMeeting } from 'api/hooks/useTeamMeetings';
import { useEffect, useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { TeamSelectField } from 'components/Fields/TeamSelectField';
import { DateField } from 'components/Fields/DateField';
import { FormPrompt } from 'components/modals/FormPromptModal';
import TabSwitcher from 'components/TabSwitcher';
import { TeamMeetingActionsField } from './fields/TeamMeetingActionsField';
import { ImageToTextField } from './fields/ImageToTextField';
import AttendeesField from './fields/AttendeesField';
import { TeamMeetingFormInput } from './types';

const TeamMeetingUpdate = () => {
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [assignApologiesModalState, toggleApologiesModal] = useState(false);
  const [assignAttendeesModalState, toggleAttendeesModal] = useState(false);

  const [searchParams] = useSearchParams();
  const { user } = useUser();
  const { teamMeetingId } = useParams<{ teamMeetingId?: string }>();
  const navigate = useNavigate();

  const selectedTeam = searchParams.get('teamId') || user?.teamId || '';
  const { updateTeamMeeting, mutationUpdateTeamMeeting } = useUpdateTeamMeeting({ teamId: selectedTeam });
  const { meetings } = useGetTeamMeetings({ teamId: selectedTeam });

  const selectedMeeting = meetings.find((m) => m.id === teamMeetingId);

  const methods = useForm<TeamMeetingFormInput>({
    mode: 'onSubmit',
    defaultValues: {},
  });
  const { handleSubmit, reset, formState } = methods;

  useEffect(() => {
    const updatedActions = selectedMeeting?.note?.actions?.map((action: Maybe<NoteActionType>) => {
      return {
        ownerId: action?.ownerId,
        action: action?.action,
        status: action?.status,
      };
    });

    reset(
      {
        teamId: selectedMeeting?.teamId,
        date: selectedMeeting?.date,
        note: {
          note: selectedMeeting?.note?.note ? selectedMeeting.note.note.replace(/<br\s*\/?>/g, '\r\n') : '',
          actions: updatedActions,
        },
        apologies: selectedMeeting ? selectedMeeting?.apologies : [],
        attendees: selectedMeeting
          ? selectedMeeting?.attendees.map((att) => {
              return {
                supportWorkerId: att || '',
              };
            })
          : [],
      },
      { keepDirty: true },
    );
  }, [reset, selectedMeeting, selectedMeeting?.apologies, selectedMeeting?.attendees, selectedMeeting?.note?.actions, selectedMeeting?.note?.note]);

  const onSubmit = async (data: TeamMeetingFormInput) => {
    await updateTeamMeeting({
      awaitRefetchQueries: true,
      variables: {
        input: {
          meetingId: selectedMeeting?.id,
          teamId: selectedTeam,
          note: {
            note: data.note?.note.replace(/\n\r?/g, '<br />'),
            actions: data.note?.actions || [],
          },
          apologies: data.apologies.map((app) => {
            return { supportWorkerId: app?.supportWorkerId, reason: app?.reason };
          }),
          attendees: data.attendees.map((attendee) => attendee.supportWorkerId),
        },
      },
    });
    reset({}, { keepDirty: false });
    setOpenSnack(true);
    // navigate(`/team-meetings?teamId=${selectedTeam}`);
  };

  const tabs = [
    {
      label: 'Notes',
      component: (
        <div className="mt-8 sm:mt-12">
          <ImageToTextField testId="meeting-note" name="note.note" label="Meeting Notes" placeholder="Meeting Notes" />
        </div>
      ),
    },
    {
      label: 'Attendence',
      component: (
        <div className="mt-8 sm:mt-12">
          <AttendeesField
            team={selectedTeam}
            toggleAttendeesModal={toggleAttendeesModal}
            assignAttendeesModalState={assignAttendeesModalState}
            toggleApologiesModal={toggleApologiesModal}
            assignApologiesModalState={assignApologiesModalState}
          />
        </div>
      ),
    },

    {
      label: 'Actions',
      component: (
        <div className="mt-8 sm:mt-12">
          <TeamMeetingActionsField name="note.actions" />
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Team Meetings</title>
      </Helmet>
      <LocationBar section="People" page="Team Meeting Update" Icon={Add} />
      <Message response={[mutationUpdateTeamMeeting]} openSnack={openSnack} setOpenSnack={setOpenSnack} />

      <div className="my-10 px-4 md:px-[5%]">
        <div className="flex md:justify-between justify-start flex-row place-items-center mb-8">
          <button
            type="button"
            className="text-gray-500 font-semibold text-md leading-md flex items-center"
            onClick={() => navigate(`/team-meetings?teamId=${selectedTeam}`)}
          >
            <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
          </button>
          <button
            type="button"
            className="text-primary-700 px-4 my-2 py-2 border border-primary-700 hidden md:flex flex-row items-center gap-2 rounded-lg font-medium text-md w-full sm:w-fit"
            onClick={() => navigate({ pathname: '/wellbeing-board', search: `teamId=${selectedTeam}` })}
            data-cy="wellbeing-board"
          >
            <Groups />
            Wellbeing Board
          </button>
        </div>
        <div className="md:hidden flex justify-between items-center gap-4 mt-4">
          <button
            type="button"
            className="text-primary-700 px-4 my-2 py-2 border border-primary-700 md:hidden flex flex-row items-center gap-2 rounded-lg font-medium text-md w-full sm:w-fit"
            onClick={() => navigate({ pathname: '/wellbeing-board', search: `teamId=${selectedTeam}` })}
            data-cy="wellbeing-board"
          >
            <Groups />
            Wellbeing Board
          </button>
        </div>
        <div className="mt-10 sm:mt-16">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TeamSelectField label="Team" name="teamId" disabled={true} />
              <DateField label="Date" name="date" testId="meeting-date" disabled={true} />
              <TabSwitcher tabs={tabs} />
              <div className="flex flex-row gap-2 my-3 ">
                <button
                  data-cy="save-meeting-button"
                  type="submit"
                  className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                >
                  Save
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <FormPrompt hasUnsavedChanges={formState.isDirty} />
    </>
  );
};

export default TeamMeetingUpdate;
