import React, { useEffect } from 'react';
import { formatTime, getDurationInMinutes, formatHoursAndMinutes } from 'services/helpers';
import { Shift, ReportingHours, Customer, UpdateReportingHourInput, ReportingHoursPeriod } from '__generated__/graphql';
import { SelectOption, User } from 'types/types';
import { Input } from 'components';
import Accordion from 'components/Accordian';
import ReactSelect from 'components/Select';
import Button from 'components/Button';
import { ShiftType, setType, VisitStatus, ShiftRunTypeEnum } from './service';

interface ShiftProps {
  user: User;
  period?: ReportingHoursPeriod | null;
  supportWorkerId: string;
  teamId: string;
  customers: Customer[];
  reportingHours?: ReportingHours | null;
  apiLoading: boolean;
  updateReportingHour: (input: UpdateReportingHourInput) => void;
}

const ShiftRow = ({ user, period, reportingHours, customers, supportWorkerId, teamId, apiLoading, updateReportingHour }: ShiftProps) => {
  const [hours, setHours] = React.useState<SelectOption>({ value: '0', label: '0' });
  const [minutes, setMinutes] = React.useState<SelectOption>({ value: '0', label: '0' });
  const [mileage, setMileage] = React.useState<string>('0');
  const [customerMileage, setCustomerMileage] = React.useState<string>('0');
  const [travelWithCustomerDestination, setDestination] = React.useState<string>('');
  const bankedHoursOptions = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ];
  const bankedMinutesOptions = [
    { value: '0', label: '0' },
    { value: '15', label: '15' },
    { value: '30', label: '30' },
    { value: '45', label: '45' },
  ];

  useEffect(() => {
    const duration = formatHoursAndMinutes(reportingHours?.bankedHours || 0);
    setHours({ value: duration?.hours.toString(), label: duration?.hours.toString() });
    setMinutes({ value: duration?.minutes.toString(), label: duration?.minutes.toString() });
    setMileage(reportingHours?.visit?.mileage?.toString() || '0');
    setCustomerMileage(reportingHours?.visit?.travelWithCustomerMileage?.toString() || '0');
    setDestination(reportingHours?.visit?.travelWithCustomerDestination?.toString() || '');
  }, [reportingHours]);

  if (!reportingHours || !reportingHours.visit) return null;

  const getCustomer = (sh: Shift) => {
    const customer = customers.find((c: Customer) => c.id === sh.customerId);
    return customer ? `${customer?.firstName} ${customer?.lastName}` : '';
  };

  function isNumber(n: string) {
    return !n || n.match(/^\d{1,}(\.\d{0,4})?$/);
  }

  const handleMileageChange = (m: string) => {
    if (!isNumber(m)) return;
    setMileage(m);
  };

  const handleCustomerMileageChange = (m: string) => {
    if (!isNumber(m)) return;
    setCustomerMileage(m);
  };

  const handleDestinationChange = (d: string) => {
    setDestination(d);
  };

  const onUpdate = () => {
    updateReportingHour({
      period: reportingHours.reportingHoursPeriodId,
      bankedHours: parseInt(hours.value, 10),
      bankedMinutes: parseInt(minutes.value, 10),
      mileage: parseFloat(mileage),
      customerMileage: parseFloat(customerMileage),
      travelWithCustomerDestination,
      id: reportingHours?.id,
      teamId,
      supportWorkerId: reportingHours.secondarySupportWorkerId,
    });
  };

  const duration = formatHoursAndMinutes(getDurationInMinutes(reportingHours.visit.startDateTime, reportingHours.visit.endDateTime));

  const isDisabled = (user.profile !== supportWorkerId || period?.status === 'Submitted') && !user?.permissions?.admin;

  const color = reportingHours?.visit?.type === ShiftType.activity ? 'bg-sky-100' : 'bg-slate-200';

  return (
    <>
      <div>
        <div className="text-lg leading-lg text-primary-700 font-semibold">{getCustomer(reportingHours.visit)}</div>
        <div className="text-md leading-md text-gray-800 font-medium mt-1">{setType(reportingHours, teamId, supportWorkerId)}</div>
        <div className="text-md leading-md text-gray-800 font-medium mt-1">
          Time: {formatTime(reportingHours.visit.startDateTime, 'k:mm')} - {formatTime(reportingHours.visit.endDateTime, 'k:mm')} - {duration.hours}
          hrs {duration.minutes} mins
        </div>
        {reportingHours.visit.status === VisitStatus.cancelled && <div className="text-md leading-md text-warning-700 font-medium mt-1">Visit Cancelled</div>}
        {reportingHours.visit.status === VisitStatus.moved && (
          <div className="text-md leading-md text-warning-700 font-medium mt-1">Visit has been moved (displayed for reference only)</div>
        )}
        {reportingHours.visit?.origin?.supportWorkerId && (
          <div className="text-md leading-md text-gray-800 font-medium mt-1">
            Moved From: {reportingHours.visit?.origin?.fullName ? reportingHours.visit?.origin?.fullName : 'TBC'}
          </div>
        )}
        {reportingHours.visit.status !== VisitStatus.cancelled && reportingHours.visit.status !== VisitStatus.moved && (
          <Accordion title="Add mileage" color={color}>
            <div className="mt-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Travel miles</div>
              <div className="mt-2">
                <Input value={mileage} onChange={handleMileageChange} disabled={isDisabled} />
              </div>
            </div>
            <div className="mt-3">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Person miles</div>
              <div className="mt-2">
                <Input value={customerMileage} onChange={handleCustomerMileageChange} disabled={isDisabled} />
              </div>
            </div>
            <div className="mt-3">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Destination</div>
              <div className="mt-2">
                <Input value={travelWithCustomerDestination} onChange={handleDestinationChange} disabled={isDisabled} />
              </div>
            </div>
            {(reportingHours?.shift?.ownerId !== supportWorkerId || reportingHours.shift.type === ShiftRunTypeEnum.oneoff) && (
              <>
                <div className="mt-3">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">Banked Hours</div>
                  <div className="mt-2">
                    <ReactSelect options={bankedHoursOptions} onChange={setHours} selectedValue={hours} />
                  </div>
                </div>
                <div className="mt-3">
                  <div className="text-sm font-medium text-gray-700 capitalize w-auto">Banked Minutes</div>
                  <div className="mt-2">
                    <ReactSelect options={bankedMinutesOptions} onChange={setMinutes} selectedValue={minutes} />
                  </div>
                </div>
              </>
            )}
            <div className="flex flex-col sm:flex-row gap-2 shrink-0 justify-between mt-5">
              <button
                type="button"
                className="text-white disabled:opacity-40 bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center justify-center"
                onClick={() => {
                  setMileage('0');
                  setCustomerMileage('0');
                  setDestination('');
                  setHours({ value: '0', label: '0' });
                  setMinutes({ value: '0', label: '0' });
                }}
              >
                Clear
              </button>
              <Button loading={apiLoading} color="bg-primary-700" label="Update" disabled={isDisabled} onClick={onUpdate} loadingLabel="Updating..." />
            </div>
          </Accordion>
        )}
      </div>
      <div className="w-full h-px bg-gray-100 my-4" />
    </>
  );
};

export default ShiftRow;
