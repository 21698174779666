import { Controller, useFormContext } from 'react-hook-form';
import FormLabel from '../FormLabel';

interface TextAreaFieldProps {
  label: string;
  placeholder: string;
  name: string;
  disabled?: boolean;
  rows?: number;
  testId?: string;
  validate?: (value: string) => boolean | string;
  isRequired?: boolean;
}

interface TextAreaFieldWithoutLabelProps {
  placeholder: string;
  name: string;
  disabled?: boolean;
  rows?: number;
  testId?: string;
  validate?: (value: string) => boolean | string;
  isRequired?: boolean;
}

const TextAreaFieldWithoutLabel = ({ rows, testId, placeholder, name, disabled = false, validate, isRequired = true }: TextAreaFieldWithoutLabelProps) => {
  const { control, register } = useFormContext(); // retrieve all hook methods
  return (
    <Controller
      name={name}
      control={control}
      rules={isRequired ? { required: 'This is required.', validate } : {}}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <textarea
              rows={rows || 12}
              disabled={disabled}
              value={value}
              ref={register(name).ref}
              onChange={(event) => (onChange ? onChange(event.target.value) : null)}
              // eslint-disable-next-line max-len
              className="bg-white px-5 py-2  disabled:bg-slate-50 disabled:text-slate-500 rounded-lg text-md leading-md text-gray-700 placeholder:text-gray-500 border border-gray-300 shadow-xs w-full"
              placeholder={placeholder ?? ''}
              data-cy={testId || name}
            />

            <p className="mt-2 text-sm text-error-600 dark:text-green-500">
              <span className="font-medium">{error?.message}</span>
            </p>
          </>
        );
      }}
    />
  );
};
const TextAreaField = (props: TextAreaFieldProps) => {
  return (
    <FormLabel label={props.label}>
      <div className="w-full lg:w-1/2">
        <TextAreaFieldWithoutLabel {...props} />
      </div>
    </FormLabel>
  );
};

export { TextAreaField, TextAreaFieldWithoutLabel };
