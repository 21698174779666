import { useWatch } from 'react-hook-form';
import { InputField } from 'components/Fields/InputField';
import { DateField } from 'components/Fields/DateField';
import { SelectField } from 'components/Fields/SelectField';
import { CustomerPackage, Invoice } from '__generated__/graphql';
import { formatTime } from 'services/helpers';

interface InvoiceUpdateProps {
  customerPackages: CustomerPackage[];
  invoice: Invoice | undefined;
}

export default function InvoiceUpdate({ customerPackages, invoice }: InvoiceUpdateProps) {
  const packageId = useWatch({ name: 'packageId' });

  const packageOptions =
    customerPackages
      .filter((cp) => cp.customerId === invoice?.customerId)
      .map((cp) => ({
        label: `${formatTime(cp.startDate || 0, 'do MMMM, yyyy')} - ${cp.endDate ? formatTime(cp.endDate, 'do MMMM, yyyy') : 'Active'}` || '',
        value: cp.id || '',
      })) || [];

  const packagePriceOptions =
    customerPackages
      .find((p) => p.id === packageId)
      ?.prices?.map((cp) => ({
        label:
          `${formatTime(cp?.startDate || 0, 'do MMMM, yyyy')} - ${cp?.endDate ? formatTime(cp?.endDate, 'do MMMM, yyyy') : 'Active'} - £${cp?.monthlyValue}` ||
          '',
        value: cp?.id || '',
      })) || [];

  return (
    <div className="mt-8 sm:mt-12">
      <DateField label="Invoice Date" name="invoiceDate" />
      <DateField label="Due Date" name="dueDate" />
      <InputField label="Invoice Number" placeholder="InvoiceNumber" name="invoiceNumber" />
      <InputField label="Name" placeholder="Name" name="name" />
      <SelectField isRequired options={packageOptions} label="Package" name="packageId" />
      <SelectField isRequired options={packagePriceOptions} label="Package Price" name="packagePriceId" />
      <div className="flex justify-end">
        <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      </div>
    </div>
  );
}
