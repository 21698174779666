import { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { getTime, add } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { EmojiPeople } from '@mui/icons-material';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Message, LocationBar, Loading } from 'components';
import { SelectField } from 'components/Fields/SelectField';
import { InputField } from 'components/Fields/InputField';
import { RadioField } from 'components/Fields/RadioField';
import { TeamSelectField } from 'components/Fields/TeamSelectField';
import { DateField } from 'components/Fields/DateField';
import { useGetCustomers } from 'api/hooks/useCustomers';
import { SupportedPersonSelectField } from 'components/Fields/SupportedPersonSelectField';
import { useCreateInvoice } from 'api/hooks/useInvoice';
import { getLastDayOfPayroll, getFirstDayOfPayroll, getPeriodsForYear, formatTime } from 'services/helpers';
import { useGetCustomerPackages } from 'api/hooks/useCustomerPackages';
import { useGetBookingsLazy } from 'api/hooks/useThriveBookings';

const InvoiceCreate = () => {
  const [validation, setValidation] = useState<string | null>(null);
  const { customerPackages } = useGetCustomerPackages({});
  const { saveInvoice, mutationSaveInvoice } = useCreateInvoice();
  const { customers, loading } = useGetCustomers({ showAll: true });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>('');
  const { getBookingsForThrive, bookings } = useGetBookingsLazy();
  const invoiceTypes = ['Care', 'Thrive'];

  const periods = getPeriodsForYear().map((p) => {
    return { label: formatTime(p, 'do MMMM, yyyy'), value: getFirstDayOfPayroll(p).toString() };
  });

  const methods = useForm<{
    teamId: string;
    name: string;
    customerId: string;
    dueDate: number;
    invoiceDate: number;
    payrollStartDate: number;
    payrollEndDate: number;
    invoiceNumber: string;
    invoiceType: string;
    thriveCustomerId: string;
    packageId: string;
    packagePriceId: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      invoiceType: invoiceTypes[0],
    },
  });

  const { handleSubmit, watch, setValue } = methods;
  const navigate = useNavigate();
  const customer = watch('customerId');
  const thriveCustomer = watch('thriveCustomerId');
  const payrollStartDate = watch('payrollStartDate');
  const invoiceType = watch('invoiceType');
  const packageId = watch('packageId');

  useEffect(() => {
    if (payrollStartDate) {
      const newStartDate = getFirstDayOfPayroll(parseFloat(payrollStartDate.toString()));
      const newEndDate = getLastDayOfPayroll(newStartDate);
      const invoiceDate = getTime(add(newEndDate, { days: 3 }));
      const dueDate = getTime(add(newEndDate, { days: 10 }));
      setValue('payrollEndDate', newEndDate);
      setValue('invoiceDate', invoiceDate);
      setValue('dueDate', dueDate);

      getBookingsForThrive({
        variables: {
          query: {
            startDateTime: newStartDate,
            endDateTime: newEndDate,
          },
        },
      });
    }
  }, [payrollStartDate, setValue, getBookingsForThrive]);

  const thriveCustomers = bookings.map((booking) => {
    const name = `${booking?.customer?.firstName} ${booking?.customer?.surname}`;
    return { label: name, value: booking?.customer?.id || '' };
  });

  useEffect(() => {
    const filtered = customers.find((c) => c.id === customer);

    if (filtered) {
      setValue('teamId', filtered.teamId);
      setValue('name', `${filtered.firstName} ${filtered.lastName}`);
      setCustomerId(filtered.id);
    }
  }, [customer, customers, customerPackages, setValue]);

  useEffect(() => {
    const filtered = bookings.find((c) => c?.customer?.id === thriveCustomer);

    if (filtered) {
      setValue('name', `${filtered?.customer?.firstName} ${filtered?.customer?.surname}`);
    }
  }, [thriveCustomer, bookings, setValue]);

  const onSubmit = async (data: {
    teamId: string;
    name: string;
    customerId: string;
    dueDate: number;
    invoiceDate: number;
    invoiceNumber: string;
    payrollStartDate: number;
    payrollEndDate: number;
    invoiceType: string;
    thriveCustomerId: string;
    packageId: string;
    packagePriceId: string;
  }) => {
    setOpenSnack(true);
    if (invoiceType === invoiceTypes[0] && !data.packagePriceId) {
      setValidation('This supported person does not have a package.');
      return;
    }

    let address = '';
    if (invoiceType === invoiceTypes[0]) {
      const care = customers.find((c) => c.id === data.customerId);
      address = `${care?.address}, ${care?.postcode}`;
    } else {
      const thrive = bookings.find((c) => c.customer?.id === data.thriveCustomerId);
      address = `${thrive?.address?.addressLine1}, ${thrive?.address?.postcode}`;
    }

    await saveInvoice({
      awaitRefetchQueries: true,
      variables: {
        input: {
          payrollStartDate: data.payrollStartDate,
          payrollEndDate: data.payrollEndDate,
          name: data.name,
          dueDate: data.dueDate,
          invoiceDate: data.invoiceDate,
          invoiceNumber: data.invoiceNumber,
          invoiceType: data.invoiceType,
          customerId: invoiceType === invoiceTypes[1] ? data.thriveCustomerId : data.customerId,
          teamId: invoiceType === invoiceTypes[1] ? invoiceTypes[1] : data.teamId,
          address,
          packageId: data.packageId,
          packagePriceId: data.packagePriceId,
        },
      },
    });
    setTimeout(() => {
      setOpenSnack(false);
      navigate(`/developer/invoices/${data.payrollStartDate}/${data.teamId || 'Thrive'}/${data.customerId || data.thriveCustomerId}`);
    }, 2000);
  };

  const packageOptions =
    customerPackages
      .filter((cp) => cp.customerId === customerId)
      .map((cp) => ({
        label: `${formatTime(cp.startDate || 0, 'do MMMM, yyyy')} - ${cp.endDate ? formatTime(cp.endDate, 'do MMMM, yyyy') : 'Active'}` || '',
        value: cp.id || '',
      })) || [];

  const packagePriceOptions =
    customerPackages
      .find((p) => p.id === packageId)
      ?.prices?.map((cp) => ({
        label:
          `${formatTime(cp?.startDate || 0, 'do MMMM, yyyy')} - ${cp?.endDate ? formatTime(cp?.endDate, 'do MMMM, yyyy') : 'Active'} - £${cp?.monthlyValue}` ||
          '',
        value: cp?.id || '',
      })) || [];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      <LocationBar section="Invoices" page="Create Invoice" Icon={EmojiPeople} />
      <Message response={[mutationSaveInvoice]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button type="button" className="text-gray-500 font-semibold text-md leading-md flex items-center" onClick={() => navigate('/developer/invoices/')}>
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>
        {validation && <div className="text-sm leading-lg mt-5 text-red-800">{validation}</div>}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-10 sm:mt-16">
              <div className="mt-8 sm:mt-12">
                <SelectField isRequired options={periods} label="Start Date" name="payrollStartDate" />
                {payrollStartDate && (
                  <>
                    {payrollStartDate && (
                      <>
                        <DateField label="End Date" name="payrollEndDate" disabled />
                        <DateField label="Invoice Date" name="invoiceDate" disabled />
                        <DateField label="Due Date" name="dueDate" disabled />
                      </>
                    )}
                    <RadioField radioOptions={invoiceTypes} testId="invoice-type" label="Invoice Type" name="invoiceType" />
                    {invoiceType === invoiceTypes[1] && <SelectField isRequired options={thriveCustomers} label="Thrive Customer" name="thriveCustomerId" />}
                    {invoiceType === invoiceTypes[0] && (
                      <>
                        <SupportedPersonSelectField name="customerId" label="Supported Person" searchable />
                        <TeamSelectField label="Team" name="teamId" disabled searchable />
                      </>
                    )}
                    {customer && packageOptions.length > 0 && <SelectField isRequired options={packageOptions} label="Package" name="packageId" />}
                    {customer && packagePriceOptions.length > 0 && (
                      <SelectField isRequired options={packagePriceOptions} label="Package Price" name="packagePriceId" />
                    )}
                    {customer && (
                      <>
                        <InputField label="Name" placeholder="name" name="name" disabled />
                        <InputField label="Invoice Number" placeholder="InvoiceNumber" name="invoiceNumber" isRequired />
                      </>
                    )}
                  </>
                )}
              </div>
              <button
                disabled={!payrollStartDate}
                type="submit"
                data-cy="save-candidate-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
              >
                Save
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default InvoiceCreate;
