import { isEqual } from 'date-fns';

export const doDateRangesOverlap = (start1: Date, start2: Date, end1: Date, end2: Date) => {
  return (
    (start1 > start2 && start1 < end2) ||
    (end1 > start2 && end1 < end2) ||
    (start1 < start2 && end1 > end2) ||
    (isEqual(start1, start2) && isEqual(end1, end2)) ||
    (isEqual(start1, start2) && end1 > end2) ||
    (start1 < start2 && isEqual(end1, end2))
  );
};

export const createDateWithTime = (date: number, time: number) => {
  const d = new Date(date);

  d.setHours(new Date(time).getHours(), new Date(time).getMinutes(), 0, 0);
  return d;
};
