import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Invoice, Shift } from '__generated__/graphql';
import { useGetVisitsForCustomerByDateRange } from 'api/hooks/useInvoice';
import { getDurationInMinutes } from 'services/helpers';
import { Table, Loading } from 'components';
import { MapInvoice } from './Mapper';
import { ChecklistItem, columnVisibility } from './types';

interface InvoiceCarePackageProps {
  invoice: Invoice | undefined;
  onSubmit: (invoice: Invoice) => void;
}

export default function InvoiceCarePackage({ invoice, onSubmit }: InvoiceCarePackageProps) {
  const [rowSelection, setRowSelection] = useState<ChecklistItem[]>([]);

  const { visits, loading } = useGetVisitsForCustomerByDateRange({
    customerId: invoice?.customerId,
    startDateTime: invoice?.payrollStartDate,
    endDateTime: invoice?.payrollEndDate,
    teamId: invoice?.teamId,
  });

  const setChecked = (v: Shift, inv?: Invoice) => {
    // if (inv?.careItems?.length === 0 && v?.status === 'active') return true;
    return inv?.careItems?.some((i) => i?.id === v.id && i?.included) || false;
  };

  useEffect(() => {
    if (visits?.length > 0) {
      const checked = visits
        .filter((v) => (v.type === 'visit' && v?.status === 'cancelled') || v.type === 'oneoff')
        .map((v) => ({
          id: v.id,
          checked: setChecked(v, invoice),
          status: v.status,
          startDateTime: v?.startDateTime,
          endDateTime: v?.endDateTime,
          type: v?.type === 'visit' ? 'rota' : v?.type,
          cancelledDateTime: v?.cancelledDateTime,
          whoCancelled: v?.whoCancelled,
          oneOffVisitReason: v?.oneOffVisitReason,
          oneOffVisitNote: v?.oneOffVisitNote,
          travelWithCustomerMileage: v?.travelWithCustomerMileage,
          travelWithCustomerDestination: v?.travelWithCustomerDestination,
        }));
      setRowSelection(checked);
    }
  }, [visits, invoice]);

  const onSaveInvoiceItems = () => {
    onSubmit(
      MapInvoice({
        ...invoice,
        careItems: rowSelection.map((r) => ({
          id: r.id,
          included: r.checked,
          type: r.type,
          status: r.status,
          startDateTime: r.startDateTime || 0,
          endDateTime: r.endDateTime || 0,
          whoCancelled: r.whoCancelled,
          cancelledDateTime: r.cancelledDateTime,
          travelWithCustomerMileage: r?.travelWithCustomerMileage,
          travelWithCustomerDestination: r?.travelWithCustomerDestination,
        })),
      }),
    );
  };

  const onSelectItem = (id: string) => {
    setRowSelection(rowSelection.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item)));
  };

  const RowActions = ({ row }: { row: ChecklistItem }) => {
    if (row?.type === 'rota' && row?.status === 'active') return null;
    return (
      <input
        onChange={(e) => onSelectItem(e.target.value)}
        checked={row.status ? row.checked : true}
        type="checkbox"
        className="w-4 h-4 text-primary-300 bg-gray-100 border-gray-300 rounded cursor-pointer"
        value={row.id || ''}
      />
    );
  };

  const columnHelper = createColumnHelper<ChecklistItem>();

  const columns1 = [
    columnHelper.accessor('startDateTime', {
      enableSorting: false,
      header: () => '#',
      cell: (info) => <div data-cy="list-index">{info.row.index + 1}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('startDateTime', {
      enableSorting: false,
      header: () => 'Date',
      cell: (info) => <div data-cy="list-invoiceDate">{format(info.renderValue() ?? 0, 'PPPP')}</div>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('endDateTime', {
      enableSorting: false,
      header: () => 'Time',
      cell: (info) => (
        <div data-cy="list-invoiceDate">
          {format(info.row.original.startDateTime ?? 0, 'HH:mm')} - {format(info.renderValue() ?? 0, 'HH:mm')}
        </div>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('cancelledDateTime', {
      enableSorting: false,
      header: () => 'Notes',
      cell: (info) => (
        <div>
          <div>{info.renderValue() ? `Cancelled On: ${format(info.renderValue() ?? 0, 'PPPP')}` : ''}</div>
          <div>{info.row.original.cancelledDateTime ? `By: ${info.row.original.whoCancelled}` : ''}</div>
          <div>{info.row.original.type === 'oneoff' ? `Reason: ${info.row.original.oneOffVisitReason}` : ''}</div>
          <div>
            {info.row.original.type === 'oneoff' ? `Note: ${info.row.original.oneOffVisitNote}` : ''}
            {info.row.original.type === 'oneoff'
              ? `, Duration: ${getDurationInMinutes(info.row.original.startDateTime || 0, info.row.original.endDateTime || 0)}mins`
              : ''}
          </div>
        </div>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('status', {
      enableSorting: false,
      header: () => 'Status',
      cell: (info) => {
        return <div data-cy="list-name">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('type', {
      enableSorting: false,
      header: () => 'Type',
      cell: (info) => {
        return <div data-cy="list-name">{info.renderValue()}</div>;
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      cell: (props) => <RowActions row={props.row.original} />,
      header: () => (
        <button type="button" onClick={onSaveInvoiceItems} className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
          Save
        </button>
      ),
    }),
  ] as Array<ColumnDef<ChecklistItem, unknown>>;

  return (
    <div className="mt-8">
      <div className="flex flex-col mt-2">
        {loading && <Loading isComponent />}
        {!loading && rowSelection && rowSelection.length > 0 && (
          <Table<ChecklistItem> pageSize={500} data={rowSelection} columns={columns1} columnVisibility={columnVisibility} />
        )}
        {!loading && (!rowSelection || (rowSelection.length === 0 && <div>There are no one off or cancelled visits to consider.</div>))}
      </div>
    </div>
  );
}
