import { useEffect, useState } from 'react';
import { getTimeInDecimal } from 'services/helpers';
import { useGetSupportWorkers } from 'api/hooks/useSupportWorkers';
import { useGetAgreedShifts } from 'api/hooks/useAgreedShifts';
import ReactSelect from 'components/Select';
import { SupportWorker as SupportWorkerType, AgreedShift, AgreedShiftRun, AgreedVisitType, Maybe } from '../../../__generated__/graphql';

interface RotaStatsProps {
  selectedTeam: string;
  selectedAgreedRota: string;
}

const RotaStats = ({ selectedTeam, selectedAgreedRota }: RotaStatsProps) => {
  const [totalHoursOnShift, setTotalHoursOnShift] = useState(0);
  const [totalHoursOnVisit, setTotalHoursOnVisit] = useState(0);
  const [totalHoursTbc, setTotalHoursTbc] = useState(0);
  const [totalTbcs, setTotalTbcs] = useState(0);
  const [hoursAMShift, setHoursAMShift] = useState(0);
  const [hoursAMVisit, setHoursAMVisit] = useState(0);
  const [hoursPMShift, setHoursPMShift] = useState(0);
  const [hoursPMVisit, setHoursPMVisit] = useState(0);
  const [hoursWeekDayShift, setHoursWeekDayShift] = useState(0);
  const [hoursWeekDayVisit, setHoursWeekDayVisit] = useState(0);
  const [hoursWeekendShift, setHoursWeekendShift] = useState(0);
  const [hoursWeekendVisit, setHoursWeekendVisit] = useState(0);
  const [averageHoursDayShift, setAverageHoursDayShift] = useState(0);
  const [averageHoursDayVisit, setAverageHoursDayVisit] = useState(0);
  const [hoursToMakeUp, setHoursToMakeUp] = useState(0);
  const [weekSelector, setWeekSelector] = useState({ value: '0', label: 'Whole rota' });
  const weekOptions = [
    { value: '0', label: 'Whole rota' },
    { value: '1', label: 'Week 1' },
    { value: '2', label: 'Week 2' },
  ];
  const { agreedShifts } = useGetAgreedShifts({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });

  const { supportWorkers } = useGetSupportWorkers({
    teamId: selectedTeam,
  });

  useEffect(() => {
    let newTotalHoursOnShift = 0;
    let newTotalHoursOnVisit = 0;
    let newTotalHoursTbc = 0;
    let newTotalTbcs = 0;
    let newHoursAMShift = 0;
    let newHoursPMShift = 0;
    let newHoursAMVisit = 0;
    let newHoursPMVisit = 0;
    let newHoursWeekdayShift = 0;
    let newHoursWeekdayVisit = 0;
    let newHoursWeekendShift = 0;
    let newHoursWeekendVisit = 0;
    let numberofDaysInShift = 0;
    let newHoursToMakeUp = 0;
    if (weekSelector.value === '0' || weekSelector.value === '1') {
      agreedShifts?.week1?.forEach((shiftRun: Maybe<AgreedShiftRun>) => {
        shiftRun?.shifts?.forEach((shift: Maybe<AgreedShift>) => {
          if (shiftRun.startDateTime && shiftRun.endDateTime) {
            newTotalHoursOnShift += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            numberofDaysInShift += 1;
            if (shiftRun.period?.includes('AM')) {
              newHoursAMShift += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            }
            if (shiftRun.period?.includes('PM')) {
              newHoursPMShift += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            }
            if (shift?.day && shift?.day < 5) {
              newHoursWeekdayShift += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            } else {
              newHoursWeekendShift += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            }
          }
          shift?.visits?.forEach((visit: Maybe<AgreedVisitType>) => {
            if (visit?.startDateTime && visit.endDateTime) {
              newTotalHoursOnVisit += getTimeInDecimal(visit.startDateTime, visit.endDateTime);
              if (shift?.ownerId === '') {
                newTotalTbcs += 1;
                newTotalHoursTbc += getTimeInDecimal(visit.startDateTime, visit.endDateTime);
              }
              if (shiftRun.period?.includes('AM')) {
                newHoursAMVisit += getTimeInDecimal(visit.startDateTime, visit.endDateTime);
              }
              if (shiftRun.period?.includes('PM')) {
                newHoursPMVisit += getTimeInDecimal(visit.startDateTime, visit.endDateTime);
              }
              if (shift.day < 5) {
                newHoursWeekdayVisit += getTimeInDecimal(visit.startDateTime, visit.endDateTime);
              } else {
                newHoursWeekendVisit += getTimeInDecimal(visit.startDateTime, visit.endDateTime);
              }
            }
          });
        });
      });
    }
    if (weekSelector.value === '0' || weekSelector.value === '2') {
      agreedShifts?.week2?.forEach((shiftRun: Maybe<AgreedShiftRun>) => {
        shiftRun?.shifts?.forEach((shift: Maybe<AgreedShift>) => {
          if (shiftRun.startDateTime && shiftRun.endDateTime) {
            newTotalHoursOnShift += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            numberofDaysInShift += 1;
            if (shiftRun.period?.includes('AM')) {
              newHoursAMShift += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            }
            if (shiftRun.period?.includes('PM')) {
              newHoursPMShift += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            }
            if (shift?.day && shift?.day < 5) {
              newHoursWeekdayShift += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            } else {
              newHoursWeekendShift += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            }
          }
          shift?.visits?.forEach((visit: Maybe<AgreedVisitType>) => {
            if (visit?.startDateTime && visit?.endDateTime) {
              newTotalHoursOnVisit += getTimeInDecimal(visit?.startDateTime, visit?.endDateTime);
              if (shift?.ownerId === '') {
                newTotalTbcs += 1;
                newTotalHoursTbc += getTimeInDecimal(visit?.startDateTime, visit?.endDateTime);
              }
              if (shiftRun.period?.includes('AM')) {
                newHoursAMVisit += getTimeInDecimal(visit?.startDateTime, visit?.endDateTime);
              }
              if (shiftRun.period?.includes('PM')) {
                newHoursPMVisit += getTimeInDecimal(visit?.startDateTime, visit?.endDateTime);
              }
              if (shift?.day < 5) {
                newHoursWeekdayVisit += getTimeInDecimal(visit.startDateTime, visit.endDateTime);
              } else {
                newHoursWeekendVisit += getTimeInDecimal(visit.startDateTime, visit.endDateTime);
              }
            }
          });
        });
      });
    }
    supportWorkers?.forEach((supportWorker: SupportWorkerType) => {
      let hoursInAgreedShifts = 0;
      if (weekSelector.value === '0' || weekSelector.value === '1') {
        agreedShifts.week1?.forEach((shiftRun: Maybe<AgreedShiftRun>) => {
          shiftRun?.shifts?.forEach((shift: Maybe<AgreedShift>) => {
            if (shift?.ownerId === supportWorker.id && shiftRun.startDateTime && shiftRun.endDateTime) {
              hoursInAgreedShifts += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            }
          });
        });
      }
      if (weekSelector.value === '0' || weekSelector.value === '2') {
        agreedShifts.week2?.forEach((shiftRun: Maybe<AgreedShiftRun>) => {
          shiftRun?.shifts?.forEach((shift: Maybe<AgreedShift>) => {
            if (shift?.ownerId === supportWorker.id && shiftRun.startDateTime && shiftRun.endDateTime) {
              hoursInAgreedShifts += getTimeInDecimal(shiftRun.startDateTime, shiftRun.endDateTime);
            }
          });
        });
      }
      if (weekSelector.value === '0') {
        newHoursToMakeUp += supportWorker.contractedHours > 3 ? supportWorker.contractedHours * 2 - 3 - hoursInAgreedShifts : supportWorker.contractedHours;
      } else {
        newHoursToMakeUp += supportWorker.contractedHours > 3 ? supportWorker.contractedHours - 1.5 - hoursInAgreedShifts : supportWorker.contractedHours;
      }
    });
    setTotalHoursOnShift(newTotalHoursOnShift);
    setTotalHoursOnVisit(newTotalHoursOnVisit);
    setTotalHoursTbc(newTotalHoursTbc);
    setTotalTbcs(newTotalTbcs);
    setHoursAMShift(newHoursAMShift);
    setHoursAMVisit(newHoursAMVisit);
    setHoursPMShift(newHoursPMShift);
    setHoursPMVisit(newHoursPMVisit);
    setHoursWeekDayShift(newHoursWeekdayShift);
    setHoursWeekDayVisit(newHoursWeekdayVisit);
    setHoursWeekendShift(newHoursWeekendShift);
    setHoursWeekendVisit(newHoursWeekendVisit);
    setAverageHoursDayShift(newTotalHoursOnShift / numberofDaysInShift || 0);
    setAverageHoursDayVisit(newTotalHoursOnVisit / numberofDaysInShift || 0);
    setHoursToMakeUp(newHoursToMakeUp);
  }, [agreedShifts, supportWorkers, weekSelector]);

  return (
    <div className="my-5">
      <div className="mt-5 w-fit">
        <ReactSelect selectedValue={weekSelector} options={weekOptions} onChange={setWeekSelector} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 mt-10">
        <div className="border border-gray-100 rounded-lg shadow-md p-5 flex flex-col justify-between gap-4">
          <div className="text-display-sm leading-display-sm">Total Hours</div>
          <div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-green-400 ${(totalHoursOnShift * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (totalHoursOnShift * 100) / 300 > 100 ? '100%' : (totalHoursOnShift * 100) / 300 > 0 ? `${(totalHoursOnShift * 100) / 300}%` : '1%',
                }}
              >
                {(totalHoursOnShift * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    On shift:
                    <span className="font-semibold ml-2">{parseFloat(totalHoursOnShift.toFixed(2))}</span>
                  </div>
                )}
              </div>
              {(totalHoursOnShift * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  On shift:
                  <span className="font-semibold ml-2">{parseFloat(totalHoursOnShift.toFixed(2))}</span>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-green-700 ${(totalHoursOnVisit * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (totalHoursOnVisit * 100) / 300 > 100 ? '100%' : (totalHoursOnVisit * 100) / 300 > 0 ? `${(totalHoursOnVisit * 100) / 300}%` : '1%',
                }}
              >
                {(totalHoursOnVisit * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    On visit:
                    <span className="font-semibold ml-2">
                      {parseFloat(totalHoursOnVisit.toFixed(2))}{' '}
                      {(totalHoursOnVisit * 100) / totalHoursOnShift > 100
                        ? '(100%)'
                        : (totalHoursOnVisit * 100) / totalHoursOnShift > 0
                        ? `(${parseFloat(((totalHoursOnVisit * 100) / totalHoursOnShift).toFixed(2))}%)`
                        : '(0%)'}
                    </span>
                  </div>
                )}
              </div>
              {(totalHoursOnVisit * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  On visit:
                  <span className="font-semibold ml-2">
                    {parseFloat(totalHoursOnVisit.toFixed(2))}{' '}
                    {(totalHoursOnVisit * 100) / totalHoursOnShift > 100
                      ? '(100%)'
                      : (totalHoursOnVisit * 100) / totalHoursOnShift > 0
                      ? `(${parseFloat(((totalHoursOnVisit * 100) / totalHoursOnShift).toFixed(2))}%)`
                      : '(0%)'}
                  </span>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-error-500 ${(totalHoursTbc * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (totalHoursTbc * 100) / 300 > 100 ? '100%' : (totalHoursTbc * 100) / 300 > 0 ? `${(totalHoursTbc * 100) / 300}%` : '1%',
                }}
              >
                {(totalHoursTbc * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    TBC hours:
                    <span className="font-semibold ml-2">
                      {parseFloat(totalHoursTbc.toFixed(2))} ({totalTbcs} visits){' '}
                      {(totalHoursTbc * 100) / totalHoursOnShift > 100
                        ? '(100%)'
                        : (totalHoursTbc * 100) / totalHoursOnShift > 0
                        ? `(${parseFloat(((totalHoursTbc * 100) / totalHoursOnShift).toFixed(2))}%)`
                        : '(0%)'}
                    </span>
                  </div>
                )}
              </div>
              {(totalHoursTbc * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  TBC hours:
                  <span className="font-semibold ml-2">
                    {parseFloat(totalHoursTbc.toFixed(2))} ({totalTbcs} visits){' '}
                    {(totalHoursTbc * 100) / totalHoursOnShift > 100
                      ? '(100%)'
                      : (totalHoursTbc * 100) / totalHoursOnShift > 0
                      ? `(${parseFloat(((totalHoursTbc * 100) / totalHoursOnShift).toFixed(2))}%)`
                      : '(0%)'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="border border-gray-100 rounded-lg shadow-md p-5 flex flex-col justify-between gap-4">
          <div className="text-display-sm leading-display-sm">Hours on AM runs</div>
          <div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-green-400 ${(hoursAMShift * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (hoursAMShift * 100) / 300 > 100 ? '100%' : (hoursAMShift * 100) / 300 > 0 ? `${(hoursAMShift * 100) / 300}%` : '1%',
                }}
              >
                {(hoursAMShift * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    On shift:
                    <span className="font-semibold ml-2">{parseFloat(hoursAMShift.toFixed(2))}</span>
                  </div>
                )}
              </div>
              {(hoursAMShift * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  On shift:
                  <span className="font-semibold ml-2">{parseFloat(hoursAMShift.toFixed(2))}</span>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-green-700 ${(hoursAMVisit * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (hoursAMVisit * 100) / 300 > 100 ? '100%' : (hoursAMVisit * 100) / 300 > 0 ? `${(hoursAMVisit * 100) / 300}%` : '1%',
                }}
              >
                {(hoursAMVisit * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    On visit:
                    <span className="font-semibold ml-2">
                      {parseFloat(hoursAMVisit.toFixed(2))}{' '}
                      {(hoursAMVisit * 100) / hoursAMShift > 100
                        ? '(100%)'
                        : (hoursAMVisit * 100) / hoursAMShift > 0
                        ? `(${parseFloat(((hoursAMVisit * 100) / hoursAMShift).toFixed(2))}%)`
                        : '(1%)'}
                    </span>
                  </div>
                )}
              </div>
              {(hoursAMVisit * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  On visit:
                  <span className="font-semibold ml-2">
                    {parseFloat(hoursAMVisit.toFixed(2))}{' '}
                    {(hoursAMVisit * 100) / hoursAMShift > 100
                      ? '(100%)'
                      : (hoursAMVisit * 100) / hoursAMShift > 0
                      ? `(${parseFloat(((hoursAMVisit * 100) / hoursAMShift).toFixed(2))}%)`
                      : '(1%)'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="border border-gray-100 rounded-lg shadow-md p-5 flex flex-col justify-between gap-4">
          <div className="text-display-sm leading-display-sm">Hours on PM runs</div>
          <div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-green-400 ${(hoursPMShift * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (hoursPMShift * 100) / 300 > 100 ? '100%' : (hoursPMShift * 100) / 300 > 0 ? `${(hoursPMShift * 100) / 300}%` : '1%',
                }}
              >
                {(hoursPMShift * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    On shift:
                    <span className="font-semibold ml-2">{parseFloat(hoursPMShift.toFixed(2))}</span>
                  </div>
                )}
              </div>
              {(hoursPMShift * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  On shift:
                  <span className="font-semibold ml-2">{parseFloat(hoursPMShift.toFixed(2))}</span>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-green-700 ${(hoursPMVisit * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (hoursPMVisit * 100) / 300 > 100 ? '100%' : (hoursPMVisit * 100) / 300 > 0 ? `${(hoursPMVisit * 100) / 300}%` : '1%',
                }}
              >
                {(hoursPMVisit * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    On visit:
                    <span className="font-semibold ml-2">
                      {parseFloat(hoursPMVisit.toFixed(2))}{' '}
                      {(hoursPMVisit * 100) / hoursPMShift > 100
                        ? '(100%)'
                        : (hoursPMVisit * 100) / hoursPMShift > 0
                        ? `(${parseFloat(((hoursPMVisit * 100) / hoursPMShift).toFixed(2))}%)`
                        : '(0%)'}
                    </span>
                  </div>
                )}
              </div>
              {(hoursPMVisit * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  On visit:
                  <span className="font-semibold ml-2">
                    {parseFloat(hoursPMVisit.toFixed(2))}{' '}
                    {(hoursPMVisit * 100) / hoursPMShift > 100
                      ? '(100%)'
                      : (hoursPMVisit * 100) / hoursPMShift > 0
                      ? `(${parseFloat(((hoursPMVisit * 100) / hoursPMShift).toFixed(2))}%)`
                      : '(0%)'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="border border-gray-100 rounded-lg shadow-md p-5 flex flex-col justify-between gap-4">
          <div className="text-display-sm leading-display-sm">Weekday hours</div>
          <div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-green-400 ${(hoursWeekDayShift * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (hoursWeekDayShift * 100) / 300 > 100 ? '100%' : (hoursWeekDayShift * 100) / 300 > 0 ? `${(hoursWeekDayShift * 100) / 300}%` : '1%',
                }}
              >
                {(hoursWeekDayShift * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    On shift:
                    <span className="font-semibold ml-2">{parseFloat(hoursWeekDayShift.toFixed(2))}</span>
                  </div>
                )}
              </div>
              {(hoursWeekDayShift * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  On shift:
                  <span className="font-semibold ml-2">{parseFloat(hoursWeekDayShift.toFixed(2))}</span>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-green-700 ${(hoursWeekDayVisit * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (hoursWeekDayVisit * 100) / 300 > 100 ? '100%' : (hoursWeekDayVisit * 100) / 300 > 0 ? `${(hoursWeekDayVisit * 100) / 300}%` : '1%',
                }}
              >
                {(hoursWeekDayVisit * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    On visit:
                    <span className="font-semibold ml-2">
                      {parseFloat(hoursWeekDayVisit.toFixed(2))}{' '}
                      {(hoursWeekDayVisit * 100) / hoursWeekDayShift > 100
                        ? '(100%)'
                        : (hoursWeekDayVisit * 100) / hoursWeekDayShift > 0
                        ? `(${parseFloat(((hoursWeekDayVisit * 100) / hoursWeekDayShift).toFixed(2))}%)`
                        : '(0%)'}
                    </span>
                  </div>
                )}
              </div>
              {(hoursWeekDayVisit * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  On visit:
                  <span className="font-semibold ml-2">
                    {parseFloat(hoursWeekDayVisit.toFixed(2))}{' '}
                    {(hoursWeekDayVisit * 100) / hoursWeekDayShift > 100
                      ? '(100%)'
                      : (hoursWeekDayVisit * 100) / hoursWeekDayShift > 0
                      ? `(${parseFloat(((hoursWeekDayVisit * 100) / hoursWeekDayShift).toFixed(2))}%)`
                      : '(0%)'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="border border-gray-100 rounded-lg shadow-md p-5 flex flex-col justify-between gap-4">
          <div className="text-display-sm leading-display-sm">Weekend hours</div>
          <div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-green-400 ${(hoursWeekendShift * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (hoursWeekendShift * 100) / 300 > 100 ? '100%' : (hoursWeekendShift * 100) / 300 > 0 ? `${(hoursWeekendShift * 100) / 300}%` : '1%',
                }}
              >
                {(hoursWeekendShift * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    On shift:
                    <span className="font-semibold ml-2">{parseFloat(hoursWeekendShift.toFixed(2))}</span>
                  </div>
                )}
              </div>
              {(hoursWeekendShift * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  On shift:
                  <span className="font-semibold ml-2">{parseFloat(hoursWeekendShift.toFixed(2))}</span>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <div
                className={`flex justify-end items-center bg-green-700 ${(hoursWeekendVisit * 100) / 300 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
                style={{
                  width: (hoursWeekendVisit * 100) / 300 > 100 ? '100%' : (hoursWeekendVisit * 100) / 300 > 0 ? `${(hoursWeekendVisit * 100) / 300}%` : '1%',
                }}
              >
                {(hoursWeekendVisit * 100) / 300 >= 45 && (
                  <div className="text-md leading-md text-gray-800">
                    On visit:
                    <span className="font-semibold ml-2">
                      {parseFloat(hoursWeekendVisit.toFixed(2))}{' '}
                      {(hoursWeekendVisit * 100) / hoursWeekendShift > 100
                        ? '(100%)'
                        : (hoursWeekendVisit * 100) / hoursWeekendShift > 0
                        ? `(${parseFloat(((hoursWeekendVisit * 100) / hoursWeekendShift).toFixed(2))}%)`
                        : '(0%)'}
                    </span>
                  </div>
                )}
              </div>
              {(hoursWeekendVisit * 100) / 300 < 45 && (
                <div className="text-md leading-md text-gray-800">
                  On visit:
                  <span className="font-semibold ml-2">
                    {parseFloat(hoursWeekendVisit.toFixed(2))}{' '}
                    {(hoursWeekendVisit * 100) / hoursWeekendShift > 100
                      ? '(100%)'
                      : (hoursWeekendVisit * 100) / hoursWeekendShift > 0
                      ? `(${parseFloat(((hoursWeekendVisit * 100) / hoursWeekendShift).toFixed(2))}%)`
                      : '(0%)'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="border border-gray-100 rounded-lg shadow-md p-5 flex flex-col justify-between gap-4">
          <div className="text-display-sm leading-display-sm">Average hours per day</div>
          <div className="flex justify-end text-md leading-md text-gray-800 h-[56px] px-6 py-4 mr-4 bg-green-400">
            On shift:
            <span className="font-semibold ml-2">{parseFloat(averageHoursDayShift.toFixed(2))}</span>
          </div>
          <div className="flex items-center">
            <div
              className={`flex justify-end items-center bg-green-700 ${
                (averageHoursDayVisit * 100) / averageHoursDayShift >= 45 ? 'px-6 py-2' : 'p-0'
              } h-[56px] mr-4 `}
              style={{
                width: (averageHoursDayVisit * 100) / averageHoursDayShift > 0 ? `${(averageHoursDayVisit * 100) / averageHoursDayShift}%` : '1%',
              }}
            >
              {(averageHoursDayVisit * 100) / averageHoursDayShift >= 45 && (
                <div className="text-md leading-md text-gray-800">
                  On visit:
                  <span className="font-semibold ml-2">
                    {parseFloat(averageHoursDayVisit.toFixed(2))}{' '}
                    {(averageHoursDayVisit * 100) / averageHoursDayShift > 0
                      ? `(${parseFloat(((averageHoursDayVisit * 100) / averageHoursDayShift).toFixed(2))}%)`
                      : '(0%)'}
                  </span>
                </div>
              )}
            </div>
            {((averageHoursDayVisit * 100) / averageHoursDayShift < 45 || averageHoursDayShift === 0) && (
              <div className="text-md leading-md text-gray-800">
                On visit:
                <span className="font-semibold ml-2">
                  {parseFloat(averageHoursDayVisit.toFixed(2))}{' '}
                  {(averageHoursDayVisit * 100) / averageHoursDayShift > 0
                    ? `(${parseFloat(((averageHoursDayVisit * 100) / averageHoursDayShift).toFixed(2))}%)`
                    : '(0%)'}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="border border-gray-100 rounded-lg shadow-md p-5 flex flex-col justify-between gap-4">
          <div className="text-display-sm leading-display-sm">WSW contracted hours not on shift template</div>
          <div className="flex items-center">
            <div
              className={`flex justify-end items-center bg-[#B38DF7] ${(hoursToMakeUp * 100) / 100 >= 45 ? 'px-6 py-2' : 'p-0'} h-[56px] mr-4 `}
              style={{
                width: (hoursToMakeUp * 100) / 100 > 100 ? '100%' : (hoursToMakeUp * 100) / 100 > 0 ? `${(hoursToMakeUp * 100) / 100}%` : '1%',
              }}
            >
              {(hoursToMakeUp * 100) / 100 >= 45 && <span className="font-semibold ml-2">{parseFloat(hoursToMakeUp.toFixed(2))}</span>}
            </div>
            {(hoursToMakeUp * 100) / 100 < 45 && <span className="font-semibold ml-2">{parseFloat(hoursToMakeUp.toFixed(2))}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RotaStats;
