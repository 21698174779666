import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Tabs, Tab } from '@mui/material';
import { User } from 'types/types';
import { Shift, ShiftRun, ShiftUpdateItem, SupportWorker } from '__generated__/graphql';
import { TabPanel } from 'components/TabPanel';
import MoveVisitToNewShift from './MoveVisitToNewShift';
import MoveVisitToAgreedShift from './MoveVisitToAgreedShift';

interface EditSupportWorkerProps {
  visit: Shift | null;
  toggleSupportWorkerModal: (editSupportWorkerModalState: boolean) => void;
  editSupportWorkerModalState: boolean;
  user: User;
  onMoveVisitToShift: (
    newShiftId: string,
    newRotaId: string,
    newTeamId: string,
    currentVisitId: string,
    currentRotaId: string,
    currentTeamId: string,
    supportWorkerId: string,
    shiftType: string,
    shiftStartDateTime?: number,
  ) => void;
  onSaveVisit: (data: Partial<ShiftUpdateItem>, event: string) => void;
  apiLoading: boolean;
}

export default function EditSupportWorker({
  visit,
  toggleSupportWorkerModal,
  editSupportWorkerModalState,
  user,
  onMoveVisitToShift,
  onSaveVisit,
  apiLoading,
}: EditSupportWorkerProps) {
  const [supportWorker, setSupportWorker] = useState<SupportWorker | null>(null);
  const [currentTab, setTab] = useState<number>(0);
  const [currentShift, setShiftToMove] = useState<ShiftRun | null>(null);

  useEffect(() => {
    if (visit) setSupportWorker(visit?.secondarySupportWorker || null);
  }, [visit]);

  const onChangeSw = (sw: SupportWorker | null) => {
    setSupportWorker(sw);
  };

  const onMoveVisitToAgreedShift = () => {
    if (visit?.id && visit?.rotaId && visit?.teamId && currentShift?.rotaId && currentShift?.teamId) {
      onMoveVisitToShift(currentShift?.id, currentShift?.rotaId, currentShift?.teamId, visit?.id, visit?.rotaId, visit?.teamId, '', '');
    }
  };

  const onMoveVisitToFixedShift = (shiftType: string, shiftStartDateTime: number) => {
    if (visit?.id && visit?.rotaId && visit?.teamId && supportWorker && shiftStartDateTime) {
      const rotaId = supportWorker?.teamId === visit?.teamId ? visit.rotaId : '';
      onMoveVisitToShift('', rotaId, supportWorker?.teamId, visit?.id, visit?.rotaId, visit?.teamId, supportWorker?.id, shiftType, shiftStartDateTime);
    }
  };

  const onMoveVisitToNewFlexiShift = (shiftType: string) => {
    if (visit?.id && visit?.rotaId && visit?.teamId && supportWorker) {
      const rotaId = supportWorker?.teamId === visit?.teamId ? visit.rotaId : '';
      onMoveVisitToShift('', rotaId, supportWorker?.teamId, visit?.id, visit?.rotaId, visit?.teamId, supportWorker?.id, shiftType);
    }
  };

  const onSetVisitToTbc = () => {
    if (visit?.shiftRun?.ownerId) {
      onSaveVisit({ secondarySupportWorkerId: null }, 'RemoveSupporWorker');
    }
  };

  const onSetVisitToOwner = () => {
    if (visit?.shiftRun?.ownerId) {
      onSaveVisit({ secondarySupportWorkerId: visit?.shiftRun?.ownerId }, 'AddSupporWorker');
    }
  };

  const onClose = () => {
    toggleSupportWorkerModal(false);
    setShiftToMove(null);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Dialog fullWidth data-cy="assign-sw-dialog" maxWidth="md" open={editSupportWorkerModalState} onClose={onClose}>
      <DialogContent>
        <Tabs value={currentTab} onChange={handleTabChange} sx={{ ml: '-1em' }}>
          <Tab label="Existing Agreed Shifts" sx={{ fontSize: { xs: '0.7em', md: '1em' } }} />
          <Tab label="New Fixed/Banked Hours Shift" sx={{ fontSize: { xs: '0.7em', md: '1em' } }} />
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          <MoveVisitToAgreedShift
            title="Move visit to an existing agreed shift"
            user={user}
            setSelectedShift={setShiftToMove}
            visit={visit}
            selectedShift={currentShift}
            onClose={onClose}
            onMoveVisitToAgreedShift={onMoveVisitToAgreedShift}
            onSetVisitToTbc={onSetVisitToTbc}
            onSetVisitToOwner={onSetVisitToOwner}
            apiLoading={apiLoading}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <MoveVisitToNewShift
            title="Create a new banked hours or fixed shift"
            showTeam={true}
            onChangeSw={onChangeSw}
            user={user}
            selectedShift={visit}
            onClose={onClose}
            onMoveVisitToFixedShift={onMoveVisitToFixedShift}
            onMoveVisitToNewFlexiShift={onMoveVisitToNewFlexiShift}
            apiLoading={apiLoading}
          />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
}
