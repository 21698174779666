import { Invoice, InvoiceItem, InvoiceItemThriveBooking } from '__generated__/graphql';

export const MapInvoiceItem = (item: InvoiceItem | null) => {
  return {
    endDateTime: item?.endDateTime,
    id: item?.id,
    included: item?.included,
    startDateTime: item?.startDateTime,
    status: item?.status,
    type: item?.type,
    whoCancelled: item?.whoCancelled,
    cancelledDateTime: item?.cancelledDateTime,
    travelWithCustomerMileage: item?.travelWithCustomerMileage,
    travelWithCustomerDestination: item?.travelWithCustomerDestination,
  };
};

export const MapThriveBookingItem = (item: InvoiceItemThriveBooking | null) => {
  return {
    id: item?.id,
    included: item?.included,
    startDateTime: item?.startDateTime,
    endDateTime: item?.endDateTime,
    customerName: item?.customerName,
    partnerName: item?.partnerName,
    price: item?.price,
    product: item?.product,
  };
};

export const MapInvoice = (inv: Invoice | undefined) => {
  return {
    teamId: inv?.teamId,
    name: inv?.name,
    customerId: inv?.customerId,
    dueDate: inv?.dueDate,
    invoiceDate: inv?.invoiceDate,
    payrollStartDate: inv?.payrollStartDate,
    payrollEndDate: inv?.payrollEndDate,
    invoiceNumber: inv?.invoiceNumber,
    careItems: inv?.careItems?.map((i) => MapInvoiceItem(i)),
    mileageItems: inv?.mileageItems?.map((i) => MapInvoiceItem(i)),
    thriveBookingItems: inv?.thriveBookingItems?.map((i) => MapThriveBookingItem(i)),
    packageId: inv?.packageId,
    packagePriceId: inv?.packagePriceId,
  };
};
