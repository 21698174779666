import { RouteObject } from 'react-router-dom';
import AgreedShiftsContainer from 'pages/AgreedShifts';
import ConfirmationPractices from 'pages/ConfirmationPractices';
import ManageConfirmationPractices from 'pages/ConfirmationPractices/Manage';
import Faq from 'pages/Faq';
import Guides from 'pages/Guides';
import LeadershipTeam from 'pages/LeadershipTeam';
import MagicNumber from 'pages/MagicNumber';
import SupportedPeopleList from 'pages/SupportedPeople/SupportedPeopleList';
import Maps from 'pages/Maps';
import MyShifts from 'pages/MyShifts';
import MyVisits from 'pages/MyVisits';
import ReportingHours from 'pages/ReportingHours';
import ManageReportingHours from 'pages/ReportingHours/ManageReportingHours';
import SubmitReportingHours from 'pages/ReportingHours/SubmitReportingHours';
import ReportingHoursList from 'pages/ReportingHours/ReportingHoursList';
import ReviewStats from 'pages/ReviewStats';
import RotaList from 'pages/SelectRota';
import EditProfile from 'pages/SupportedPeople/EditProfile';
import Profile from 'pages/SupportedPeople/Profile';
import TeamMeetings from 'pages/TeamMeetings/TeamMeetingList';
import TeamMeetingCreate from 'pages/TeamMeetings/TeamMeetingCreate';
import TeamMemberDetail from 'pages/TeamMembers/TeamMemberDetail';
import TeamMemberUpdate from 'pages/TeamMembers/TeamMemberUpdate';
import TeamSetup from 'pages/TeamSetup';
import TeamShifts from 'pages/TeamShifts';
import WellbeingBoard from 'pages/WellbeingBoard';
import WellbeingBoardCustomer from 'pages/WellbeingBoard/WellbeingBoardCustomer';
import Dashboard from 'pages/Dashboard';
import Room from 'pages/Chat';
import Policies from 'pages/Policies';
import TeamMembersList from 'pages/TeamMembers/TeamMembersList';
import FormList from 'pages/Forms/FormList';
import MedicalObvsDetail from 'pages/Forms/MedicalObvs/MedicalObvsDetail';
import AscotReviewCreate from 'pages/WellbeingBoard/AscotReview/AscotReviewCreate';
import AscotReviewUpdate from 'pages/WellbeingBoard/AscotReview/AscotReviewUpdate';
import AscotExpectedOutcomeUpdate from 'pages/WellbeingBoard/AscotExpectedOutcome/AscotExpectedOutcomeUpdate';
import TeamMeetingUpdate from 'pages/TeamMeetings/TeamMeetingUpdate';
import AscotExpectedOutcomeCreate from 'pages/WellbeingBoard/AscotExpectedOutcome/AscotExpectedOutcomeCreate';
import IncidentDetail from 'pages/Forms/Incident/IncidentDetail';
import ReportingHoursStatus from 'pages/ReportingHours/ReportingHoursStatus';

export const publicRoutes: RouteObject[] = [
  { path: '/', element: <Dashboard /> },
  { path: '/chat', element: <Room /> },
  {
    path: '/confirmation-practices',
    children: [
      { index: true, element: <ConfirmationPractices /> },
      { path: 'manage/:groupId', element: <ManageConfirmationPractices /> },
    ],
  },
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/faqs', element: <Faq /> },
  { path: '/guides', element: <Guides /> },
  { path: '/magic-number', element: <MagicNumber /> },
  { path: '/maps', element: <Maps /> },
  { path: '/my-shifts', element: <MyShifts /> },
  { path: '/my-visits', element: <MyVisits /> },
  { path: '/organisation-map', element: <LeadershipTeam /> },
  { path: '/policies', element: <Policies /> },
  {
    path: '/reporting-hours',
    children: [
      { index: true, element: <ReportingHours /> },
      { path: 'list', element: <ReportingHoursList /> },
      { path: 'manage/:teamId/:swId/:id', element: <ManageReportingHours /> },
      { path: 'submit/:teamId/:swId/:id', element: <SubmitReportingHours /> },
    ],
  },
  {
    path: '/reporting-hours-status',
    children: [{ index: true, element: <ReportingHoursStatus /> }],
  },
  { path: '/review-stats', element: <ReviewStats /> },

  { path: '/rota', element: <RotaList /> },
  { path: '/rota-templates', element: <AgreedShiftsContainer /> },

  {
    path: '/team-meetings',
    children: [
      { index: true, element: <TeamMeetings /> },
      { path: ':teamMeetingId/update', element: <TeamMeetingUpdate /> },
      { path: 'new', element: <TeamMeetingCreate /> },
    ],
  },
  {
    path: '/team-members',
    children: [
      { index: true, element: <TeamMembersList /> },
      { path: ':teamMemberId', element: <TeamMemberDetail /> },
      { path: ':teamMemberId/update', element: <TeamMemberUpdate /> },
    ],
  },
  { path: '/team-setup', element: <TeamSetup /> },

  { path: '/team-shifts', element: <TeamShifts /> },
  {
    path: '/supported-people',
    children: [
      { index: true, element: <SupportedPeopleList /> },
      { path: ':supportedPersonId', element: <Profile /> },
      { path: ':supportedPersonId/update', element: <EditProfile /> },
    ],
  },
  {
    path: '/wellbeing-board',
    children: [
      { index: true, element: <WellbeingBoard /> },
      { path: ':customerId', element: <WellbeingBoardCustomer /> },
      { path: ':customerId/review/:reviewId/ascot/create', element: <AscotReviewCreate /> },
      { path: ':customerId/ascot/:ascotReviewId', element: <AscotReviewUpdate /> },
      { path: ':customerId/ascot/:ascotReviewId/expected-outcome/create', element: <AscotExpectedOutcomeCreate /> },
      { path: ':customerId/ascot/:ascotReviewId/expected-outcome/:ascotExpectedOutcomeId/update', element: <AscotExpectedOutcomeUpdate /> },
    ],
  },
  {
    path: '/forms',
    children: [
      { index: true, element: <FormList /> },
      { path: 'medical-obvs/:formId', element: <MedicalObvsDetail /> },
      { path: 'medical-obvs/create', element: <MedicalObvsDetail /> },
      { path: 'incident/:formId', element: <IncidentDetail /> },
      { path: 'incident/create', element: <IncidentDetail /> },
    ],
  },
];
