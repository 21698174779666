import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getTime } from 'date-fns';
import ReactSelect from 'components/Select';
import { Input } from 'components';
import DatePicker from 'components/DatePicker';
// import { camelCaseToNormalString, toTitleCase } from 'services/strings';
import { Maybe, CustomerPackage, CustomerPackagePrice } from '__generated__/graphql';
import { SelectOption } from 'types/types';
import ReactMultiSelect from 'components/MultiSelect';
import TextArea from 'components/TextArea';
import { fundingSources, priceTypes } from './Mapper';

interface PackagePriceCreateProps {
  customerPackage: Maybe<CustomerPackage> | undefined;
  onClose: () => void;
  onCreate: (data: CustomerPackagePrice) => void;
}

export default function PackagePriceCreate({ onClose, onCreate, customerPackage }: PackagePriceCreateProps) {
  const [validation, setValidation] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date(customerPackage?.startDate || Date.now()));
  const [monthlyValue, setMonthlyValue] = useState<string>('0');
  const [numberOfHours, setNumberOfHours] = useState<string>('0');
  const [note, setNote] = useState<string>('');
  const [priceType, setPriceType] = useState<SelectOption | undefined>();
  const [fundingSource, setFundingSource] = useState<SelectOption[]>([]);

  const onSavePackagePrice = () => {
    setValidation(null);

    if (!priceType) {
      setValidation('Please add a price type');
      return;
    }
    if (!fundingSource || fundingSource?.length === 0) {
      setValidation('Please add a funding source');
      return;
    }
    if (!monthlyValue || parseFloat(monthlyValue) <= 0) {
      setValidation('Please add a monthly value');
      return;
    }
    if (!numberOfHours || parseFloat(numberOfHours) <= 0) {
      setValidation('Please add a number of hours per week');
      return;
    }

    if (startDate && priceType && fundingSource && numberOfHours) {
      onCreate({
        id: uuidv4(),
        startDate: getTime(startDate),
        endDate: null,
        monthlyValue: parseFloat(monthlyValue),
        hourlyValue: parseFloat((parseFloat(monthlyValue) / (parseFloat(numberOfHours) * 4.34)).toFixed(2)),
        numberOfHours: parseFloat(numberOfHours),
        note,
        type: priceType.value,
        fundingSource: fundingSource?.map((fs) => fs.value),
      });
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-5xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[70vh] overflow-y-auto">
            <div className="text-gray-900 text-lg leading-lg font-semibold mb-5">Create Package Price</div>
            {validation && <div className="text-sm leading-lg mb-5 text-red-800">{validation}</div>}
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Price Type</div>
              <div className="mt-2">
                <ReactSelect testId="package-price-types" options={priceTypes} onChange={(e) => setPriceType(e)} selectedValue={priceType} searchable />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Funding Source</div>
              <div className="mt-2">
                <ReactMultiSelect
                  testId="package-price-funding"
                  options={fundingSources}
                  onChange={setFundingSource}
                  selectedValue={fundingSource}
                  searchable
                />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Start Date</div>
              <div className="mt-2">
                <DatePicker testId="package-price-start-date" value={startDate ?? undefined} onChange={setStartDate} />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Monthly Value</div>
              <div className="mt-2">
                <Input value={monthlyValue} onChange={setMonthlyValue} testId="package-price-monthly-value" />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Number of Hours per Week</div>
              <div className="mt-2">
                <Input value={numberOfHours} onChange={setNumberOfHours} testId="package-number-visits" />
              </div>
            </div>
            <div className="mb-5">
              <div className="text-sm font-medium text-gray-700 capitalize w-auto">Note</div>
              <div className="mt-2">
                <TextArea value={note} onChange={setNote} testId="package-price-note" rows={4} />
              </div>
            </div>
            <div className="flex justify-between items-center mt-5">
              <button type="button" className="text-gray-500 font-semibold text-md leading-md" onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                data-cy="save-package-button"
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                onClick={onSavePackagePrice}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-gray-900" />
    </>
  );
}
