/* eslint-disable prettier/prettier */
import { EmojiPeople } from '@mui/icons-material';
import { useState } from 'react';
import { Message, LocationBar } from 'components';
import Helmet from 'react-helmet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useCreateAscotReview } from 'api/hooks/useAscotReviews';
import { AscotReviewInput } from '__generated__/graphql';
import AscotReviewForm from './AscotReviewForm';

const AscotReviewCreate = () => {
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { customerId } = useParams<{ customerId?: string }>();
  const { createAscotReview, mutationCreateAscotReview } = useCreateAscotReview({ customerId });
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get('teamId') || '';

  const navigate = useNavigate();

  const onSubmit = async (data: AscotReviewInput) => {
    await createAscotReview({
      awaitRefetchQueries: true,

      variables: {
        input: {
          ...data,
        },
      },
    });
    setOpenSnack(true);
  };

  return (
    <>
      <Helmet>
        <title>Ascot</title>
      </Helmet>
      <LocationBar section="Ascot" page="Create Ascot Review" Icon={EmojiPeople} />
      <Message response={[mutationCreateAscotReview]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => navigate(`/wellbeing-board/${customerId}?teamId=${teamId}`)}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>

        <AscotReviewForm onSubmit={onSubmit} />
      </div>
    </>
  );
};

export default AscotReviewCreate;
