import { useQuery, useMutation, gql, useLazyQuery } from '@apollo/client';
import { Customer, CustomerSearch, HubSpotCustomer, HubSpotSearch } from '../../__generated__/graphql';

export const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccountForCustomer($input: ManageCustomerAccountInput) {
    ActivateAccountForCustomer(input: $input) {
      id
      message
      date
    }
  }
`;

export const TERMINATE_ACCOUNT = gql`
  mutation TerminateAccountForCustomer($input: ManageCustomerAccountInput) {
    TerminateAccountForCustomer(input: $input) {
      id
      message
      date
    }
  }
`;

export const DISABLE_FAMILY_MEMBER = gql`
  mutation DisableMemberFromChatRoom($input: DisableMemberChatRoomInput) {
    DisableMemberFromChatRoom(input: $input) {
      message
      date
    }
  }
`;

export const ACTIVATE_FAMILY_MEMBER = gql`
  mutation ActivateMemberChatRoom($input: ActivateMemberChatRoomInput) {
    ActivateMemberChatRoom(input: $input) {
      message
      date
    }
  }
`;

export const CREATE_BIRDIE_ACCOUNT = gql`
  mutation CreateBirdieAccountForCustomer($input: ManageCustomerAccountInput) {
    CreateBirdieAccountForCustomer(input: $input) {
      id
      message
      date
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomer($query: CustomerByIdSearch) {
    GetCustomer(query: $query) {
      id
      externalId
      firstName
      lastName
      nhsNumber
      address
      postcode
      phone
      lat
      lng
      teamId
      hubspotDealId
      active
      city
      mobile
      birdieId
      image {
        url
        id
      }
      linkWorkers {
        id
        fullName
        avatar
      }
      wellbeingStatus
      teamName
      notes {
        id
        date
        note
      }
      reviews {
        id
        date
        requestedBy
        note
      }
      profileData {
        aboutMe
        matters
        support
      }
      circleId
      circleName
      firstVisitDate
      familyMembers {
        id
        name
        email
        isActive
        invoice
      }
      dateCreated
      dateUpdated
      documents {
        id
        url
        ownerId
        ownerType
        title
        type
        dateCreated
      }
      teamPeriod {
        teamLeftId
        teamJoinedId
        date
      }
    }
  }
`;

export const GET_CUSTOMERS_MARKER = gql`
  query GetCustomers($query: CustomerSearch) {
    GetCustomers(query: $query) {
      id
      firstName
      lastName
      postcode
      lat
      lng
    }
  }
`;
export const SAVE_CUSTOMER_PROFILE = gql`
  mutation SaveCustomerProfile($input: CustomerProfileInput) {
    SaveCustomerProfile(input: $input) {
      id
      message
      date
    }
  }
`;

export const GET_CUSTOMERS_REVIEWS = gql`
  query GetCustomers($query: CustomerSearch) {
    GetCustomers(query: $query) {
      id
      teamId
      circleName
      teamName
      lastName
      firstName
      firstVisitDate
      reviews {
        id
        date
        requestedBy
        note
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query GetCustomers($query: CustomerSearch) {
    GetCustomers(query: $query) {
      externalId
      firstName
      lastName
      nhsNumber
      address
      city
      phone
      postcode
      profileData {
        aboutMe
        matters
        support
      }
      teamName
      id

      image {
        url
        id
      }
      familyMembers {
        id
        name
        email
        isActive
        invoice
      }

      lat
      lng
      birdieId
      active
      dateUpdated
      dateCreated
      linkWorkers {
        id
        fullName
        avatar
      }
      wellbeingStatus
      notes {
        id
        date
        note
      }
      teamId
      active
      reviews {
        id
        date
        requestedBy
        note
      }
      circleId
      circleName
      firstVisitDate
      teamPeriod {
        teamLeftId
        teamJoinedId
        date
      }
    }
  }
`;

export const SAVE_CUSTOMER_LINK_WORKERS = gql`
  mutation SaveCustomerLinkWorkers($input: CustomerUpdate) {
    SaveCustomerLinkWorkers(input: $input) {
      message
      date
    }
  }
`;

export const SAVE_CUSTOMER_REVIEWS = gql`
  mutation SaveCustomerReviews($input: CustomerReviewsInput) {
    SaveCustomerReviews(input: $input) {
      message
      date
    }
  }
`;

export const EDIT_CUSTOMER = gql`
  mutation EditCustomer($input: CustomerEditInput) {
    EditCustomer(input: $input) {
      message
      date
    }
  }
`;

export const EDIT_CUSTOMER_NOTE = gql`
  mutation EditCustomerNote($input: CustomerNoteInput) {
    EditCustomerNote(input: $input) {
      message
      date
    }
  }
`;

export const SAVE_CUSTOMER_NOTE = gql`
  mutation SaveCustomerNote($input: CustomerNoteInput) {
    SaveCustomerNote(input: $input) {
      message
      date
    }
  }
`;

export const CHANGE_CUSTOMER_TEAM = gql`
  mutation TeamChangeForCustomer($input: ManageCustomerAccountInput) {
    TeamChangeForCustomer(input: $input) {
      id
      message
      date
    }
  }
`;

export const GET_HUBSPOT_SUPPORTED_PEOPLE = gql`
  query GetSupportedPeopleFromHubspot($query: HubSpotSearch) {
    GetSupportedPeopleFromHubspot(query: $query) {
      address
      city
      createDateTime
      email
      id
      firstname
      lastname
      mobilephone
      relationshipToClient
      phone
      zip
      teamhub {
        id
        teamId
      }
      deal {
        id
        signedTerms
        createdDateTime
        dealstage
        team
      }
    }
  }
`;

export const GET_HUBSPOT_SUPPORTED_PERSON = gql`
  query GetSupportedPersonFromHubspot($query: HubSpotSearch) {
    GetSupportedPersonFromHubspot(query: $query) {
      address
      city
      createDateTime
      email
      id
      firstname
      lastname
      mobilephone
      relationshipToClient
      phone
      zip
      teamhub {
        id
        teamId
      }
      deal {
        id
        signedTerms
        createdDateTime
        dealstage
        team
      }
    }
  }
`;

export const SAVE_SUPPORTED_PERSON = gql`
  mutation CreateSupportedPerson($input: CreateCustomerInput) {
    CreateSupportedPerson(input: $input) {
      id
      message
      date
    }
  }
`;

export const CREATE_FAMILY_ACCOUNTS = gql`
  mutation CreateFamilyAccounts($input: CreateFamilyInput) {
    CreateFamilyAccounts(input: $input) {
      id
      message
      date
    }
  }
`;

interface GetCustomersResponse {
  GetCustomers: Customer[];
}

interface GetCustomerResponse {
  GetCustomer: Customer;
}

interface GetSupportedPeopleFromHubspotResponse {
  GetSupportedPeopleFromHubspot: HubSpotCustomer[];
}

interface GetSupportedPersonFromHubspotResponse {
  GetSupportedPersonFromHubspot: HubSpotCustomer;
}

const refetchCustomers = (input: CustomerSearch) => ({
  refetchQueries: [
    {
      query: GET_CUSTOMERS,
      variables: {
        query: { ...input },
      },
    },
  ],
});

export const refetchCustomer = (input: CustomerSearch) => ({
  refetchQueries: [
    {
      query: GET_CUSTOMER,
      variables: {
        query: {
          ...input,
        },
      },
    },
  ],
});

export const useChangeTeam = (query: CustomerSearch) => {
  const [changeTeam, mutationChangeTeam] = useMutation(CHANGE_CUSTOMER_TEAM, {
    refetchQueries: [
      {
        query: GET_CUSTOMERS,
        variables: {
          query: { ...query },
        },
      },
    ],
  });

  return { changeTeam, mutationChangeTeam };
};

export const useGetCustomers = (query: CustomerSearch) => {
  const { data, loading, error, refetch } = useQuery<GetCustomersResponse>(GET_CUSTOMERS, {
    variables: {
      query: { ...query },
    },
  });

  return { customers: data?.GetCustomers || [], loading, error, refetch };
};

export const useGetCustomer = (query: CustomerSearch) => {
  const { loading, data, error } = useQuery<GetCustomerResponse>(GET_CUSTOMER, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { customer: data?.GetCustomer, loading, error };
};

export const useGetCustomersReviews = (query: CustomerSearch) => {
  const { data, loading, error } = useQuery<GetCustomersResponse>(GET_CUSTOMERS_REVIEWS, {
    variables: {
      query: { ...query },
    },
  });

  return { customers: data?.GetCustomers || [], loading, error };
};

export const useGetCustomersMarker = (query: CustomerSearch) => {
  const { loading, data, error } = useQuery<GetCustomersResponse>(GET_CUSTOMERS_MARKER, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { customers: data?.GetCustomers || [], loading, error };
};

export const useCreateFamilyAccounts = (query: CustomerSearch) => {
  const [createFamilyAccounts, mutationCreateFamilyAccounts] = useMutation(CREATE_FAMILY_ACCOUNTS, refetchCustomers(query));

  return { createFamilyAccounts, mutationCreateFamilyAccounts };
};

export const useActivateAccount = (query: CustomerSearch) => {
  const [activateAccount, mutationActivateAccount] = useMutation(ACTIVATE_ACCOUNT, refetchCustomers(query));

  return { activateAccount, mutationActivateAccount };
};

export const useTerminateAccount = (query: CustomerSearch) => {
  const [terminateAccount, mutationTerminateAccount] = useMutation(TERMINATE_ACCOUNT, refetchCustomers(query));

  return { terminateAccount, mutationTerminateAccount };
};

export const useCreateBirdieAccount = (query: CustomerSearch) => {
  const [createBirdieAccount, mutationCreateBirdieAccount] = useMutation(CREATE_BIRDIE_ACCOUNT, refetchCustomers(query));

  return { createBirdieAccount, mutationCreateBirdieAccount };
};

export const useEditCustomer = (query: CustomerSearch) => {
  const [editCustomer, mutationEditCustomer] = useMutation(EDIT_CUSTOMER, refetchCustomer(query));
  return { editCustomer, mutationEditCustomer };
};

export const useEditSupportedPerson = (query: CustomerSearch) => {
  const [editCustomer, mutationEditCustomer] = useMutation(EDIT_CUSTOMER, refetchCustomers(query));
  return { editCustomer, mutationEditCustomer };
};

export const useEditCustomerNote = (query: CustomerSearch) => {
  const [editCustomerNote, mutationEditCustomerNote] = useMutation(EDIT_CUSTOMER_NOTE, refetchCustomer(query));
  return { editCustomerNote, mutationEditCustomerNote };
};

export const useSaveCustomerNote = (query: CustomerSearch) => {
  const [saveCustomerNote, mutationSaveCustomerNote] = useMutation(SAVE_CUSTOMER_NOTE, refetchCustomer(query));
  return { saveCustomerNote, mutationSaveCustomerNote };
};

export const useSaveCustomerReviews = (query: CustomerSearch) => {
  const [saveCustomerReviews, mutationSaveCustomerReviews] = useMutation(SAVE_CUSTOMER_REVIEWS, refetchCustomer(query));
  return { saveCustomerReviews, mutationSaveCustomerReviews };
};

export const useUpdateCustomerLinkWorker = (query: CustomerSearch) => {
  const [updateCustomerLinkWorker, mutationCustomerLinkWorker] = useMutation(SAVE_CUSTOMER_LINK_WORKERS, refetchCustomer(query));
  return { updateCustomerLinkWorker, mutationCustomerLinkWorker };
};

export const useDisableFamily = (query: CustomerSearch) => {
  const [disableFamily, mutationDisableFamily] = useMutation(DISABLE_FAMILY_MEMBER, refetchCustomer(query));
  return { disableFamily, mutationDisableFamily };
};

export const useActivateFamily = (query: CustomerSearch) => {
  const [activateFamily, mutationActivateFamily] = useMutation(ACTIVATE_FAMILY_MEMBER, refetchCustomer(query));
  return { activateFamily, mutationActivateFamily };
};

export const useSaveCustomerProfile = () => {
  const [saveCustomerProfile, mutationSaveCustomerProfile] = useMutation(SAVE_CUSTOMER_PROFILE);

  return { saveCustomerProfile, mutationSaveCustomerProfile };
};

export const useGetHubspotSupportedPerson = (query: HubSpotSearch) => {
  const { loading, data, error } = useQuery<GetSupportedPersonFromHubspotResponse>(GET_HUBSPOT_SUPPORTED_PERSON, {
    variables: {
      query: {
        ...query,
      },
    },
  });

  return { customer: data?.GetSupportedPersonFromHubspot || null, loading, error };
};

export const useGetHubspotSupportedPeople = () => {
  const [getHubspotSupportedPeople, { loading, error, data }] = useLazyQuery<GetSupportedPeopleFromHubspotResponse>(GET_HUBSPOT_SUPPORTED_PEOPLE);
  return { getHubspotSupportedPeople, loading, error, customers: data?.GetSupportedPeopleFromHubspot || [] };
};

export const useCreateSupportedPerson = () => {
  const [createSupportedPerson, mutationCreateSupoprtedPerson] = useMutation(SAVE_SUPPORTED_PERSON);
  return { createSupportedPerson, mutationCreateSupoprtedPerson };
};
