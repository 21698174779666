import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Rota, Shift as ShiftType, Maybe, ShiftRun } from '__generated__/graphql';
import { FocusOn } from 'react-focus-on';
import { Loading } from 'components';
import ActivityItem from './ActivityItem';
import ActivityItemNoShift from './ActivityItemNoShift';

interface ManageActivityProps {
  toggleCreateActivityModal: (value: boolean) => void;
  createActivityModalState: boolean;
  myShiftRuns: (Maybe<ShiftRun> | undefined)[];
  myActivities: ShiftType[];
  onSaveActivity: (activity: Partial<ShiftType>) => Promise<void>;
  onUpdateActivity: (activity: Partial<ShiftType>) => void;
  onDeleteActivity: (activity: Partial<ShiftType>) => void;
  rota: Rota;
  apiLoading: boolean;
}

type ActivityType = Partial<ShiftType> & { newEntry?: boolean };

export default function ManageActivity({
  toggleCreateActivityModal,
  createActivityModalState,
  myShiftRuns,
  myActivities,
  onSaveActivity,
  onUpdateActivity,
  onDeleteActivity,
  rota,
  apiLoading,
}: ManageActivityProps) {
  const [activities, setActivities] = useState<ActivityType[]>([]);

  useEffect(() => {
    if (myShiftRuns?.length > 0) {
      setActivities([
        ...myActivities,
        {
          id: uuidv4(),
          shiftId: myShiftRuns[0]?.id,
          startDateTime: myShiftRuns[0]?.startDateTime,
          endDateTime: myShiftRuns[0]?.endDateTime,
          activityType: 'InitialConversation',
          notes: '',
          newEntry: true,
        },
      ]);
    }
  }, [myActivities, myShiftRuns]);

  const removeActivity = (activity: Partial<ActivityType>) => {
    if (!activity.newEntry) {
      onDeleteActivity(activity);
    } else {
      setActivities(activities.filter((a) => a.id !== activity.id));
    }
  };

  const updateActivity = (activity: Partial<ShiftType>) => {
    onUpdateActivity(activity);
  };

  const saveActivity = (activity: Partial<ShiftType>) => {
    onSaveActivity(activity);
  };

  const saveActivityNoShift = (activity: Partial<ShiftType>) => {
    onSaveActivity(activity);
  };

  return (
    <div>
      {createActivityModalState && (
        <FocusOn>
          <div
            className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-[1200] outline-none focus:outline-none"
            onClick={() => toggleCreateActivityModal(false)}
          >
            {apiLoading ? (
              <Loading isComponent />
            ) : (
              <div className="relative w-auto mx-auto max-w-5xl">
                <div
                  className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6 max-h-screen md:max-h-[90vh] overflow-y-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {myShiftRuns?.length > 0 && (
                    <>
                      <div className="text-gray-900 text-display-xs leading-display-xs font-semibold mb-5">Add banked hours activity</div>
                      {activities.map((activity, i) => (
                        <ActivityItem
                          key={`activity${i}`}
                          activity={activity}
                          myShiftRuns={myShiftRuns}
                          updateActivity={updateActivity}
                          removeActivity={removeActivity}
                          saveActivity={saveActivity}
                        />
                      ))}
                    </>
                  )}
                  <ActivityItemNoShift rota={rota} saveActivityNoShift={saveActivityNoShift} />

                  <div className="flex justify-end mt-5">
                    <button
                      type="button"
                      className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
                      onClick={() => toggleCreateActivityModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="opacity-80 fixed inset-0 z-[1100] bg-gray-900" />
        </FocusOn>
      )}
    </div>
  );
}
