/* eslint-disable prettier/prettier */
import { EmojiPeople } from '@mui/icons-material';
import { useState } from 'react';
import { Message, LocationBar, Loading } from 'components';
import Helmet from 'react-helmet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useGetAscotReview } from 'api/hooks/useAscotReviews';
import { useCreateAscotExpectedOutcome } from 'api/hooks/useAscotExpectedOutcomes';
import AscotExpectedOutcomeForm, { AscotExpectedOutcomeFormInput } from './AscotExpectedOutcomeForm';

const AscotExpectedOutcomeCreate = () => {
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const { ascotReviewId } = useParams<{ ascotReviewId?: string }>();
  const { ascotReview, loading } = useGetAscotReview({ id: ascotReviewId });
  const { createAscotExpectedOutcome, mutationCreateAscotExpectedOutcome } = useCreateAscotExpectedOutcome({ customerId: ascotReview?.supportedPersonId });
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get('teamId') || '';

  const navigate = useNavigate();

  const onSubmit = async (data: AscotExpectedOutcomeFormInput) => {
    const input = data;
    delete input.foodAndDrink.current;
    delete input.personalSafety.current;
    delete input.occupation.current;
    delete input.personalCleanlinessAndComfort.current;
    delete input.socialParticipationAndInvolvement.current;
    delete input.controlOverDailyLife.current;
    delete input.accomodationAndComfort.current;

    await createAscotExpectedOutcome({
      awaitRefetchQueries: true,

      variables: {
        input: {
          ...input,
        },
      },
    });
    setOpenSnack(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>Ascot</title>
      </Helmet>
      <LocationBar section="Ascot" page="Create Ascot Expected Outcome" Icon={EmojiPeople} />
      <Message response={[mutationCreateAscotExpectedOutcome]} openSnack={openSnack} setOpenSnack={setOpenSnack} />
      <div className="my-10 px-4 md:px-[5%]">
        <button
          type="button"
          className="text-gray-500 font-semibold text-md leading-md flex items-center"
          onClick={() => navigate(`/wellbeing-board/${ascotReview?.supportedPersonId}?teamId=${teamId}`)}
        >
          <ArrowLeftIcon className="mr-2 w-5 h-5" /> Back
        </button>

        <AscotExpectedOutcomeForm ascotReview={ascotReview} onSubmit={onSubmit} />
      </div>
    </>
  );
};

export default AscotExpectedOutcomeCreate;
