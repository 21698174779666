import { useState } from 'react';
import { getWellbeingStatusColor } from 'services/helpers';
import Loading from 'components/Loading';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { ArrowBack, Edit, SelfImprovement } from '@mui/icons-material';
import { CustomerUpdate } from '__generated__/graphql';
import { useEditCustomer, useGetCustomer, useUpdateCustomerLinkWorker } from 'api/hooks/useCustomers';
import { useRemoveDocumentForSupportedPerson } from 'api/hooks/useDocuments';
import { useUser } from 'context/userContext';
import Avatar from 'components/Avatar';
import { ImageSizeType } from 'types/types';
import { LocationBar } from 'components';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import AssignLinkWorker from 'pages/TeamSetup/AssignLinkWorker';
import TabSwitcher from 'components/TabSwitcher';
import EditWellbeingStatus from './EditWellbeingStatus';
import WellbeingBoardNotes from './WellbeingBoardNotes';
import WellbeingBoardReviews from './WellbeingBoardReviews';
import WellbeingBoardAbout from './WellbeingBoardAbout';
import WellbeinBoardScores from './WellbeingBoardScores';
import { WellbeingReviewText } from './WellbeingReviewText';
import { useCustomerAscotData } from './useCustomerAscotData';
import WellbeingBoardPersonal from './WellbeingBoardPersonal';
import DocumentManagement from '../../../components/Documents';

const WellbeingBoardCustomer = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [searchParams] = useSearchParams();
  const { customerId } = useParams();
  const teamId = searchParams.get('teamId') || user?.teamId || '';
  const defaultTab = searchParams.get('tab');
  const [wellBeingStatusModalState, toggleWellbeingStatusModal] = useState(false);
  const [assignLinkWorkerModalState, toggleLinkWorkerModal] = useState<boolean>(false);
  const { removeDocument, mutationRemoveDocument } = useRemoveDocumentForSupportedPerson({ teamId, id: customerId });

  const { loading: customerLoading, customer } = useGetCustomer({
    teamId,
    id: customerId,
  });

  const { customerReviews, lastAscotReview, lastAscotExpectedOutcome, loading } = useCustomerAscotData(customer);
  const { editCustomer, mutationEditCustomer } = useEditCustomer({
    teamId,
    id: customerId,
  });

  const { updateCustomerLinkWorker, mutationCustomerLinkWorker } = useUpdateCustomerLinkWorker({
    teamId,
    id: customerId,
  });

  const onChangeStatus = async (id: string, status: string) => {
    await editCustomer({
      variables: {
        input: {
          id,
          wellbeingStatus: status,
          teamId,
        },
      },
    });
  };

  const onSaveLinkWorkers = async (data: Partial<CustomerUpdate>) => {
    await updateCustomerLinkWorker({
      variables: {
        input: { ...data, teamId },
      },
    });
  };

  if (customerLoading || mutationEditCustomer.loading || mutationCustomerLinkWorker.loading || loading || mutationRemoveDocument.loading) {
    return <Loading />;
  }

  if (customer === null) {
    return null;
  }

  const onEditStatus = async (status: string) => {
    onChangeStatus(customer?.id || '', status);
  };

  const onEditLinkWorkers = (data: Partial<CustomerUpdate>) => {
    onSaveLinkWorkers(data);
    toggleLinkWorkerModal(false);
  };

  const onDeleteDocument = async (id: string, ownerId: string) => {
    await removeDocument({
      variables: {
        input: {
          id,
          ownerId,
        },
      },
    });
  };

  const tabs = [
    {
      label: 'Personal',
      component: <WellbeingBoardPersonal toggleLinkWorkerModal={toggleLinkWorkerModal} customer={customer} />,
      testId: 'wellbeing-board-personal-panel',
    },

    { label: 'Notes', component: <WellbeingBoardNotes notes={customer ? customer.notes : null} />, testId: 'wellbeing-board-notes-panel' },
    {
      label: 'Reviews',
      component: <WellbeingBoardReviews firstVisitDate={customer?.firstVisitDate ?? null} reviewsList={customerReviews || []} teamId={teamId} />,
      testId: 'wellbeing-board-reviews-panel',
    },
    { label: 'About', component: <WellbeingBoardAbout customer={customer} />, testId: 'wellbeing-board-about-panel' },
    {
      label: 'Score',
      component: <WellbeinBoardScores ascotReview={lastAscotReview} ascotExpectedOutcome={lastAscotExpectedOutcome} />,
      testId: 'wellbeing-board-score-panel',
    },
    {
      label: 'Documents',
      component: (
        <DocumentManagement
          onDeleteDocument={onDeleteDocument}
          teamId={customer?.teamId}
          ownerId={customer?.id}
          ownerType="SupportedPerson"
          documents={customer?.documents}
        />
      ),
      testId: 'wellbeing-board-documents-panel',
    },
  ];
  return (
    <>
      <LocationBar section="People" page="Wellbeing board" Icon={SelfImprovement} />
      <div className="my-10 px-4 md:px-[5%]">
        {wellBeingStatusModalState && <EditWellbeingStatus toggleWellbeingStatusModal={toggleWellbeingStatusModal} onEditStatus={onEditStatus} />}
        {assignLinkWorkerModalState && customer && (
          <AssignLinkWorker customer={customer} onClose={() => toggleLinkWorkerModal(false)} onSaveCustomer={onEditLinkWorkers} selectedTeam={teamId ?? ''} />
        )}
        <button
          type="button"
          onClick={() => navigate(`/wellbeing-board?teamId=${teamId}`)}
          className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex items-center gap-2 mb-5"
        >
          <ArrowBack />
          <div className="sm:hidden">Go back</div>
          <div className="hidden md:block">Go back to the wellbeing board</div>
        </button>
        <div className="">
          <div className="flex flex-col md:flex-row justify-between md:items-center gap-4">
            <div className="flex flex-col">
              <div className="flex flex-col sm:flex-row items-center gap-3">
                <Avatar size={ImageSizeType.LG2} avatar={customer?.image?.url} alt={`Supported Person ${customer?.firstName} ${customer?.lastName}`} />
                <div
                  className="text-primary-700 text-display-sm md:text-display-lg leading-display-sm md:leading-display-lg tracking-display-lg font-semibold text-center sm:text-left"
                  data-cy="wellbeing-board-customer-name"
                >
                  {customer?.firstName} {customer?.lastName}
                </div>
              </div>
              <div className="flex flex-row items-center gap-2 text-gray-800 text-xl leading-xl mt-4" data-cy="wellbeing-board-customer-team">
                <UserGroupIcon className="w-6 h-6 shrink-0" />
                {customer?.teamName}
              </div>
            </div>
            <div className="flex flex-col shrink-0">
              <div
                className="md:text-right text-2xl md:text-display-sm leading-2xl md:leading-display-sm font-medium uppercase"
                style={{ color: getWellbeingStatusColor(customer?.wellbeingStatus || '') }}
                data-cy="wellbeing-board-status"
              >
                {customer?.wellbeingStatus}
              </div>
              <button
                type="button"
                onClick={() => toggleWellbeingStatusModal(true)}
                className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md flex justify-center items-center gap-2 mt-2 sm:w-fit md:w-full"
                data-cy="wellbeing-board-update-status-button"
              >
                <Edit className="!w-5 !h-5" />
                Change Status
              </button>
              <div className="mt-4">{customer && <WellbeingReviewText customer={customer} />}</div>
            </div>
          </div>
        </div>
        <div className="mt-10">
          <TabSwitcher defaultTab={defaultTab ? parseInt(defaultTab, 10) : 0} tabs={tabs} />
        </div>
      </div>
    </>
  );
};

export default WellbeingBoardCustomer;
