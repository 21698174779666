import { useState } from 'react';
import { getTime } from 'date-fns';
import DatePicker from 'components/DatePicker';
import { CustomerPackage } from '__generated__/graphql';

interface PackageUpdateProps {
  customerPackage: CustomerPackage | undefined;
  onUpdate: (startDate: CustomerPackage) => void;
}

export default function UpdatePackage({ customerPackage, onUpdate }: PackageUpdateProps) {
  const [startDate, setStartDate] = useState<Date | null>(new Date(customerPackage?.startDate || 0));

  const onSavePackage = () => {
    if (startDate) {
      onUpdate({
        ...customerPackage,
        startDate: getTime(startDate),
      });
    }
  };

  return (
    <div className="mt-8 sm:mt-12">
      <DatePicker testId="package-start-date" value={startDate ?? undefined} onChange={setStartDate} disabled={customerPackage?.endDate !== null} />
      <div className="flex justify-end mt-6">
        {!customerPackage?.endDate && (
          <button
            type="button"
            data-cy="save-package-button"
            className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md"
            onClick={onSavePackage}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
}
