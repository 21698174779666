import { gql, useLazyQuery } from '@apollo/client';
import { Shift, VisitCancellation } from '__generated__/graphql';

export const GET_VISIT_CANCELLATION_REPORT = gql`
  query GetVisitCancellationReport($query: VisitCancellationSearch) {
    GetVisitCancellationReport(query: $query) {
      customerName
      totalCancelledVisitTime
      totalCancelledVisits
      totalNumberOfVisits
      totalVisitTime
    }
  }
`;

export const GET_CANCELLATION_VISITS_REPORT = gql`
  query GetCancelledVisitsReport($query: PayrollReportSearch) {
    GetCancelledVisitsReport(query: $query) {
      id
      shiftId
      visitId
      startDateTime
      endDateTime
      travelTime
      teamId
      teamName
      week
      status
      type
      mileage
      travelWithCustomerMileage
      travelWithCustomerDestination
      notes
      whoCancelled
      cancelledDateTime
      oneOffVisitReason
      oneOffVisitNote
      isToBeRescheduled
      activityType
      rotaId
      birdieId
      origin {
        rotaId
        teamId
        visitId
        supportWorkerId
        fullName
        shiftId
      }
      destination {
        rotaId
        teamId
        visitId
        supportWorkerId
        fullName
        shiftId
      }
      customer {
        id
        firstName
        lastName
        address
        phone
        lat
        lng
        birdieId
      }
      supportWorker {
        fullName
        avatar
        position
        contractedHours
        employmentStatus
        id
        teamId
        birdieId
        lat
        lng
      }
      secondarySupportWorker {
        fullName
        avatar
        contractedHours
        employmentStatus
        id
        teamId
        birdieId
        lat
        lng
      }
      shiftRun {
        id
        startDateTime
        endDateTime
        type
        ownerId
        teamId
        rotaId
      }
    }
  }
`;

interface GetVisitCancellationResponse {
  GetVisitCancellationReport: VisitCancellation[];
}

interface GetCancellationVisitsResponse {
  GetCancelledVisitsReport: Shift[];
}

export const useGetVisitCancellationReport = () => {
  const [getVisitCancellationReport, { loading: reportLoading, error: reportError, data }] =
    useLazyQuery<GetVisitCancellationResponse>(GET_VISIT_CANCELLATION_REPORT);
  return { getVisitCancellationReport, reportLoading, reportError, report: data?.GetVisitCancellationReport || [] };
};

export const useGetCancellationVisitsReport = () => {
  const [getCancellationVisitsReport, { loading: visitsLoading, error: visitsError, data }] =
    useLazyQuery<GetCancellationVisitsResponse>(GET_CANCELLATION_VISITS_REPORT);
  return { getCancellationVisitsReport, visitsLoading, visitsError, visits: data?.GetCancelledVisitsReport || [] };
};
