import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import getTime from 'date-fns/getTime';
import { clockMessage } from 'config/messages';
import { Shift, Maybe } from '__generated__/graphql';
import { activityTypes } from 'types/types';
import { Loading } from 'components';

interface EditActivityProps {
  toggleEditActivityModal: (value: boolean) => void;
  editActivityModalState: boolean;
  shifts: Shift[];
  selectedActivity: Shift | null;
  onUpdateActivity: (activity: Partial<Shift>) => Promise<void>;
  apiLoading: boolean;
}

export default function EditActivity({
  toggleEditActivityModal,
  editActivityModalState,
  shifts,
  selectedActivity,
  onUpdateActivity,
  apiLoading,
}: EditActivityProps) {
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const [activityType, setActivityType] = useState<Maybe<string> | undefined>(null);
  const [notes, setNotes] = useState<Maybe<string> | undefined>('');
  const [validation, setValidation] = useState<string>('');
  const activity = selectedActivity && shifts?.length > 0 ? shifts?.find((s) => s.id === selectedActivity?.id) : null;

  useEffect(() => {
    if (activity) {
      setStartTime(activity.startDateTime);
      setEndTime(activity?.endDateTime);
      setActivityType(activity?.activityType);
      setNotes(activity?.notes);
      setValidation('');
    }
  }, [activity]);

  const onUpdate = () => {
    setValidation('');

    if (getTime(startTime ?? 0) >= getTime(endTime ?? 0) || getTime(startTime ?? 0) === getTime(endTime ?? 0)) {
      setValidation('Please provide a valid shift date time; end time should be greater than start time');
      return;
    }

    if (!notes || notes.length === 0) {
      setValidation('Please provide some notes for this activity');
      return;
    }

    if (activity) {
      onUpdateActivity({
        ...activity,
        startDateTime: getTime(startTime),
        endDateTime: getTime(endTime),
        activityType,
        notes,
      });
    }
  };

  const onChangeStartDateTime = (dateTime: number | null) => {
    if (dateTime) {
      setStartTime(dateTime);
    }
  };

  const onChangeEndDateTime = (dateTime: number | null) => {
    if (dateTime) {
      setEndTime(dateTime);
    }
  };

  return (
    <Dialog open={editActivityModalState} onClose={() => toggleEditActivityModal(false)}>
      <DialogTitle sx={{ fontSize: '1.6em', padding: { xs: '0.8em', sm: '1.2em' } }}>Edit Activity</DialogTitle>
      <DialogContent>
        {validation && (
          <div className="text-error-700 text-lg leading-lg flex items-center gap-2">
            <ExclamationTriangleIcon className="w-5 h-5" />
            {validation}
          </div>
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormControl sx={{ paddingTop: { xs: '1em', sm: '1em' }, width: '98%' }}>
            <Typography>Start Date</Typography>
            <TimePicker
              ampm={false}
              localeText={{ toolbarTitle: 'Select Start Time' }}
              minutesStep={5}
              value={startTime}
              onChange={onChangeStartDateTime}
              slotProps={{ textField: { helperText: clockMessage } }}
            />
          </FormControl>
          <FormControl sx={{ paddingTop: { xs: '1em', sm: '1em' }, width: '98%' }}>
            <Typography>End Date</Typography>
            <TimePicker
              ampm={false}
              localeText={{ toolbarTitle: 'Select Start Time' }}
              minutesStep={5}
              minTime={startTime}
              value={endTime}
              onChange={onChangeEndDateTime}
              slotProps={{ textField: { helperText: clockMessage } }}
            />
          </FormControl>
        </LocalizationProvider>
        <FormControl sx={{ paddingTop: { xs: '1em', sm: '1em' }, width: '98%' }}>
          <Typography>Activity</Typography>
          <Select value={activityType} label="Activity" onChange={(e) => setActivityType(e.target.value)}>
            {activityTypes.map((option) => (
              <MenuItem key={option.value} value={option.value.toString()}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ paddingTop: { xs: '1em', sm: '1em' }, width: '98%' }}>
          <Typography>Notes</Typography>
          <textarea
            style={{ width: '100%', border: '1px solid #ddd', padding: '1em' }}
            rows={5}
            value={notes || ''}
            onChange={(e) => setNotes(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ padding: '1em' }}>
        <Button variant="contained" onClick={() => toggleEditActivityModal(false)}>
          Close
        </Button>
        {apiLoading ? (
          <Loading isComponent />
        ) : (
          <Button variant="contained" onClick={() => onUpdate()}>
            Save Activity
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
