import { useForm, FormProvider } from 'react-hook-form';
import { DateField } from 'components/Fields/DateField';
import { SupportedPersonSelectField } from 'components/Fields/SupportedPersonSelectField';
import { AscotReview, AscotExpectedOutcomeInput, AscotExpectedOutcome } from '__generated__/graphql';
import { SupportWorkerSelectField } from 'components/Fields/SupportWorkerSelectField';
import { useNavigate } from 'react-router-dom';
import { FormPrompt } from 'components/modals/FormPromptModal';
import { useUser } from 'context/userContext';
import { TextAreaFieldWithDescription } from '../AscotReview/TextAreaWithDescriptionField';
import { RadioField } from '../AscotReview/RadioField';

export interface AscotExpectedOutcomeFormInput extends AscotExpectedOutcomeInput {
  foodAndDrink: {
    current?: number;
    answer: string;
    score: number;
  };
  personalSafety: {
    current?: number;
    answer: string;
    score: number;
  };
  occupation: {
    current?: number;
    answer: string;
    score: number;
  };
  personalCleanlinessAndComfort: {
    current?: number;
    answer: string;
    score: number;
  };
  socialParticipationAndInvolvement: {
    current?: number;
    answer: string;
    score: number;
  };
  controlOverDailyLife: {
    current?: number;
    answer: string;
    score: number;
  };
  accomodationAndComfort: {
    current?: number;
    answer: string;
    score: number;
  };
}

const AscotExpectedOutcomeForm = ({
  onSubmit,
  ascotReview,
  ascotExpectedOutcome,
}: {
  onSubmit: (data: AscotExpectedOutcomeFormInput) => void;
  ascotReview?: AscotReview;
  ascotExpectedOutcome?: AscotExpectedOutcome;
}) => {
  const { user } = useUser();
  const methods = useForm<AscotExpectedOutcomeFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      id: ascotExpectedOutcome?.id,
      supportWorkerId: ascotExpectedOutcome?.supportWorkerId ? ascotExpectedOutcome?.supportWorkerId : user?.profile,
      supportedPersonId: ascotExpectedOutcome ? ascotExpectedOutcome.supportedPersonId : ascotReview?.supportedPersonId,
      reviewDate: ascotExpectedOutcome ? ascotExpectedOutcome.reviewDate : ascotReview?.reviewDate,
      reviewId: ascotExpectedOutcome ? ascotExpectedOutcome.reviewId : ascotReview?.reviewId,
      foodAndDrink: {
        score: ascotExpectedOutcome?.foodAndDrink?.score,
        answer: ascotExpectedOutcome?.foodAndDrink?.answer,
        current: ascotReview?.foodAndDrink?.score,
      },
      personalSafety: {
        score: ascotExpectedOutcome?.personalSafety?.score,
        answer: ascotExpectedOutcome?.personalSafety?.answer,
        current: ascotReview?.personalSafety?.score,
      },
      accomodationAndComfort: {
        score: ascotExpectedOutcome?.accomodationAndComfort?.score,
        answer: ascotExpectedOutcome?.accomodationAndComfort?.answer,
        current: ascotReview?.accomodationAndComfort?.score,
      },
      occupation: {
        score: ascotExpectedOutcome?.occupation?.score,
        answer: ascotExpectedOutcome?.occupation?.answer,
        current: ascotReview?.occupation?.score,
      },
      personalCleanlinessAndComfort: {
        score: ascotExpectedOutcome?.personalCleanlinessAndComfort?.score,
        answer: ascotExpectedOutcome?.personalCleanlinessAndComfort?.answer,
        current: ascotReview?.personalCleanlinessAndComfort?.score,
      },
      socialParticipationAndInvolvement: {
        score: ascotExpectedOutcome?.socialParticipationAndInvolvement?.score,
        answer: ascotExpectedOutcome?.socialParticipationAndInvolvement?.answer,
        current: ascotReview?.socialParticipationAndInvolvement?.score,
      },
      controlOverDailyLife: {
        score: ascotExpectedOutcome?.controlOverDailyLife?.score,
        answer: ascotExpectedOutcome?.controlOverDailyLife?.answer,
        current: ascotReview?.controlOverDailyLife?.score,
      },
    },
  });
  const navigate = useNavigate();

  const { handleSubmit, reset, formState } = methods;

  const formSubmit = (data: AscotExpectedOutcomeFormInput) => {
    onSubmit(data);
    reset({}, { keepValues: true });
    navigate(`/wellbeing-board/${ascotReview?.supportedPersonId}?tab=2`);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(formSubmit)}>
          <div className="mt-10 sm:mt-16">
            <div className="mt-8 sm:mt-12">
              <SupportedPersonSelectField name="supportedPersonId" label="Supported Person" disabled={true} />
              <SupportWorkerSelectField name="supportWorkerId" label="Person conducting review" />
              <DateField name="reviewDate" label="Date of review" />
              <RadioField
                options={[
                  { id: '1', label: 'I get all the food and drink I like when I want (ideal state)', value: 0.879 },
                  { id: '2', label: 'I get adequate food and drink at OK times (no needs)', value: 0.775 },
                  { id: '3', label: 'I don`t always get adequate or timely food and drink (some needs)', value: 0.294 },
                  {
                    id: '4',
                    label: 'I don`t always get adequate or timely food and drink, and I think there is a risk to my health (high needs)',
                    value: 0.184,
                  },
                ]}
                disabled={true}
                name="foodAndDrink.current"
                label="Results for ‘Food and Drink’ Domain"
              />
              <RadioField
                options={[
                  { id: '1', label: 'Ideal state', value: 0.879 },
                  { id: '2', label: 'No needs', value: 0.775 },
                  { id: '3', label: 'Some needs', value: 0.294 },
                  { id: '4', label: 'High needs', value: 0.184 },
                ]}
                name="foodAndDrink.score"
                note="Interviewer note: It is important that you draw on information from the person’s family or representatives and other members of the team when
              rating this section."
                // eslint-disable-next-line max-len
                label="Now, imagine that the person doesn’t have the support and services from Bellevie and no other help has stepped in. Which of the following would then best describe the person’s situation with regard to food and drink?"
                isRequired
              />
              <TextAreaFieldWithDescription label="Justification for ratings:" name="foodAndDrink.answer" placeholder="" />
              <RadioField
                options={[
                  { id: '1', label: 'My home is as clean and comfortable as I want (ideal state)', value: 0.863 },
                  { id: '2', label: 'My home is adequately clean and comfortable (no needs)', value: 0.78 },
                  { id: '3', label: 'My home is not quite clean or comfortable enough (some needs)', value: 0.374 },
                  { id: '4', label: 'My home is not at all clean or comfortable (high needs)', value: 0.288 },
                ]}
                disabled={true}
                name="accomodationAndComfort.current"
                label="Results for ‘Accommodation and comfort’ Domain"
              />
              <RadioField
                options={[
                  { id: '1', label: 'Ideal state', value: 0.863 },
                  { id: '2', label: 'No needs', value: 0.78 },
                  { id: '3', label: 'Some needs', value: 0.374 },
                  { id: '4', label: 'High needs', value: 0.288 },
                ]}
                name="accomodationAndComfort.score"
                note="Interviewer note: It is important that you draw on information from the person’s family or representatives and other members of the team when
              rating this section."
                // eslint-disable-next-line max-len
                label="Now, imagine that the person doesn’t have the support and services from Bellevie and no other help has stepped in. Which of the following would then best describe the person’s situation with regard to accommodation and comfort?"
                isRequired
              />
              <TextAreaFieldWithDescription label="Justification for ratings:" name="accomodationAndComfort.answer" placeholder="" />
              <RadioField
                options={[
                  { id: '1', label: 'I feel clean and am able to present myself the way I like (ideal state)', value: 0.911 },
                  { id: '2', label: 'I feel adequately clean and presentable (no needs)', value: 0.789 },
                  { id: '3', label: 'I feel less than adequately clean or presentable (some needs)', value: 0.265 },
                  { id: '4', label: 'I don’t feel at all clean or presentable  (high needs)', value: 0.195 },
                ]}
                disabled={true}
                name="personalCleanlinessAndComfort.current"
                label="Results for personal cleanliness and comfort:"
              />

              <RadioField
                options={[
                  { id: '1', label: 'Ideal state', value: 0.911 },
                  { id: '2', label: 'No needs', value: 0.789 },
                  { id: '3', label: 'Some needs', value: 0.265 },
                  { id: '4', label: 'High needs', value: 0.195 },
                ]}
                note="Interviewer note: It is important that you draw on information from the person’s family or representatives and other members of the team when
              rating this section."
                name="personalCleanlinessAndComfort.score"
                // eslint-disable-next-line max-len
                label="Now, imagine that the person doesn’t have the support and services from Bellevie and no other help has stepped in. Which of the following would then best describe the person’s situation with regard to accommodation and comfort?"
                isRequired
              />
              <TextAreaFieldWithDescription label="Justification for ratings:" name="personalCleanlinessAndComfort.answer" placeholder="" />
              <RadioField
                options={[
                  { id: '1', label: 'I feel as safe as I want (ideal state)', value: 0.88 },
                  { id: '2', label: 'Generally I feel adequately safe, but not as safe as I would like (no needs)', value: 0.452 },
                  { id: '3', label: 'I feel less than adequately safe (some needs)', value: 0.298 },
                  { id: '4', label: 'I don’t feel safe at all (high needs)', value: 0.114 },
                ]}
                disabled={true}
                name="personalSafety.current"
                label="Results for Personal safety"
              />

              <RadioField
                options={[
                  { id: '1', label: 'Ideal state', value: 0.88 },
                  { id: '2', label: 'No needs', value: 0.452 },
                  { id: '3', label: 'Some needs', value: 0.298 },
                  { id: '4', label: 'High needs', value: 0.114 },
                ]}
                note="Interviewer note: It is important that you draw on information from the person’s family or representatives and other members of the team when
              rating this section."
                name="personalSafety.score"
                // eslint-disable-next-line max-len
                label="Now, imagine that the person doesn’t have the support and services from Bellevie and no other help has stepped in. Which of the following would then best describe the person’s situation with regard to feeling safe?"
                isRequired
              />
              <TextAreaFieldWithDescription label="Justification for ratings:" name="personalSafety.answer" placeholder="" />
              <RadioField
                options={[
                  { id: '1', label: 'I have as much control over my daily life as I want (ideal state)', value: 1.0 },
                  { id: '2', label: 'I have adequate control over my daily life (no needs)', value: 0.919 },
                  { id: '3', label: 'I have some control over my daily life, but not enough (some needs)', value: 0.541 },
                  { id: '4', label: 'I have no control over my daily life (high needs)', value: 0.0 },
                ]}
                disabled={true}
                name="controlOverDailyLife.current"
                label="Results for control over daily life"
              />
              <RadioField
                note="Interviewer note: It is important that you draw on information from the person’s family or representatives and other members of the team when
              rating this section."
                options={[
                  { id: '1', label: 'Ideal state', value: 1.0 },
                  { id: '2', label: 'No needs', value: 0.919 },
                  { id: '3', label: 'Some needs', value: 0.541 },
                  { id: '4', label: 'High needs', value: 0.0 },
                ]}
                name="controlOverDailyLife.score"
                // eslint-disable-next-line max-len
                label="Now, imagine that the person doesn’t have the support and services from Bellevie and no other help has stepped in. Which of the following would then best describe the person’s situation with regard to control over daily life?"
                isRequired
              />
              <TextAreaFieldWithDescription label="Justification for ratings:" name="controlOverDailyLife.answer" placeholder="" />
              <RadioField
                options={[
                  { id: '1', label: 'I`m able to spend my time as I want, doing things I value or enjoy (ideal state)', value: 0.962 },
                  { id: '2', label: ' I`m able to do enough of the things I value or enjoy with my time (no needs)', value: 0.927 },
                  { id: '3', label: 'I do some of the things I value or enjoy with my time, but not enough (some needs)', value: 0.567 },
                  { id: '4', label: 'I don`t do anything I value or enjoy with my time (high needs)', value: 0.17 },
                ]}
                disabled={true}
                name="occupation.current"
                label="Results for occupation"
              />
              <RadioField
                note="Interviewer note: It is important that you draw on information from the person’s family or representatives and other members of the team when
              rating this section."
                options={[
                  { id: '1', label: 'Ideal state', value: 0.962 },
                  { id: '2', label: 'No needs', value: 0.927 },
                  { id: '3', label: 'Some needs', value: 0.567 },
                  { id: '4', label: 'High needs', value: 0.17 },
                ]}
                name="occupation.score"
                // eslint-disable-next-line max-len
                label="Now, imagine that the person doesn’t have the support and services from Bellevie and no other help has stepped in. Which of the following would then best describe the person’s situation with regard to occupation?"
                isRequired
              />
              <TextAreaFieldWithDescription label="Justification for ratings:" name="occupation.answer" placeholder="" />
              <RadioField
                options={[
                  { id: '1', label: 'I have as much social contact as I want with people I like (ideal state)', value: 0.873 },
                  { id: '2', label: 'I have adequate social contact with people (no needs)', value: 0.748 },
                  { id: '3', label: 'I have some social contact with people, but not enough (some needs)', value: 0.497 },
                  { id: '4', label: 'I have little social contact with people and feel socially isolated (high needs)', value: 0.241 },
                ]}
                disabled={true}
                name="socialParticipationAndInvolvement.current"
                label="Results for social participation and involvement "
              />
              <RadioField
                note="Interviewer note: It is important that you draw on information from the person’s family or representatives and other members of the team when rating this section."
                options={[
                  { id: '1', label: 'Ideal state', value: 0.873 },
                  { id: '2', label: 'No needs', value: 0.748 },
                  { id: '3', label: 'Some needs', value: 0.497 },
                  { id: '4', label: 'High needs', value: 0.241 },
                ]}
                name="socialParticipationAndInvolvement.score"
                // eslint-disable-next-line max-len
                label="Now, imagine that the person doesn’t have the support and services from Bellevie and no other help has stepped in. Which of the following would then best describe the person’s situation with regard to social participation and involvement?"
                isRequired
              />
              <TextAreaFieldWithDescription label="Justification for ratings:" name="socialParticipationAndInvolvement.answer" placeholder="" />
            </div>
            <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
              Save
            </button>
          </div>
        </form>
      </FormProvider>
      <FormPrompt hasUnsavedChanges={formState.isDirty} />
    </>
  );
};

export default AscotExpectedOutcomeForm;
