import { DropTargetMonitor, useDrop } from 'react-dnd';
import Loading from 'components/Loading';
import { useEffect, useRef, useState } from 'react';
import {
  AgreedShiftRunWeeks,
  AgreedVisitType,
  AddAgreedVisitInput,
  AgreedShift,
  AgreedShiftRun,
  UpdateAgreedVisitItemInput,
  DeleteAgreedVisitInput,
  AddMultipleAgreedVisitInput,
  DeleteMultipleAgreedVisitInput,
  EditAgreedVisitInput,
  Maybe,
} from '__generated__/graphql';
import { BeachAccess, Cake, Sick, WarningAmber } from '@mui/icons-material';
import {
  useAddAgreedVisit,
  useGetAgreedShifts,
  useUpdateAgreedVisits,
  useDeleteMultipleAgreedVisit,
  useEditAgreedVisit,
  useAddMultipleAgreedVisit,
  useDeleteAgreedVisit,
} from 'api/hooks/useAgreedShifts';
import { useGetAgreedRota } from 'api/hooks/useAgreedRota';
import ReactSelect from 'components/Select';
import { useGetLeaveForTeam } from 'api/hooks/useLeave';
import { addDays, getTime } from 'date-fns';
import AgreedVisitHeader from './AgreedVisitHeader';
import VisitRun from './VisitRun';
import { doDateRangesOverlap } from '../utils';

interface AgreedVisitsProps {
  date: number;
  selectedTeam: string;
  selectedAgreedRota: string;
  isDisable: boolean;
  isDuplicateVisit: (value: boolean) => void;
}

const AgreedVisits = ({ date, selectedTeam, selectedAgreedRota, isDisable, isDuplicateVisit }: AgreedVisitsProps) => {
  const { agreedShifts, agreedShiftsLoading } = useGetAgreedShifts({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });
  const { leave } = useGetLeaveForTeam({
    teamId: selectedTeam,
    startDateTime: getTime(date),
    endDateTime: getTime(addDays(date, 14)),
  });
  const { agreedRotas, agreedRotasLoading } = useGetAgreedRota({
    teamId: selectedTeam,
  });
  const { addAgreedVisit, mutationAddAgreedVisit } = useAddAgreedVisit({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });

  const { addMultipleAgreedVisit, mutationAddMultipleAgreedVisit } = useAddMultipleAgreedVisit({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });

  const { editAgreedVisit, mutationEditAgreedVisit } = useEditAgreedVisit({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });

  const { deleteAgreedVisit, mutationDeleteAgreedVisit } = useDeleteAgreedVisit({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });

  const { deleteMultipleAgreedVisit, mutationDeleteMultipleAgreedVisit } = useDeleteMultipleAgreedVisit({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });

  const { updateAgreedVisits, mutationUpdateAgreedVisit } = useUpdateAgreedVisits({
    teamId: selectedTeam,
    agreedRotaId: selectedAgreedRota,
  });
  const [isDuplicateVisits, setIsDuplicateVisits] = useState(false);
  const [isDnDEditing, setIsDnDEditing] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState({ value: '1', label: 'Week 1' });
  const [selectedPeriod, setSelectedPeriod] = useState({ value: 'All', label: 'All' });
  const [selectedRun, setSelectedRun] = useState({ value: '', label: '' });
  const [agreedVisits, setAgreedVisits] = useState<AgreedShiftRunWeeks>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const selectedAgreedShift = selectedWeek.value === '1' ? agreedShifts.week1 : agreedShifts.week2;
  const weekOptions = [
    { value: '1', label: 'Week 1' },
    { value: '2', label: 'Week 2' },
  ];
  const runOptions = selectedAgreedShift?.map((ag) => ({ value: ag?.period ?? '', label: ag?.period ?? '' })) ?? [];
  const periodOptions = [
    { value: 'All', label: 'All' },
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ];

  const [, containerDropRef] = useDrop({
    accept: 'visit',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hover: (
      _: { visit: AgreedVisitType; agreedShiftRunId: string; day: number },
      monitor: DropTargetMonitor<{
        visit: AgreedVisitType;
        agreedShiftRunId: string;
        day: number;
      }>,
    ) => {
      const offset = monitor.getSourceClientOffset();
      const adjustment = isDnDEditing ? 70 : 0; // Adjustment for the offset difference between the two modes

      if (offset && containerRef.current && window.pageYOffset > 370 + adjustment) {
        const dropTargetRect = containerRef.current.getBoundingClientRect();
        const positionY = offset.y - dropTargetRect.top;
        const topPosition = window.pageYOffset - (370 + adjustment);

        if (Math.abs(topPosition - positionY) < 10) {
          const newScrollPosition = window.pageYOffset - window.pageYOffset / 8;
          window.scrollTo(0, newScrollPosition);
        }
      }
    },
    collect: (
      monitor: DropTargetMonitor<{
        visit: AgreedVisitType;
        agreedShiftRunId: string;
        day: number;
      }>,
    ) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (agreedShifts) {
      setAgreedVisits(agreedShifts);
    }
  }, [agreedShifts]);

  useEffect(() => {
    let isDuplicate = false;
    agreedVisits?.week1?.forEach((shiftRun: Maybe<AgreedShiftRun>) => {
      shiftRun?.shifts?.forEach((shift: Maybe<AgreedShift>) => {
        shift?.visits?.forEach((visit: Maybe<AgreedVisitType>) => {
          const visitStartDate = new Date();
          const visitEndDate = new Date();
          if (visit?.startDateTime) visitStartDate.setHours(new Date(visit.startDateTime).getHours(), new Date(visit.startDateTime).getMinutes(), 0, 0);
          if (visit?.endDateTime) visitEndDate.setHours(new Date(visit.endDateTime).getHours(), new Date(visit.endDateTime).getMinutes(), 0, 0);
          shift?.visits?.forEach((v: Maybe<AgreedVisitType>) => {
            const startDate = new Date();
            const endDate = new Date();
            if (v?.startDateTime) startDate.setHours(new Date(v?.startDateTime).getHours(), new Date(v.startDateTime).getMinutes(), 0, 0);
            if (v?.endDateTime) endDate.setHours(new Date(v?.endDateTime).getHours(), new Date(v.endDateTime).getMinutes(), 0, 0);
            if (v?.id !== visit?.id && doDateRangesOverlap(visitStartDate, startDate, visitEndDate, endDate)) {
              isDuplicate = true;
            }
          });
        });
      });
    });
    if (!isDuplicate) {
      agreedVisits?.week2?.forEach((shiftRun: Maybe<AgreedShiftRun>) => {
        shiftRun?.shifts?.forEach((shift: Maybe<AgreedShift>) => {
          shift?.visits?.forEach((visit: Maybe<AgreedVisitType>) => {
            const visitStartDate = new Date();
            const visitEndDate = new Date();
            if (visit?.startDateTime) visitStartDate.setHours(new Date(visit?.startDateTime).getHours(), new Date(visit.startDateTime).getMinutes(), 0, 0);
            if (visit?.endDateTime) visitEndDate.setHours(new Date(visit?.endDateTime).getHours(), new Date(visit.endDateTime).getMinutes(), 0, 0);
            shift?.visits?.forEach((v: Maybe<AgreedVisitType>) => {
              const startDate = new Date();
              const endDate = new Date();
              if (v?.startDateTime) startDate.setHours(new Date(v?.startDateTime).getHours(), new Date(v?.startDateTime).getMinutes(), 0, 0);
              if (v?.endDateTime) endDate.setHours(new Date(v.endDateTime).getHours(), new Date(v.endDateTime).getMinutes(), 0, 0);
              if (v?.id !== visit?.id && doDateRangesOverlap(visitStartDate, startDate, visitEndDate, endDate)) {
                isDuplicate = true;
              }
            });
          });
        });
      });
    }
    isDuplicateVisit(isDuplicate);
    setIsDuplicateVisits(isDuplicate);
  }, [agreedVisits, isDuplicateVisit]);

  const removeVisit = (visitId: string, agreedShiftRunId: string, oldAgreedShiftId: string) => {
    const newAgreedVisits = JSON.parse(JSON.stringify(agreedVisits));

    const removeAgreedVisit = (week: string) => {
      newAgreedVisits[week].forEach((visitRun: AgreedShiftRun, index: number) => {
        if (agreedShiftRunId === visitRun.id) {
          visitRun.shifts?.forEach((shift: Maybe<AgreedShift>, shiftIndex: number) => {
            if (oldAgreedShiftId === shift?.id) {
              newAgreedVisits[week][index].shifts[shiftIndex].visits = newAgreedVisits[week][index].shifts[shiftIndex].visits.filter(
                (visit: AgreedVisitType) => visit.id !== visitId,
              );
            }
          });
        }
      });
    };

    removeAgreedVisit('week1');
    removeAgreedVisit('week2');

    return newAgreedVisits;
  };

  const addVisit = (newAgreedVisits: AgreedShiftRunWeeks, visit: AgreedVisitType, agreedShiftRunId: string, agreedShiftId: string) => {
    const cloneNewAgreedVisits = JSON.parse(JSON.stringify(newAgreedVisits));

    const updateShift = (shift: AgreedShift) => {
      if (agreedShiftId === shift.id) {
        shift.visits?.push(visit);
      }
    };

    const updateShiftRun = (week: keyof AgreedShiftRunWeeks) => {
      cloneNewAgreedVisits[week]?.forEach((shiftRun: AgreedShiftRun, index: number) => {
        if (agreedShiftRunId === shiftRun.id) {
          cloneNewAgreedVisits[week][index].shifts.forEach(updateShift);
        }
      });
    };

    updateShiftRun('week1');
    updateShiftRun('week2');

    return cloneNewAgreedVisits;
  };

  const onVisitDrop = (visit: AgreedVisitType, oldAgreedShiftRunId: string, oldAgreedShiftId: string, agreedShiftRunId: string, agreedShiftId: string) => {
    let newAgreedVisits = removeVisit(visit?.id || '', oldAgreedShiftRunId, oldAgreedShiftId);
    newAgreedVisits = addVisit(newAgreedVisits, visit, agreedShiftRunId, agreedShiftId);
    setAgreedVisits(newAgreedVisits);
    setIsDnDEditing(true);
  };

  const onAddAgreedVisit = async (newVisit: AddAgreedVisitInput) => {
    await addAgreedVisit({
      variables: {
        input: newVisit,
      },
    });
  };

  const onAddMultipleAgreedVisit = async (newMultipleVisits: AddMultipleAgreedVisitInput) => {
    let shiftRunIndex = 0;

    const findShiftRunIndex = (week: Maybe<AgreedShiftRun>[]) => {
      week.forEach((visitRun: Maybe<AgreedShiftRun>) => {
        if (visitRun?.id === newMultipleVisits.agreedShiftRunId) {
          const i = visitRun?.period?.slice(-1) || '';
          shiftRunIndex = parseInt(i, 10) - 1;
        }
      });
    };

    findShiftRunIndex(agreedVisits?.week1 || []);
    findShiftRunIndex(agreedVisits?.week2 || []);

    await addMultipleAgreedVisit({
      variables: {
        input: {
          teamId: newMultipleVisits.teamId,
          agreedRotaId: newMultipleVisits.agreedRotaId,
          agreedShiftRunId: newMultipleVisits.agreedShiftRunId,
          agreedShiftId: newMultipleVisits.agreedShiftId,
          customerId: newMultipleVisits.customerId,
          startDateTime: newMultipleVisits.startDateTime,
          endDateTime: newMultipleVisits.endDateTime,
          days: newMultipleVisits.days,
          untilDate: newMultipleVisits.untilDate || agreedRotas[0].endRotaDateTime,
          shiftRunIndex,
        },
      },
    });
  };

  const onEditAgreedVisit = async (editedVisit: EditAgreedVisitInput) => {
    await editAgreedVisit({
      variables: {
        input: editedVisit,
      },
    });
  };

  const onDeleteAgreedVisit = async (visit: DeleteAgreedVisitInput) => {
    await deleteAgreedVisit({
      variables: {
        input: visit,
      },
    });
  };

  const onDeleteMultipleAgreedVisit = async (visits: DeleteMultipleAgreedVisitInput) => {
    const deleteInput =
      visits.startDateTime && visits.endDateTime
        ? visits
        : {
            teamId: visits.teamId,
            customerId: visits.customerId,
            startDateTime: visits.startDateTime,
            endDateTime: agreedRotas[0].endRotaDateTime,
          };

    await deleteMultipleAgreedVisit({
      variables: {
        input: deleteInput,
      },
    });
  };

  const onUpdateAgreedVisits = async () => {
    const updateAV: UpdateAgreedVisitItemInput[] = [];

    const processShift = (shift: Maybe<AgreedShift>, agreedRotaId: string) => {
      shift?.visits?.forEach((visit: Maybe<AgreedVisitType>) => {
        updateAV.push({
          id: visit?.id,
          agreedRotaId,
          agreedShiftId: shift?.id,
          agreedShiftRunId: shift?.agreedShiftRunId,
          customerId: visit?.customerId,
          teamId: shift?.teamId,
          startDateTime: visit?.startDateTime,
          endDateTime: visit?.endDateTime,
        });
      });
    };

    const processShiftRun = (shiftRun: Maybe<AgreedShiftRun>) => {
      shiftRun?.shifts?.forEach((shift: Maybe<AgreedShift>) => {
        processShift(shift, shift?.agreedRotaId || '');
      });
    };

    agreedVisits?.week1?.forEach(processShiftRun);
    agreedVisits?.week2?.forEach(processShiftRun);

    await updateAgreedVisits({
      variables: {
        input: {
          items: updateAV,
        },
      },
    });

    setIsDnDEditing(false);
  };

  if (
    agreedShiftsLoading ||
    agreedRotasLoading ||
    mutationAddAgreedVisit.loading ||
    mutationEditAgreedVisit.loading ||
    mutationDeleteAgreedVisit.loading ||
    mutationDeleteMultipleAgreedVisit.loading ||
    mutationUpdateAgreedVisit.loading ||
    mutationAddMultipleAgreedVisit.loading
  ) {
    return <Loading />;
  }

  const renderFiltersAndWarnings = () => (
    <>
      <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-8">
        <div className="text-gray-800 text-lg leading-lg flex flex-row items-center gap-2">
          <BeachAccess />
          <div>Leave</div>
        </div>
        <div className="text-gray-800 text-lg leading-lg flex flex-row items-center gap-2">
          <Sick />
          <div>Sick Leave</div>
        </div>
        <div className="text-gray-800 text-lg leading-lg flex flex-row items-center gap-2">
          <Cake />
          <div>Birthday Leave</div>
        </div>
      </div>
      <div className="xl:hidden">
        <div className="mt-5">
          <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select a week</div>
          <div className="mt-2">
            <ReactSelect
              selectedValue={selectedWeek}
              options={weekOptions}
              onChange={(option) => {
                setSelectedWeek(option);
                const week = option.value === '1' ? agreedShifts.week1 || [] : agreedShifts.week2 || [];
                setSelectedRun({ value: week[0]?.period || '', label: week[0]?.period || '' });
              }}
            />
          </div>
        </div>
        <div className="mt-3">
          <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select a Run</div>
          <div className="mt-2">
            <ReactSelect selectedValue={selectedRun} options={runOptions} onChange={setSelectedRun} />
          </div>
        </div>
      </div>
      <div className="hidden xl:block">
        <div className="mt-5">
          <div className="text-sm font-medium text-gray-700 capitalize w-auto">Select a Period</div>
          <div className="mt-2">
            <ReactSelect selectedValue={selectedPeriod} options={periodOptions} onChange={setSelectedPeriod} />
          </div>
        </div>
        {isDnDEditing && (
          <div className="flex items-center justify-between mt-6 gap-2">
            <div className="flex items-center gap-2">
              <WarningAmber className="text-warning-600 !w-8 !h-8" />
              <div className="text-warning-600 text-lg leading-lg font-semibold">
                Becareful your drag and drop changes are not saved. When you're ready, you can press the save button.
              </div>
            </div>
            <button
              type="button"
              className="px-5 py-1.5 rounded-lg border border-primary-700 text-primary-700 text-lg font-semibold"
              onClick={onUpdateAgreedVisits}
            >
              Save
            </button>
          </div>
        )}
      </div>
      {isDuplicateVisits && (
        <div className="flex items-center mt-6 gap-3">
          <WarningAmber className="text-red-600 !w-10 !h-10" />
          <div className="text-red-600 text-lg leading-lg font-bold">
            There are overlapping visits on this rota template, ie 2 visits overlapping at the same time. These are in red.{' '}
            <u>Please delete the ones in red, until the visits are displayed in blue.</u> You need to do this before you can publish this template or create a
            new one.
          </div>
        </div>
      )}
    </>
  );

  const renderWeek = (week: number, agreedWeek: Maybe<Maybe<AgreedShiftRun>[]> | undefined) => (
    <div className={`${selectedWeek.value === week.toString() ? 'flex' : 'hidden'} xl:flex items-stretch border border-gray-100 flex-wrap mt-5`}>
      <div className="hidden xl:flex flex-wrap w-full">
        <AgreedVisitHeader date={date} week={week} startDateTime={0} endDateTime={0} />
      </div>
      {agreedVisits &&
        agreedWeek?.map((row: Maybe<AgreedShiftRun>, index: number) => {
          if (row && row?.status !== 'paused') {
            return (
              <div
                key={index}
                className={`${row?.period === selectedRun.value ? 'flex' : 'hidden'} ${
                  (row?.period?.includes('AM') && selectedPeriod.value === 'AM') ||
                  (row?.period?.includes('PM') && selectedPeriod.value === 'PM') ||
                  selectedPeriod.value === 'All'
                    ? 'xl:flex'
                    : 'xl:hidden'
                } w-full border-t border-gray-100`}
              >
                <VisitRun
                  agreedShifts={agreedVisits}
                  row={row}
                  week={week}
                  onVisitDrop={onVisitDrop}
                  onAddAgreedVisit={onAddAgreedVisit}
                  onAddMultipleAgreedVisit={onAddMultipleAgreedVisit}
                  onEditAgreedVisit={onEditAgreedVisit}
                  onDeleteAgreedVisit={onDeleteAgreedVisit}
                  onDeleteMultipleAgreedVisit={onDeleteMultipleAgreedVisit}
                  isDnDEditing={isDnDEditing}
                  selectedAgreedRota={selectedAgreedRota}
                  isDisable={isDisable}
                  leave={leave}
                />
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );

  return (
    <>
      {renderFiltersAndWarnings()}
      <div ref={containerRef}>
        <div ref={containerDropRef}>
          {renderWeek(1, agreedVisits?.week1)}
          {renderWeek(2, agreedVisits?.week2)}
        </div>
      </div>
    </>
  );
};

export default AgreedVisits;
