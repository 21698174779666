import { RefCallback } from 'react';
import Select from 'react-select';
import { SelectOption } from 'types/types';

interface ReactSelectProps {
  options: SelectOption[];
  name?: string;
  onChange?: (option: SelectOption, name?: string) => void;
  selectedValue?: SelectOption;
  disabled?: boolean;
  searchable?: boolean;
  placeholder?: string;
  isContained?: boolean;
  isLoading?: boolean;
  isTableCol?: boolean;

  testId?: string;
  secondaryColor?: string;
  primaryColor?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formRef?: RefCallback<any>;
}

const ReactSelect = ({
  options,
  onChange,
  name,
  isContained = false,
  isLoading = false,
  selectedValue,
  disabled = false,
  searchable = false,
  isTableCol = false,
  placeholder,
  formRef,
  secondaryColor,
  primaryColor,
  testId = 'react-select',
}: ReactSelectProps) => {
  return (
    <Select
      options={options}
      name={name}
      placeholder={!searchable ? placeholder || 'Please select...' : ''}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#737E8C',
        },
      })}
      ref={formRef}
      // id={testId}
      // classNamePrefix="react-select"
      className={testId}
      classNamePrefix="select"
      isLoading={isLoading}
      noOptionsMessage={() => <p> No Options available </p>}
      isDisabled={disabled}
      onChange={(option, metaData) =>
        onChange ? onChange({ value: option?.value ?? '', label: option?.label ?? '', id: option?.id ?? '' }, metaData.name || '') : null
      }
      value={selectedValue}
      isSearchable={searchable}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          padding: '5px 10px',
          fontSize: '16px',
          borderRadius: '8px',
          background: isContained ? `${primaryColor || '#2D5B6C'}` : '',
          color: isContained ? `${secondaryColor || '#2D5B6C'}` : '',
          border: isContained ? `1px solid ${secondaryColor || '#2D5B6C'}` : '',
          // Removes weird border around container
          boxShadow: isContained ? '' : '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          hover: {
            border: secondaryColor || '#737E8C',
          },
          textOverflow: '',
          width: isTableCol ? 'max-content' : 'auto',
          maxWidth: isTableCol ? '200px' : 'auto',
        }),
        container: (base) => ({
          ...base,
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: isContained ? `${secondaryColor || '#FFF'}` : '#454C54',
          fontWeight: 500,
          textOverflow: '',
          textWrap: 'wrap',
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: isContained ? `${secondaryColor || '#FFF'}` : '#737E8C',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          color: isContained ? `${secondaryColor || '#FFF'}` : '#737E8C',
          marginLeft: '10px',
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none',
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: '#FFF',
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isSelected ? `${primaryColor || '#2D5B6C'}` : '#FFF',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: state.isSelected ? `${primaryColor || '#2D5B6C'}` : '#EDF5F7',
          },
          color: state.isSelected ? `${secondaryColor || '#FFF'}` : '#2D5B6C',
          fontWeight: 'bold',
        }),
      }}
    />
  );
};

export default ReactSelect;
