import { Customer } from '__generated__/graphql';
import { useGetAscotExpectedOutcomes } from 'api/hooks/useAscotExpectedOutcomes';
import { useGetAscotReviews } from 'api/hooks/useAscotReviews';
import { useMemo } from 'react';
import { CustomerAscotExpectedOutcome, CustomerReviewData, CustomerAscotReview } from '../types';

const scoreKeys = [
  'controlOverDailyLife',
  'personalCleanlinessAndComfort',
  'foodAndDrink',
  'personalSafety',
  'socialParticipationAndInvolvement',
  'occupation',
  'accomodationAndComfort',
  'dignity',
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const calculateAscotScore = (review: any) => {
  let scoreTotal = 0;
  if (review) {
    const reviewKey = Object.keys(review);
    reviewKey.forEach((scoreKey) => {
      if (scoreKeys.includes(scoreKey)) {
        const reviewScore = review[scoreKey];

        scoreTotal += reviewScore?.score || 0;
      }
    });
  }

  return scoreTotal * 0.203 - 0.466;
};

const useCustomerAscotData = (customer: Customer | undefined) => {
  const { ascotReviews, loading: loadingReviews } = useGetAscotReviews({ customerId: customer?.id });
  const { ascotExpectedOutcomes, loading } = useGetAscotExpectedOutcomes({ customerId: customer?.id });

  const data: {
    reviews: CustomerReviewData[] | undefined;
    lastAscotReview: CustomerAscotReview | undefined;
    lastAscotExpectedOutcome: CustomerAscotExpectedOutcome | undefined;
  } = useMemo(() => {
    let lastAscotReview;
    let lastAscotExpectedOutcome;
    const sortedReviews = customer?.reviews?.slice().sort((a, b) => (b?.date || 0) - (a?.date || 0));
    const reviews = sortedReviews?.map((review, index) => {
      const ascotReview = ascotReviews?.find((ar) => ar.reviewId === review?.id);
      const ascotExpectedOutcome = ascotExpectedOutcomes?.find((aeo) => aeo.reviewId === review?.id);
      const ascotReviewTotal = calculateAscotScore(ascotReview);
      if (index + 1 === customer?.reviews?.length) {
        lastAscotReview = {
          ...ascotReview,
          total: ascotReviewTotal.toFixed(2),
        };
      }

      const ascotExpectedOutcomeTotal = calculateAscotScore(ascotExpectedOutcome);
      if (index + 1 === customer?.reviews?.length) {
        lastAscotExpectedOutcome = {
          ...ascotExpectedOutcome,
          total: ascotExpectedOutcomeTotal.toFixed(2),
        };
      }
      return {
        id: review?.id ?? '',
        date: review?.date ?? 0,
        requestedBy: review?.requestedBy ?? '',
        note: review?.note ?? '',
        ascotReviewId: ascotReview?.id,
        ascotReviewTotal: ascotReviewTotal.toFixed(2),
        ascotExpectedOutcomeTotal: ascotExpectedOutcomeTotal.toFixed(2),
        ascotExpectedOutcomeId: ascotExpectedOutcome?.id,
      };
    });
    return { reviews, lastAscotReview, lastAscotExpectedOutcome };
  }, [ascotExpectedOutcomes, ascotReviews, customer?.reviews]);

  return {
    customerReviews: data?.reviews,
    lastAscotReview: data?.lastAscotReview,
    lastAscotExpectedOutcome: data.lastAscotExpectedOutcome,
    loading: loadingReviews || loading,
  };
};

export { useCustomerAscotData };
