import { useState } from 'react';
import { Input, FormLabel, TeamSelector } from 'components';
import Button from 'components/Button';
import { DateField } from 'components/Fields/DateField';
import { FormatedNumberField } from 'components/Fields/FormatedNumberField';
import { UserContextProps, useUser } from 'context/userContext';
import { Customer } from '../../__generated__/graphql';
import { SupportedPeopleStateTransition } from './types';

interface ManageSupportedPeopleProps {
  customer: Customer | null | undefined;
  onChangeAccount: (type: string, teamId: string) => void;
  teamChangeLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detailRef: any;
}

const ManageSupportedPeople = ({ detailRef, customer, teamChangeLoading, onChangeAccount }: ManageSupportedPeopleProps) => {
  const { user } = useUser() as UserContextProps;
  const [teamId, setTeam] = useState<string>(customer?.teamId || '');

  if (!customer) return null;

  return (
    <div ref={detailRef} className="mt-8 sm:mt-12">
      <div className="text-black text-md underline sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1 invisible  print:visible">Detail</div>

      <FormatedNumberField isRequired={false} format="### ### ####" label="NHS number" placeholder="NHS number" name="nhsNumber" />
      <DateField name="firstVisitDate" label="First Visit Date" />
      <FormLabel label="Phone">
        <div className="w-full lg:w-1/2">
          <Input
            disabled={true}
            value={customer?.phone || ''}
            placeholder="Phone"
            error={
              !customer?.phone ? (
                <p>
                  Support worker has no phone number &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the phone number in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
        </div>
      </FormLabel>
      <FormLabel label="Maps Coordinates">
        <div className="w-full lg:w-1/2">
          <Input
            disabled={true}
            value={customer?.lat !== 0 || customer?.lng !== 0 ? `${customer?.lat.toFixed(2)}/${customer?.lng.toFixed(2)}` : ''}
            placeholder="Maps Coordinates"
            error={customer?.lat === 0 || customer?.lng === 0 ? 'Please Activate Maps' : undefined}
          />
        </div>
      </FormLabel>
      <FormLabel label="Team">
        <div className="w-full lg:w-1/2">
          <TeamSelector selectTeam={setTeam} teamId={teamId} allTeams />
          {!customer?.teamId ||
            (!customer?.teamName && (
              <p>
                Supported person has no team&ensp;
                <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                  please check the team in hubspot
                </a>
              </p>
            ))}
          {user?.permissions?.developer && (
            <div className="mt-4 print:hidden">
              <Button
                loading={teamChangeLoading}
                label="Change Team"
                color="bg-primary-700"
                onClick={() => onChangeAccount(SupportedPeopleStateTransition.changeTeam, teamId)}
                selector="change-team-button"
              />
            </div>
          )}
        </div>
      </FormLabel>
      <FormLabel label="Address">
        <div className="w-full lg:w-1/2 flex flex-col gap-2">
          <Input
            disabled={true}
            value={customer?.address || ''}
            placeholder="Address"
            error={
              !customer?.address ? (
                <p>
                  Support worker has no address &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the address in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
          <Input
            disabled={true}
            value={customer?.city || ''}
            placeholder="City"
            error={
              !customer?.city ? (
                <p>
                  Support worker has no city &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the address in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
          <Input
            disabled={true}
            value={customer?.postcode || ''}
            placeholder="Postcode"
            error={
              !customer?.postcode ? (
                <p>
                  Support worker has no postcode &ensp;
                  <a className="underline" target="_blank" rel="noreferrer" href="https://www.hubspot.com/">
                    please check the address in hubspot
                  </a>
                </p>
              ) : undefined
            }
          />
        </div>
      </FormLabel>
      <button type="submit" className="text-white bg-primary-700 rounded-lg px-5 py-2.5 font-semibold text-md leading-md print:hidden">
        Save
      </button>
    </div>
  );
};

export default ManageSupportedPeople;
