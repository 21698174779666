import { ChevronRightIcon, UserGroupIcon } from '@heroicons/react/24/outline';

export type SlackLinkType =
  | 'bc85ada8-a654-4b18-800f-44dcc694c959'
  | '14ea2b85-95c6-4c0a-aa23-2e4bde3a6e4a'
  | '822448b8-b87d-488f-9235-98622ed25522'
  | '76684ec9-efb6-497a-98b2-db791a773ca8'
  | '3dd03eea-adbf-43bb-a2fa-e5305d2895b9'
  | '3f0f2d13-8ad3-4ee2-85a1-344294f45c47'
  | 'bc64febd-f5a2-4786-b22c-cc9ed0adeacf'
  | '95fa5afe-0348-4eaf-ad85-a71c09e0539e'
  | '438e2d7e-3e53-4efb-bf8c-7481c340c38e'
  | 'aa8b7990-b745-42b1-8989-d37cd62c51d5'
  | '7205da17-0c8c-4b51-8fd8-6dbe0633ab08';

export const SLACK_LINKS = {
  'bc85ada8-a654-4b18-800f-44dcc694c959': 'https://bellevie-leadership.slack.com', // Leadership Ops
  '14ea2b85-95c6-4c0a-aa23-2e4bde3a6e4a': 'https://bellevie-leadership.slack.com', // Leadership Ops (Dev env)
  '822448b8-b87d-488f-9235-98622ed25522': 'https://bellevie-leadership.slack.com', // WS Leaders
  '76684ec9-efb6-497a-98b2-db791a773ca8': 'https://bellevie-leadership.slack.com', // Care Support
  '3dd03eea-adbf-43bb-a2fa-e5305d2895b9': 'https://bellevie-abingdon.slack.com', // Abingdon
  '3f0f2d13-8ad3-4ee2-85a1-344294f45c47': 'https://bellevie-oxford.slack.com', // Oxford East
  'bc64febd-f5a2-4786-b22c-cc9ed0adeacf': 'https://bellevie-oxford.slack.com', // Oxford West
  '95fa5afe-0348-4eaf-ad85-a71c09e0539e': 'https://bellevienorth-bdn3801.slack.com', // Hexham
  '438e2d7e-3e53-4efb-bf8c-7481c340c38e': 'https://bellevienorth-bdn3801.slack.com', // Northumberland
  'aa8b7990-b745-42b1-8989-d37cd62c51d5': 'https://bellevie-wantage.slack.co/archives/C0198CYF9SA', // Wantage
  '7205da17-0c8c-4b51-8fd8-6dbe0633ab08': 'https://belleviewitney.slack.com', // Witney
};

interface LinksProps {
  teamId: SlackLinkType;
  testId: string;
}

const Links = ({ teamId, testId }: LinksProps) => {
  const teamSlack = SLACK_LINKS[teamId];
  const learningCloudUrl = process.env.REACT_APP_LEARNING_CLOUD_AUTH_URL;
  const links = [
    {
      logo: '/slack-logo.svg',
      alt: 'Slack logo',
      title: "Your team's slack",
      link: teamSlack,
    },
    {
      logo: '/slack-logo.svg',
      alt: 'Slack logo',
      title: 'Bellevie for All slack',
      link: 'https://bellevie-for-all.slack.com/',
    },
    {
      logo: '/hr-partner-logo.png',
      alt: 'HR Partner logo',
      title: 'HR Partner',
      link: 'https://bellevie.hrpartner.io/portal',
    },
    {
      logo: '/qintil-logo.png',
      alt: 'Qintil Logo',
      title: 'Qintil',
      link: 'https://lms.qintil.com/',
    },
    {
      logo: '/mlc-logo.png',
      alt: 'My Learning Cloud Logo',
      title: 'My Learning Cloud',
      link: learningCloudUrl,
    },
    {
      logo: '/birdie-logo.png',
      alt: 'Birdie logo',
      title: 'Birdie Admin',
      link: 'https://admin.birdie.care/',
    },
    {
      logo: '/thrive-logo.png',
      alt: 'Thrive logo',
      title: 'Book a thrive service',
      link: 'https://www.thrivewithbellevie.co.uk/',
    },
  ];

  return (
    <div className="bg-background2 border border-black border-opacity-[0.13] p-6 rounded-3xl" data-cy={testId}>
      <div className="flex flex-row items-center justify-between mb-4">
        <div className="flex flex-row items-center gap-3">
          <div className="border border-black border-opacity-20 w-10 h-10 rounded-full flex items-center justify-center">
            <UserGroupIcon className="!w-5 !h-5 text-content-secondary" />
          </div>
          <div className="text-xl leading-xl font-semibold text-black">Links</div>
        </div>
      </div>
      <div className="bg-background3 px-4 rounded-2xl flex flex-col divide-y divide-gray-200">
        {links.map((link) => (
          <a href={link.link} target="_blank" rel="noopener noreferrer" className="flex flex-row items-center justify-between gap-4 py-[25px] w-full">
            <div className="flex flex-row items-center gap-4">
              <img src={link.logo} alt={link.alt} className="w-1/3 hidden sm:block" />
              <div className="text-gray-900 font-semibold text-md leading-md w-full">{link.title}</div>
            </div>
            <ChevronRightIcon className="text-content-gray w-5 h-5 shrink-0" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Links;
