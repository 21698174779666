import { CustomerAscotReview, CustomerAscotExpectedOutcome } from '../types';

interface WellbeinBoardScoresProps {
  ascotReview: CustomerAscotReview | undefined;
  ascotExpectedOutcome: CustomerAscotExpectedOutcome | undefined;
}

const ScoreLabeledText = ({ label, value }: { label: string; value: number | string | undefined }) => {
  return (
    <div className="flex flex-row gap-2">
      <div className="text-lg leading-lg text-gray-800">{label}</div>
      <div className="text-lg leading-lg text-gray-800 font-semibold">{value}</div>
    </div>
  );
};
const WellbeinBoardScores = ({ ascotReview, ascotExpectedOutcome }: WellbeinBoardScoresProps) => {
  const {
    controlOverDailyLife,
    personalCleanlinessAndComfort,
    foodAndDrink,
    personalSafety,
    socialParticipationAndInvolvement,
    occupation,
    accomodationAndComfort,
    dignity,
    total,
  } = ascotReview || {};

  const isAscotReviewCompleted = !!ascotReview?.id;

  if (ascotReview) {
    return (
      <div className="flex flex-col md:flex-row gap-6  mt-5">
        <div>
          <div className="text-xl leading-xl text-gray-800 font-semibold">ASCOT SCORES</div>
          <div className="flex flex-col mt-4">
            <ScoreLabeledText label="Control over daily life" value={controlOverDailyLife?.score} />
            <ScoreLabeledText label="Personal cleanliness and comfort" value={personalCleanlinessAndComfort?.score} />
            <ScoreLabeledText label="Food and drink" value={personalSafety?.score} />
            <ScoreLabeledText label="Personal safety" value={foodAndDrink?.score} />
            <ScoreLabeledText label="Social participation and involvement" value={socialParticipationAndInvolvement?.score} />
            <ScoreLabeledText label="Occupation" value={occupation?.score} />
            <ScoreLabeledText label="Accommodation cleanliness and comfort" value={accomodationAndComfort?.score} />
            <ScoreLabeledText label="Dignity" value={dignity?.score} />
            {isAscotReviewCompleted && <ScoreLabeledText label="TOTAL" value={total} />}
          </div>
        </div>
        <div>
          <div className="text-xl leading-xl text-gray-800 font-semibold">ASCOT EXPECTED OUTCOME SCORES</div>
          <div className="flex flex-col mt-4">
            <ScoreLabeledText label="Control over daily life" value={ascotExpectedOutcome?.controlOverDailyLife?.score} />
            <ScoreLabeledText label="Personal cleanliness and comfort" value={ascotExpectedOutcome?.personalCleanlinessAndComfort?.score} />
            <ScoreLabeledText label="Food and drink" value={ascotExpectedOutcome?.personalSafety?.score} />
            <ScoreLabeledText label="Personal safety" value={ascotExpectedOutcome?.foodAndDrink?.score} />
            <ScoreLabeledText label="Social participation and involvement" value={ascotExpectedOutcome?.socialParticipationAndInvolvement?.score} />
            <ScoreLabeledText label="Occupation" value={ascotExpectedOutcome?.occupation?.score} />
            <ScoreLabeledText label="Accommodation cleanliness and comfort" value={ascotExpectedOutcome?.accomodationAndComfort?.score} />
            <br />
            <ScoreLabeledText label="TOTAL" value={ascotExpectedOutcome?.total} />
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="text-xl leading-xl text-gray-800 font-semibold">Please complete an ascot review for the latest review</div>;
  }
};

export default WellbeinBoardScores;
